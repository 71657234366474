export const projectGroupSelectLocalization = {
  'ProjectGroupSelect:Header:Title': {
    eng: 'Choose account',
    ru: 'Выберите аккаунт',
  },
  'ProjectGroupSelect:CreateProjectGroup:Text': {
    eng: 'or create a new one',
    ru: 'или создайте новый',
  },
  'ProjectGroupSelect:CreateProjectGroup:Button': {
    eng: 'Create account',
    ru: 'Создать аккаунт',
  },
  'ProjectGroupSelect:InvitedToGroupBadge': {
    eng: 'You were invited',
    ru: 'Вас пригласили',
  },
  'SelectAccount:BlockTitle:CurrentAccount': {
    eng: 'Current account',
    ru: 'Текущий аккаунт',
  },
  'SelectAccount:BlockTitle:AvailableAccounts': {
    eng: 'Your accounts',
    ru: 'Ваши аккаунты',
  },
  'SelectAccount:Footer:Link': {
    eng: 'Go to account management',
    ru: 'Перейти в управление аккаунтами',
  },
  'SelectAccount:Footer:Invites_one': {
    eng: '$[1] invitation to account',
    ru: '$[1] приглашение в аккаунт',
  },
  'SelectAccount:Footer:Invites_few': {
    eng: '$[1] invitations to account',
    ru: '$[1] приглашения в аккаунт',
  },
  'SelectAccount:Footer:Invites_many': {
    eng: '$[1] invitations to account',
    ru: '$[1] приглашений в аккаунт',
  },
  'SelectAccount:Footer:Invites_other': {
    eng: '$[1] invitations to account',
    ru: '$[1] приглашений в аккаунт',
  },
  'SelectAccount:BlockTitle:AvailableAccounts:subtitle': {
    eng: 'In {productName}, you can only log into the account that you own.',
    ru: 'В {productName} вы можете войти только в тот аккаунт, где вы владелец.',
  },
  'SelectAccount:NO_ACCOUNTS:Text': {
    eng: 'You have no accounts.',
    ru: 'У вас нет аккаунтов.',
  },
  'SelectAccount:NO_ACCOUNTS:ACCOUNT_CREATION_ALLOWED:ActionText': {
    eng: 'Create in your Personal Account',
    ru: 'Создать в Личном кабинете',
  },
  'SelectAccount:NO_SUITABLE_ACCOUNTS:Text': {
    eng: 'In {product}, you can only log into the account that you own.',
    ru: 'В {product} вы можете войти только в тот аккаунт, где вы владелец.',
  },
  'SelectAccount:NO_SUITABLE_ACCOUNTS:ACCOUNT_CREATION_ALLOWED:ActionText': {
    eng: 'Create in your Personal Account',
    ru: 'Создать в Личном кабинете',
  },
  'SelectAccount:NO_ROLES_FOR_ACCOUNT:ACCOUNT_CREATION_ALLOWED:ActionText': {
    eng: 'Go to Personal Account',
    ru: 'Перейти в Личный кабинет',
  },
  'SelectAccount:MANAGER:Text': {
    eng: 'You are in admin mode. {product} cannot be used in this mode.',
    ru: 'Вы находитесь в режиме администратора. В нем нельзя использовать {product}.',
  },
  'SelectAccount:MANAGER:INTERNAL:ActionText': {
    eng: 'Switch user mode',
    ru: 'Переключить режим',
  },
  'SelectAccount:NO_SUITABLE_ACCOUNTS:SubText': {
    eng: 'You don’t have any right now.',
    ru: 'Сейчас у вас таких нет.',
  },
  'SelectAccount:MANAGER:SubText': {
    eng: 'Switch to user mode or <a href="/c/logout">sign in with a different login</a>.',
    ru: 'Переключитесь в режим пользователя либо <a href="/c/logout">смените учетную запись</a>',
  },
  'SelectAccount:NO_ACCOUNTS:ACCOUNT_CREATION_NOT_ALLOWED:ActionText': {
    eng: 'Please wait for an invitation or contact your administrator.',
    ru: 'Ожидайте приглашение или напишите вашему администратору.',
  },
  'SelectAccount:NO_SUITABLE_ACCOUNTS:ACCOUNT_CREATION_NOT_ALLOWED:ActionText': {
    eng: 'Please wait for an invitation or contact your administrator.',
    ru: 'Ожидайте приглашение или напишите вашему администратору.',
  },
  'SelectAccount:NO_ROLES_FOR_ACCOUNT:Text': {
    eng: 'You don’t have access to {product} in any of your accounts.',
    ru: 'У вас нет доступа к {product} ни в одном из аккаунтов.',
  },
  'SelectAccount:NO_ROLES_FOR_ACCOUNT:SubText:First': {
    eng: 'If you got here by mistake, please go to the correct product page.',
    ru: 'Если вы попали сюда случайно, перейдите на страницу нужного продукта.',
  },
  'SelectAccount:NO_ROLES_FOR_ACCOUNT:SubText:Second': {
    eng: 'If you received an invitation, check your Personal Account to ensure you were invited to the correct account with access to {product}.',
    ru: 'Если вы получили приглашение, проверьте в личном кабинете, что вас пригласили в нужный аккаунт с доступом к {product}.',
  },
  'SelectAccount:NO_ROLES_FOR_ACCOUNT:SubText:Third': {
    eng: 'You can also create your own account to access {product} in your Personal Account.',
    ru: 'Вы также можете создать свой аккаунт для доступа к {product} в личном кабинете.',
  },
  'SelectAccount:Error:LimitTooltip': {
    eng: 'Reached the limit on the number of accounts',
    ru: 'Достигнут лимит на количество аккаунтов',
  },
  'SelectAccount:LIMIT_REACHED:Text': {
    eng: 'You can’t switch to accounts marked with an exclamation point because they have exceeded the workplace limit.',
    ru: 'Вы не можете переключиться в аккаунты, отмеченные восклицательным знаком, потому что на них превышен лимит рабочих мест.',
  },
  'SelectAccount:LIMIT_REACHED:SubText': {
    eng: 'Please contact the administrators of those accounts to restore access.',
    ru: 'Обратитесь к администраторам данных аккаунтов, чтобы восстановить доступ.',
  },
  'BasePage:BE-error accountsadmin.user.access.forbidden': {
    eng: 'Access forbidden',
    ru: 'Доступ запрещен',
  },
};
