var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useCallback } from 'react';
import { Form, Modal as RsModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Spinner } from '../index';
import './Modal-fix.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import classNames from 'classnames';
export const Modal = (_a) => {
    var { isOpen, children, className, title, titleIcon, buttonCancelText = 'Отмена', buttonCancelColor = 'primary', buttonCancelSize = 'md', buttonCancelOutline = true, buttonCancelTestId = 'Modal.Button.Cancel', buttonCancelDisabled = false, buttonSubmitText = 'Сохранить', buttonSubmitColor = 'primary', buttonSubmitSize = 'md', buttonSubmitOutline = false, buttonSubmitTestId = 'Modal.Button.Submit', buttonSubmitType = 'submit', onActionClick, onCancelClick, disableActionButtonAutoFocus = false, buttonSubmitDisabled = false, disableKeyboard = false, size = 'md', headerTag = 'h5', 'data-test-id': dataTestId = 'Modal.Wrapper', titleTestId = 'Modal.Title', bodyTestId = 'Modal.Body', footerTestId = 'Modal.Footer', noValidate = false, formClassName = '', tag = 'form', customFooter, FixedBannerTop, FixedBannerBottom, dark, naturalScrollbar, zIndex, onClickCloseIcon, inProgress, spinnerSize = '4x', centered, modalAutoFocus, calcBodyHeight, onOpened, hideFooter } = _a, restProps = __rest(_a, ["isOpen", "children", "className", "title", "titleIcon", "buttonCancelText", "buttonCancelColor", "buttonCancelSize", "buttonCancelOutline", "buttonCancelTestId", "buttonCancelDisabled", "buttonSubmitText", "buttonSubmitColor", "buttonSubmitSize", "buttonSubmitOutline", "buttonSubmitTestId", "buttonSubmitType", "onActionClick", "onCancelClick", "disableActionButtonAutoFocus", "buttonSubmitDisabled", "disableKeyboard", "size", "headerTag", 'data-test-id', "titleTestId", "bodyTestId", "footerTestId", "noValidate", "formClassName", "tag", "customFooter", "FixedBannerTop", "FixedBannerBottom", "dark", "naturalScrollbar", "zIndex", "onClickCloseIcon", "inProgress", "spinnerSize", "centered", "modalAutoFocus", "calcBodyHeight", "onOpened", "hideFooter"]);
    const header = useRef(null);
    const container = useRef(null);
    const footer = useRef(null);
    const setHeight = useCallback(() => {
        let headerHeight = 0;
        let footerHeight = 0;
        if (header.current) {
            headerHeight = header.current.clientHeight;
        }
        if (footer.current) {
            footerHeight = footer.current.clientHeight;
        }
        let height = calcBodyHeight
            ? calcBodyHeight(headerHeight, footerHeight)
            : `calc( 100vh - 3.5rem - ${headerHeight}px - ${footerHeight}px)`;
        if (container.current && container.current.parentNode) {
            const current = container.current;
            current.style.maxHeight = height;
            if (current.children[0]) {
                current.children[0].style.maxHeight = height;
            }
        }
    }, [calcBodyHeight]);
    const onOpenedHandle = useCallback(() => {
        setHeight();
        onOpened === null || onOpened === void 0 ? void 0 : onOpened();
    }, [onOpened, setHeight]);
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onActionClick) {
            onActionClick(e);
        }
    };
    return (_jsxs(RsModal, Object.assign({ autoFocus: modalAutoFocus, keyboard: !disableKeyboard, isOpen: isOpen, className: classNames(className, 'just-ui-modal', { 'dark-theme': dark }), toggle: onClickCloseIcon || onCancelClick, onOpened: onOpenedHandle, size: size, "data-test-id": dataTestId, zIndex: zIndex, centered: centered }, restProps, { children: [title && (_jsxs(ModalHeader, { wrapTag: (_a) => {
                    var { children } = _a, restProps = __rest(_a, ["children"]);
                    return (_jsx("div", Object.assign({ ref: header }, restProps, { "data-test-id": titleTestId, children: children })));
                }, tag: headerTag, toggle: onClickCloseIcon ? onClickCloseIcon : onCancelClick, children: [titleIcon, title] })), _jsxs(Form, { onSubmit: onSubmit, className: formClassName, noValidate: noValidate, tag: tag, children: [_jsxs(ModalBody, { "data-test-id": bodyTestId, children: [inProgress && _jsx(Spinner, { size: spinnerSize }), FixedBannerTop && (_jsx("div", { className: 'fixed-banner-top-container', children: _jsx(FixedBannerTop, {}) })), _jsx("div", { className: `modal-body-container ${naturalScrollbar ? 'natural-scrollbar' : ''}`, ref: container, children: naturalScrollbar ? (children) : (_jsx(PerfectScrollbar, { options: { maxScrollbarLength: 62 }, children: children })) }), FixedBannerBottom ? (_jsx("div", { className: 'fixed-banner-bottom-container', children: _jsx(FixedBannerBottom, {}) })) : null] }), !hideFooter && (_jsxs(ModalFooter, { tag: (_a) => {
                            var { children } = _a, restProps = __rest(_a, ["children"]);
                            return (_jsx("div", Object.assign({ ref: footer }, restProps, { "data-test-id": footerTestId, children: children })));
                        }, children: [customFooter, onActionClick || onCancelClick ? (_jsxs("div", { className: 'modal-base-buttons', children: [onCancelClick ? (_jsx(Button, { size: buttonCancelSize, color: buttonCancelColor, outline: buttonCancelOutline, onClick: onCancelClick, "data-test-id": buttonCancelTestId, disabled: buttonCancelDisabled, type: 'button', children: buttonCancelText })) : null, onActionClick ? (_jsx(Button, { size: buttonSubmitSize, color: buttonSubmitColor, disabled: buttonSubmitDisabled, outline: buttonSubmitOutline, autoFocus: !disableActionButtonAutoFocus, "data-test-id": buttonSubmitTestId, type: buttonSubmitType, children: buttonSubmitText })) : null] })) : null] }))] })] })));
};
