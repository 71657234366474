import { aimybox, aimychat, aimylogic, aimyvoice, caila, cloud, jaicf, jaicp, tovie_cloud, tovie_dialogstudio, tovie_nlp, tovie_platform, tovie_voice, tovie_agent, khub, tovie_khub, } from './icons';
export const logoByProduct = {
    Aimychat: aimychat,
    'Tovie Agent': tovie_agent,
    Aimylogic: aimylogic,
    'Tovie DialogStudio': tovie_dialogstudio,
    Aimyvoice: aimyvoice,
    'Tovie Voice': tovie_voice,
    Caila: caila,
    'Tovie ML Place': tovie_nlp,
    'Conversational Cloud': cloud,
    'Tovie Cloud': tovie_cloud,
    JAICP: jaicp,
    'Tovie Platform': tovie_platform,
    'Jay Knowledge Hub': khub,
    'Tovie Data Agent': tovie_khub,
    jaicf: jaicf, // Возможно все что ниже не используется, но трогать не буду
    aimybox: aimybox,
    tovie_agent: tovie_agent,
};
