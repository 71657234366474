export const userLogsLocalization = {
  'UserLog:Table:CreatedAt': {
    eng: 'Date and time',
    ru: 'Дата и время',
    cn: '创建',
  },
  'UserLog:Table:Project': {
    eng: 'Project',
    ru: 'Проект',
    cn: '项目',
  },
  'UserLog:Table:DeviceAndIP': {
    eng: 'IP and device',
    ru: 'IP и устройство',
  },
  'UserLog:Table:Result': {
    eng: 'Result',
    ru: 'Результат',
  },
  'UserLog:Table:TokenId': {
    eng: 'Token ID',
    ru: 'ID токена',
  },
  'UserLog:Table:Impersonator': {
    eng: 'Impersonator',
    ru: 'Имперсонатор',
  },
  'UserLog:Table:Comment': {
    eng: 'Comments',
    ru: 'Комментарий',
    cn: '评价',
  },
  Download: {
    eng: 'Export to XLSX',
    ru: 'Экспортировать в XLSX',
  },
  'UserLog:Table:User': {
    eng: 'User',
    ru: 'Пользователь',
  },
  'UserLog:Table:TargetUser': {
    eng: 'Modified user',
    ru: 'Измененный пользователь',
  },
  'UserLog:Table:Action': {
    eng: 'Action',
    ru: 'Действие',
  },
  'UserLog:Table:Result:SUCCESS': {
    eng: 'Success',
    ru: 'Успешно',
  },
  'UserLog:Table:Result:FAILURE': {
    eng: 'Failure',
    ru: 'Неуспешно',
  },
  'Event:Type:USER_LOGIN': {
    eng: 'User signed in',
    ru: 'Пользователь вошел в аккаунт',
  },
  'Event:Type:USER_LOGOUT': {
    eng: 'User signed out',
    ru: 'Пользователь вышел из аккаунта',
  },
  'Event:Type:USER_CREATE': {
    eng: 'New user added',
    ru: 'Добавлен новый пользователь',
    cn: '添加新用户',
  },
  'Event:Type:USER_DELETE': {
    eng: 'User deleted',
    ru: 'Пользователь удален',
    cn: '删除用户配置文件',
  },
  'Event:Type:USER_UPDATE': {
    eng: 'User profile updated',
    ru: 'Профиль пользователя обновлен',
    cn: '修改用户配置文件',
  },
  'Event:Type:USER_PASSWORD_CHANGE': {
    eng: 'User profile password changed',
    ru: 'Пароль профиля пользователя изменен',
    cn: '修改用户配置文件的密码',
  },
  'Event:Type:USER_BLOCK': {
    eng: 'User account is blocked',
    ru: 'Учетная запись пользователя заблокирована',
    cn: '锁定用户帐户',
  },
  'Event:Type:USER_UNBLOCK': {
    eng: 'User account is unblocked',
    ru: 'Учетная запись пользователя разблокирована',
    cn: '解锁用户账户',
  },
  'Event:Type:BOT_PROJECT_CREATE': {
    eng: 'Bot project created',
    ru: 'Проект бота создан',
    cn: '创建机器人项目',
  },
  'Event:Type:BOT_PROJECT_CLONE': {
    eng: 'Bot project cloned',
    ru: 'Проект бота склонирован',
    cn: '复制机器人项目',
  },
  'Event:Type:BOT_PROJECT_DELETE': {
    eng: 'Bot project deleted',
    ru: 'Проект бота удален',
    cn: '删除机器人项目',
  },
  'Event:Type:BOT_PROJECT_EXPORT': {
    eng: 'Bot project exported',
    ru: 'Экспорт проекта бота',
    cn: '导出机器人项目',
  },
  'Event:Type:BOT_PROJECT_IMPORT': {
    eng: 'Bot project imported',
    ru: 'Импорт проекта бота',
    cn: '导入机器人项目',
  },
  'Event:Type:BOT_PROJECT_UPDATE': {
    eng: 'Bot project settings updated',
    ru: 'Настройки проекта бота обновлены',
    cn: '修改机器人项目',
  },
  'Event:Type:LOG_ISSUE_CLOSE': {
    eng: 'Task closed',
    ru: 'Задача закрыта',
    cn: '结束任务',
  },
  'Event:Type:LOG_ISSUE_CREATE': {
    eng: 'Task created',
    ru: 'Задача создана',
    cn: '创建任务',
  },
  'Event:Type:LOG_ISSUE_UPDATE': {
    eng: 'Task updated',
    ru: 'Задача обновлена',
    cn: '修改任务',
  },
  'Event:Type:INBOUND_CHANNEL_CREATE': {
    eng: 'Inbound channel connected',
    ru: 'Входящий канал подключен',
    cn: '创建机器人通道',
  },
  'Event:Type:INBOUND_CHANNEL_UPDATE': {
    eng: 'Inbound channel settings updated',
    ru: 'Настройки входящего канала обновлены',
    cn: '修改现有的机器人通道',
  },
  'Event:Type:INBOUND_CHANNEL_DELETE': {
    eng: 'Inbound channel deleted',
    ru: 'Входящий канал удален',
    cn: '删除现有的机器人通道',
  },
  'Event:Type:INBOUND_CHANNEL_BLOCK': {
    eng: 'Inbound channel is blocked',
    ru: 'Входящий канал заблокирован',
  },
  'Event:Type:INBOUND_CHANNEL_UNBLOCK': {
    eng: 'Inbound channel is unblocked',
    ru: 'Входящий канал разблокирован',
  },
  'Event:Type:OPERATOR_CHANNEL_CREATE': {
    eng: 'Customer engagement platform connected',
    ru: 'Операторский канал подключен',
  },
  'Event:Type:OPERATOR_CHANNEL_UPDATE': {
    eng: 'Settings of a customer engagement platform updated',
    ru: 'Настройки операторского канала обновлены',
  },
  'Event:Type:OPERATOR_CHANNEL_DELETE': {
    eng: 'Customer engagement platform deleted',
    ru: 'Операторский канал удален',
  },
  'Event:Type:USER_PERMISSIONS_UPDATE': {
    eng: 'User roles updated',
    ru: 'Роли пользователя обновлены',
  },
  'Event:Type:USER_EMAIL_CHANGE': {
    eng: 'User changed the email',
    ru: 'Пользователь изменил email',
  },
  'Event:Type:USER_AVATAR_CHANGE': {
    eng: 'User changed the avatar',
    ru: 'Пользователь изменил аватар',
  },
  'Event:Type:FULL_NAME_CHANGE': {
    eng: 'User changed the full name',
    ru: 'Пользователь изменил имя и фамилию',
  },
  'Event:Type:LANGUAGE_CHANGE': {
    eng: 'User changed the interface language',
    ru: 'Пользователь изменил язык интерфейса',
  },
  'Event:Type:CLIENT_BLOCK': {
    eng: 'Client is blocked',
    ru: 'Клиент заблокирован',
  },
  'Event:Type:CLIENT_UNBLOCK': {
    eng: 'Client is unblocked',
    ru: 'Клиент разблокирован',
  },
  'Event:Type:MESSAGE_LABEL_GROUP_CREATE': {
    eng: 'Label group is added to the catalog',
    ru: 'Группа меток добавлена в справочник',
    cn: '添加标签组',
  },
  'Event:Type:MESSAGE_LABEL_GROUP_UPDATE': {
    eng: 'Label group is updated in the catalog',
    ru: 'Группа меток из справочника обновлена',
    cn: '修改标签组',
  },
  'Event:Type:MESSAGE_LABEL_GROUP_DELETE': {
    eng: 'Label group is deleted from the catalog',
    ru: 'Группа меток удалена из справочника',
    cn: '删除标签组',
  },
  'Event:Type:MESSAGE_LABEL_CREATE': {
    eng: 'Phrase label added to the catalog',
    ru: 'Метка фразы добавлена в справочник',
    cn: '添加对话标签',
  },
  'Event:Type:MESSAGE_LABEL_UPDATE': {
    eng: 'Phrase label updated in the catalog',
    ru: 'Метка фразы из справочника обновлена',
    cn: '修改对话标签',
  },
  'Event:Type:MESSAGE_LABEL_DELETE': {
    eng: 'Phrase label deleted from the catalog',
    ru: 'Метка фразы удалена из справочника',
    cn: '删除词组标签',
  },
  'Event:Type:SESSION_LABEL_CREATE': {
    eng: 'Session label added to the catalog',
    ru: 'Метка сессии добавлена в справочник',
    cn: '添加短语标签',
  },
  'Event:Type:SESSION_LABEL_UPDATE': {
    eng: 'Session label updated in the catalog',
    ru: 'Метка сессии из справочника обновлена',
    cn: '修改短语标签',
  },
  'Event:Type:SESSION_LABEL_DELETE': {
    eng: 'Session label deleted from the catalog',
    ru: 'Метка сессии удалена из справочника',
    cn: '删除会话标签',
  },
  'Event:Type:ISSUE_LABEL_CREATE': {
    eng: 'Task label is added to the catalog',
    ru: 'Метка задачи добавлена в справочник',
    cn: '添加任务标签',
  },
  'Event:Type:ISSUE_LABEL_UPDATE': {
    eng: 'Task label is updated in the catalog',
    ru: 'Метка задачи из справочника обновлена',
    cn: '修改任务标签',
  },
  'Event:Type:ISSUE_LABEL_DELETE': {
    eng: 'Task label is deleted from the catalog',
    ru: 'Метка задачи удалена из справочника',
    cn: '删除任务标签',
  },
  'Event:Type:PROJECT_TAG_CREATE': {
    eng: 'Project label created',
    ru: 'Тег создан',
  },
  'Event:Type:PROJECT_TAG_UPDATE': {
    eng: 'Project labels updated',
    ru: 'Теги обновлены',
  },
  'Event:Type:PROJECT_TAG_DELETE': {
    eng: 'Project label deleted',
    ru: 'Тег удален',
  },
  'Event:Type:PHONE_VERIFICATION_REQUEST': {
    eng: 'Phone number confirmation code request',
    ru: 'Запрос кода подтверждения номера телефона',
    cn: '请求手机验证码',
  },
  'Event:Type:PHONE_VERIFICATION': {
    eng: 'Phone number confirmed',
    ru: 'Номер телефона подтвержден',
    cn: '电话号码已确认',
  },
  'Event:Type:MASKING_RULE_CREATE': {
    eng: 'Masking rule created',
    ru: 'Правило маскирования добавлено',
  },
  'Event:Type:MASKING_RULE_UPDATE': {
    eng: 'Masking rule updated',
    ru: 'Правило маскирования обновлено',
  },
  'Event:Type:MASKING_RULE_ENABLE': {
    eng: 'Masking rule enabled',
    ru: 'Правило маскирования включено',
  },
  'Event:Type:MASKING_RULE_DISABLE': {
    eng: 'Masking rule disabled',
    ru: 'Правило маскирования отключено',
  },
  'Event:Type:MASKING_RULE_DELETE': {
    eng: 'Masking rule deleted',
    ru: 'Правило маскирования удалено',
  },
  'Event:Type:MASKING_ALL_RULES_DELETE': {
    eng: 'Masking template deleted',
    ru: 'Шаблон маскирования удален',
  },
  'NumericRangeModal:clear': {
    eng: 'Clear',
    ru: 'Очистить',
  },
  Between: {
    eng: 'Between',
    ru: 'Между',
  },
  Any: {
    eng: 'Any',
    ru: 'Любое',
  },
  After: {
    eng: 'After',
    ru: 'После',
  },
  Before: {
    eng: 'Before',
    ru: 'До',
  },
};
