import { jsx as _jsx } from "react/jsx-runtime";
import BaseModalFilter from './BaseModalFilter';
import { InlineRadioButton, RadioButton } from '@just-ai/just-ui';
import './BaseModalFilter.scss';
const prepareOptions = (filter) => {
    var _a;
    return (((_a = filter.settings) === null || _a === void 0 ? void 0 : _a.staticOptions) || []).map(currentValue => ({
        name: currentValue.label,
        value: currentValue.value,
    }));
};
const getActiveOption = (filter, optionsArguments, inMultipleFilter) => {
    var _a, _b, _c, _d, _e, _f;
    const currentFilterValues = inMultipleFilter && ((_a = optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters) === null || _a === void 0 ? void 0 : _a.find(value => value.key === 'MULTIPLE_FILTER'));
    if (inMultipleFilter && !currentFilterValues) {
        if ((_c = (_b = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _b === void 0 ? void 0 : _b.defaultOptions) === null || _c === void 0 ? void 0 : _c[0])
            return (_e = (_d = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _d === void 0 ? void 0 : _d.defaultOptions) === null || _e === void 0 ? void 0 : _e[0];
        return '';
    }
    const active = (((_f = filter.settings) === null || _f === void 0 ? void 0 : _f.staticOptions) || []).find(option => option.isSelected);
    const { option } = (filter === null || filter === void 0 ? void 0 : filter.oldValue) || {};
    if (option || active) {
        return option || (active === null || active === void 0 ? void 0 : active.value);
    }
    return '';
};
export default class RadioModal extends BaseModalFilter {
    constructor(props) {
        var _a;
        super(props);
        this.onChange = (name, value) => {
            var _a, _b;
            const valueToSet = typeof value === 'string' ? value : value === null || value === void 0 ? void 0 : value.value;
            this.setState({
                active: valueToSet,
            });
            (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, name, valueToSet);
        };
        this.onCancel = () => {
            const oldValue = this.getOldValue();
            if (!oldValue && !this.props.filter.isRequired)
                return this.props.onDelete();
            if (oldValue === null || oldValue === void 0 ? void 0 : oldValue.option) {
                this.onChange(this.props.filter.key, oldValue.option);
            }
            this.props.onCancel();
        };
        this.onConfirm = () => {
            this.props.onConfirm({ option: this.state.active }, false);
        };
        this.hideButtons = () => {
            return !!this.props.inMultipleFilter;
        };
        this.renderInputs = () => {
            const { filter: { key, settings }, } = this.props;
            if (this.state.options.length <= 3 && !(settings === null || settings === void 0 ? void 0 : settings.fullWidth)) {
                return (_jsx("div", { className: 'BaseModalFilter_container_fullWidth', children: _jsx(InlineRadioButton, { active: this.state.active, color: 'primary', name: key, onChange: this.onChange, options: this.state.options, size: 'sm' }) }));
            }
            return (_jsx("div", { className: 'BaseModalFilter_container_fullWidth', children: this.state.options.map(option => (_jsx(RadioButton, { label: option.name, value: option.value, checked: this.state.active === option.value, name: this.props.filter.key, onChange: value => this.onChange(this.props.filter.key, value) }, `options${option.value}`))) }));
        };
        this.state = {
            options: prepareOptions(this.props.filter),
            active: getActiveOption(this.props.filter, this.props.optionsArguments, this.props.inMultipleFilter),
        };
        const { filter } = props;
        if (filter.isDefault && ((_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.defaultValue)) {
            this.state = Object.assign(Object.assign({}, this.state), { active: filter.settings.defaultValue });
        }
    }
}
