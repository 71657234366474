import { FiltersFetchInterface } from '@just-ai/analytic-front';
import { FilterOption } from '@just-ai/analytic-front/dist/Reporter/api/client/api';
import { OptionsResponseDto, WorkplacesLimitsDto } from '@just-ai/api/dist/generated/Accountsadmin';

import { t } from 'localization';

import safeJsonParse from 'helpers/safeJsonParse';

export const FILTER_KEYS = {
  PRODUCT: 'PRODUCT',
  LIMITS: 'LIMITS',
  MULTIPLE_FILTER: 'MULTIPLE_FILTER',
  LIMIT_PRODUCT: 'LIMIT_PRODUCT',
};

export const getFilterOptions = (options?: OptionsResponseDto, currentWorkplacesData?: WorkplacesLimitsDto | null) => ({
  [FILTER_KEYS.PRODUCT]: Object.entries(options?.productNames || {})
    .map(([key, value]) => {
      if (value === options?.productNames?.['aimylogic']) {
        return {} as FilterOption;
      }

      const jaicpProductName = options?.domains?.['jaicp']?.product;
      if (key === jaicpProductName) {
        const aimylogicName = options?.productNames?.['aimylogic'];
        const jaicpName = value;

        let label = '';
        if (jaicpName && aimylogicName) {
          label = `${jaicpName}/${aimylogicName}`;
        } else if (jaicpName) {
          label = jaicpName;
        } else if (aimylogicName) {
          label = aimylogicName;
        }

        return {
          label: label,
          value: key,
          isSelected: false,
        };
      }

      return {
        label: value,
        value: key,
        isSelected: false,
      };
    })
    .filter(option => option.label),
  [FILTER_KEYS.LIMITS]: [
    {
      value: 'INCLUDED',
      label: t('AccountUsers:Filter:Limits:Included'),
      isSelected: false,
    },
    {
      value: 'NOT_INCLUDED',
      label: t('AccountUsers:Filter:Limits:NotIncluded'),
      isSelected: false,
    },
  ],
  [FILTER_KEYS.LIMIT_PRODUCT]: Object.keys(currentWorkplacesData?.workplacesLimits || {}).map(product => {
    const productName = options?.productNames?.[product] || '';
    return {
      label: productName,
      value: product || '',
      isSelected: false,
    };
  }),
});

interface FilterType {
  key: string;
  type: string;
  label: string;
  isDefault: boolean;
  isRequired: boolean;
  settings: {
    type?: string;
    defaultPageSizeForOptions?: 50;
    key?: string;
    withSearchInput?: boolean;
    placeholder?: string;
    disableComparison?: boolean;
    defaultComparisonOptions?: string;
    defaultOptions?: string[];
    staticOptions?: { value: string; label: any }[];
    fullWidth?: boolean;
    removeFiltersByKeys?: string[];
    subFilters?: FilterType[];
  };
}

export default class AccountsFiltersFetchInterface extends FiltersFetchInterface {
  filters: (FilterType | null)[] = [];
  options: OptionsResponseDto;
  localStorageKey: string;
  workplacesData: WorkplacesLimitsDto | null = null;

  constructor(options: OptionsResponseDto, localStorageKey: string, workplacesData: WorkplacesLimitsDto | null) {
    super();
    this.options = options;
    this.localStorageKey = localStorageKey;
    this.workplacesData = workplacesData;

    const workplaceProductOptions = getFilterOptions(options, workplacesData)[FILTER_KEYS.LIMIT_PRODUCT];
    const staticOptions: FilterType[] = [];
    if (workplaceProductOptions.length > 0) {
      staticOptions.push({
        key: FILTER_KEYS.LIMIT_PRODUCT,
        type: 'RADIO',
        label: t('AccountUsers:Filter:Product'),
        isDefault: true,
        isRequired: false,
        settings: {
          type: 'RADIO',
          staticOptions: workplaceProductOptions,
          key: FILTER_KEYS.LIMIT_PRODUCT,
          fullWidth: true,
          defaultOptions: [workplaceProductOptions[0]?.value],
        },
      });
    }

    staticOptions.push({
      key: FILTER_KEYS.LIMITS,
      type: 'RADIO',
      label: t('AccountUsers:Filter:Limits'),
      isDefault: true,
      isRequired: false,
      settings: {
        type: 'RADIO',
        staticOptions: getFilterOptions()[FILTER_KEYS.LIMITS],
        key: 'LIMITS',
        fullWidth: true,
        defaultOptions: ['NOT_INCLUDED'],
      },
    });

    this.filters = [
      {
        key: FILTER_KEYS.PRODUCT,
        type: 'CHECKBOX',
        label: t('AccountUsers:Filter:Product'),
        isDefault: false,
        isRequired: false,
        settings: {
          type: 'CHECKBOX',
          defaultPageSizeForOptions: 50,
          key: 'PRODUCT',
          withSearchInput: false,
          placeholder: t('AccountUsers:Filter:Product:Placeholder'),
          removeFiltersByKeys: ['MULTIPLE_FILTER'],
        },
      },
    ];
    if (Object.keys(workplacesData?.workplacesLimits || {}).length > 0) {
      this.filters.push({
        key: FILTER_KEYS.MULTIPLE_FILTER,
        type: 'MULTIPLE_FILTER',
        label: t('AccountUsers:Filter:Workplaces'),
        isDefault: false,
        isRequired: false,
        settings: {
          type: 'MULTIPLE_FILTER',
          removeFiltersByKeys: [FILTER_KEYS.PRODUCT],
          subFilters: staticOptions,
        },
      });
    }
  }

  mergeFiltersWithLocalStorage = () => {
    let newFilters = this.filters;
    newFilters = newFilters.filter(Boolean);
    let filtersFromLocalStorage =
      localStorage[this.localStorageKey] && safeJsonParse(localStorage[this.localStorageKey]);

    const setInitialFilterValues = (filter: any, newFilterIndex: number) => {
      if (filter.type === 'CHECKBOX') {
        newFilters[newFilterIndex]!.settings.defaultOptions = filter.options;
      } else if (filter.type === 'RADIO') {
        const optionToBeSelectedIndex = newFilters[newFilterIndex]?.settings?.staticOptions?.findIndex(
          option => 'value' in option && option.value === filter.option
        );

        if (optionToBeSelectedIndex !== -1) {
          // @ts-ignore
          newFilters[newFilterIndex].settings.staticOptions[optionToBeSelectedIndex].isSelected = true;
        }
      }
    };

    filtersFromLocalStorage?.forEach((filter: any) => {
      const newFilterIndex = newFilters.findIndex((newFilter: any) => filter.key === newFilter.key);
      if (filter.key === FILTER_KEYS.MULTIPLE_FILTER && filter?.subFiltersValues?.length === 0) return;
      newFilters[newFilterIndex]!.isDefault = true;
      setInitialFilterValues(filter, newFilterIndex);
      if (filter.type === FILTER_KEYS.MULTIPLE_FILTER) {
        filter.subFiltersValues?.forEach((subFilter: any) => {
          const subFilterStructure = newFilters[newFilterIndex]!.settings.subFilters?.find(
            x => x.key === subFilter.key
          );
          if (subFilter.type === 'RADIO') {
            const optionToBeSelectedIndex = subFilterStructure?.settings?.staticOptions?.findIndex(option => {
              return 'value' in option && option.value === subFilter.option;
            });

            if (optionToBeSelectedIndex !== -1) {
              // @ts-ignore
              subFilterStructure.settings.staticOptions[optionToBeSelectedIndex].isSelected = true;
            }
          }
        });
      }
    });
    this.filters = newFilters;
  };

  getFilters = async (): Promise<{ data: any }> => {
    this.mergeFiltersWithLocalStorage();

    return {
      data: this.filters,
    };
  };
  getFiltersOptions = async (key: string) => {
    return getFilterOptions(this.options, this.workplacesData)[key];
  };
}
