import { jsx as _jsx } from "react/jsx-runtime";
import { StylingLabel } from './Label';
const CompositeLabel = ({ value: { argumentsFilter, t } }) => {
    const { options } = argumentsFilter;
    const label = (options === null || options === void 0 ? void 0 : options.length) && (options === null || options === void 0 ? void 0 : options.length) > 0
        ? `${t('Analytics:FiltersPanel:Selected')} ${options === null || options === void 0 ? void 0 : options.length}`
        : t('Analytics:FiltersPanel:NotSelected');
    return _jsx(StylingLabel, { label: label });
};
export default CompositeLabel;
