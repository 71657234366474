var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AccountEventApi, ReportsApi, FiltersApi } from '../../generated/Audit';
class AccountEventService {
    constructor(axios) {
        this.getAccountEventsByFilters = (accountId, page, size, language, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountEventApi.getAccountEventsByFilters(accountId, page, size, language, filtersDto)).data;
        });
        this.getAccountEventsByFiltersFileType = (accountId, language, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            return yield this.ReportsApi.getAccountEventReport(accountId, language, filtersDto, {
                responseType: 'arraybuffer',
            });
        });
        this.getAvailableFilters = (accountId, language, products) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.FiltersApi.getAvailableFilters(accountId, language, products);
            return data;
        });
        this.getFilterOptions = (accountId, filterKey, language, page, size, filtersDto) => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.FiltersApi.getFilterOptions(accountId, filterKey, language, page, size, filtersDto);
            return data;
        });
        const props = [{}, AccountEventService.BASE_PATH, axios];
        this.AccountEventApi = new AccountEventApi(...props);
        this.ReportsApi = new ReportsApi(...props);
        this.FiltersApi = new FiltersApi(...props);
    }
}
AccountEventService.BASE_PATH = '';
export default AccountEventService;
