import { useEffect, useState } from 'react';

type StateType<ValueType> = ValueType | null;

function useSearchParamsState<ValueType>(
  key: string,
  defaultValue: ValueType
): [StateType<ValueType>, (newValue: ValueType) => void] {
  const [state, setState] = useState<StateType<ValueType>>(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const savedState = urlParams.get(key);
    if (savedState) {
      try {
        return JSON.parse(decodeURIComponent(atob(savedState))) as ValueType;
      } catch (error) {
        return defaultValue;
      }
    }
    return defaultValue;
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(key, btoa(encodeURIComponent(JSON.stringify(state))));
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }, [key, state]);

  return [state, setState];
}

export default useSearchParamsState;
