import React, { useCallback, useEffect, useState } from 'react';
import { Total, UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Label, usePromiseProcessing } from '@just-ai/just-ui';

import { t } from 'localization';
import { axios, isAxiosError } from 'pipes/functions';
import { appStores } from 'store';

import GroupedSelector, { OptionsGroup } from 'components/GroupedSelector';
import { Modal } from 'components/Modal';
import { toOption } from 'helpers/toOption';

import '../styles.scss';

const accountsadminService = new AccountsadminService(axios);

type EditUserModalProps = {
  user: UserSearchRecord | undefined;
  account: Total;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};
const EditUserModal = ({ onSubmit, onCancel, isOpen, user, account }: EditUserModalProps) => {
  const { groupsAndRoles } = appStores.Accounts(state => ({ groupsAndRoles: state.groupsAndRoles }));
  const [roles, setRoles] = useState<string[]>(() => user?.roles.map(role => role.name) || []);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen) return;
    setRoles([]);
  }, [isOpen]);

  useEffect(() => {
    if (user?.roles) {
      setRoles(user?.roles.map(role => role.name));
    }
  }, [user?.roles]);

  const onSubmitInner = useCallback(async () => {
    try {
      if ((!user?.id && user?.id !== 0) || (!account.id && account.id !== 0)) return;
      await accountsadminService.changeAccountRoles(account.id, user.id, roles);
      onSubmit();
    } catch (error) {
      if (isAxiosError(error)) {
        const errorCode = error.response?.data.error;
        if (errorCode) {
          setError(errorCode);
        }
      }
    }
  }, [user?.id, account.id, roles, onSubmit]);

  const [onSubmitInnerState, callOnSubmitInner] = usePromiseProcessing(onSubmitInner, {
    deps: [onSubmitInner],
  });

  const groupsToOptions: OptionsGroup[] = groupsAndRoles.map(group => ({
    label: group.name,
    value: group.name,
    list: group.roles.map(role => toOption(role.name)),
  }));

  const handleRolesChange = useCallback((roles: string[]) => {
    setRoles(roles);
    setError(null);
  }, []);

  const handleCancel = useCallback(() => {
    onCancel();
    setRoles([]);
    setError(null);
  }, [onCancel]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('EditUserModal:Title')}
      buttonCancelText={t('Cancel')}
      buttonSubmitText={t('EditUserModal.submit')}
      onActionClick={callOnSubmitInner}
      onCancelClick={handleCancel}
      inProgress={onSubmitInnerState.loading}
      disableActionButtonAutoFocus
      data-test-id='EditUserModal'
      titleTestId='EditUserModal.header'
      buttonSubmitTestId='EditUserModal.submitButton'
      buttonCancelTestId='EditUserModal.rejectButton'
      buttonSubmitDisabled={!roles.length || !!error}
      id='EditUserModal'
    >
      {error && <div className='text-danger'>{t(`EditUserModal:Error:${error}`)}</div>}
      <div className='flex-column gap-6x'>
        <div>{user?.email}</div>
        <div className='flex-column'>
          <Label>{t('InviteUserModal:Field:Roles')}</Label>
          <GroupedSelector groups={groupsToOptions} selected={roles} onChange={handleRolesChange} />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(EditUserModal);
