//@ts-nocheck
// tslint:disable
/**
 * Audit Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from 'url';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export var AccountEventType;
(function (AccountEventType) {
    AccountEventType["USERLOGIN"] = "USER_LOGIN";
    AccountEventType["USERLOGOUT"] = "USER_LOGOUT";
    AccountEventType["BOTPROJECTCREATE"] = "BOT_PROJECT_CREATE";
    AccountEventType["BOTPROJECTUPDATE"] = "BOT_PROJECT_UPDATE";
    AccountEventType["BOTPROJECTDELETE"] = "BOT_PROJECT_DELETE";
    AccountEventType["BOTPROJECTCLONE"] = "BOT_PROJECT_CLONE";
    AccountEventType["BOTPROJECTIMPORT"] = "BOT_PROJECT_IMPORT";
    AccountEventType["BOTPROJECTEXPORT"] = "BOT_PROJECT_EXPORT";
    AccountEventType["BOTPROJECTENVVARCREATE"] = "BOT_PROJECT_ENV_VAR_CREATE";
    AccountEventType["BOTPROJECTENVVARUPDATE"] = "BOT_PROJECT_ENV_VAR_UPDATE";
    AccountEventType["BOTPROJECTENVVARDELETE"] = "BOT_PROJECT_ENV_VAR_DELETE";
    AccountEventType["INBOUNDCHANNELCREATE"] = "INBOUND_CHANNEL_CREATE";
    AccountEventType["INBOUNDCHANNELUPDATE"] = "INBOUND_CHANNEL_UPDATE";
    AccountEventType["INBOUNDCHANNELDELETE"] = "INBOUND_CHANNEL_DELETE";
    AccountEventType["INBOUNDCHANNELBLOCK"] = "INBOUND_CHANNEL_BLOCK";
    AccountEventType["INBOUNDCHANNELUNBLOCK"] = "INBOUND_CHANNEL_UNBLOCK";
    AccountEventType["OPERATORCHANNELCREATE"] = "OPERATOR_CHANNEL_CREATE";
    AccountEventType["OPERATORCHANNELUPDATE"] = "OPERATOR_CHANNEL_UPDATE";
    AccountEventType["OPERATORCHANNELDELETE"] = "OPERATOR_CHANNEL_DELETE";
    AccountEventType["USERCREATE"] = "USER_CREATE";
    AccountEventType["USERDELETE"] = "USER_DELETE";
    AccountEventType["USERUPDATE"] = "USER_UPDATE";
    AccountEventType["USERPERMISSIONSUPDATE"] = "USER_PERMISSIONS_UPDATE";
    AccountEventType["USERPASSWORDCHANGE"] = "USER_PASSWORD_CHANGE";
    AccountEventType["USERBLOCK"] = "USER_BLOCK";
    AccountEventType["USERUNBLOCK"] = "USER_UNBLOCK";
    AccountEventType["USERBLOCKBYLOGINATTEMPTS"] = "USER_BLOCK_BY_LOGIN_ATTEMPTS";
    AccountEventType["CLIENTBLOCK"] = "CLIENT_BLOCK";
    AccountEventType["CLIENTUNBLOCK"] = "CLIENT_UNBLOCK";
    AccountEventType["LOGISSUECREATE"] = "LOG_ISSUE_CREATE";
    AccountEventType["LOGISSUEUPDATE"] = "LOG_ISSUE_UPDATE";
    AccountEventType["LOGISSUECLOSE"] = "LOG_ISSUE_CLOSE";
    AccountEventType["MESSAGELABELGROUPCREATE"] = "MESSAGE_LABEL_GROUP_CREATE";
    AccountEventType["MESSAGELABELGROUPUPDATE"] = "MESSAGE_LABEL_GROUP_UPDATE";
    AccountEventType["MESSAGELABELGROUPDELETE"] = "MESSAGE_LABEL_GROUP_DELETE";
    AccountEventType["MESSAGELABELCREATE"] = "MESSAGE_LABEL_CREATE";
    AccountEventType["MESSAGELABELUPDATE"] = "MESSAGE_LABEL_UPDATE";
    AccountEventType["MESSAGELABELDELETE"] = "MESSAGE_LABEL_DELETE";
    AccountEventType["SESSIONLABELCREATE"] = "SESSION_LABEL_CREATE";
    AccountEventType["SESSIONLABELUPDATE"] = "SESSION_LABEL_UPDATE";
    AccountEventType["SESSIONLABELDELETE"] = "SESSION_LABEL_DELETE";
    AccountEventType["ISSUELABELCREATE"] = "ISSUE_LABEL_CREATE";
    AccountEventType["ISSUELABELUPDATE"] = "ISSUE_LABEL_UPDATE";
    AccountEventType["ISSUELABELDELETE"] = "ISSUE_LABEL_DELETE";
    AccountEventType["PROJECTTAGCREATE"] = "PROJECT_TAG_CREATE";
    AccountEventType["PROJECTTAGUPDATE"] = "PROJECT_TAG_UPDATE";
    AccountEventType["PROJECTTAGDELETE"] = "PROJECT_TAG_DELETE";
    AccountEventType["ACCOUNTVERIFICATION"] = "ACCOUNT_VERIFICATION";
    AccountEventType["PHONEVERIFICATIONREQUEST"] = "PHONE_VERIFICATION_REQUEST";
    AccountEventType["PHONEVERIFICATION"] = "PHONE_VERIFICATION";
    AccountEventType["MASKINGRULECREATE"] = "MASKING_RULE_CREATE";
    AccountEventType["MASKINGRULEUPDATE"] = "MASKING_RULE_UPDATE";
    AccountEventType["MASKINGRULEENABLE"] = "MASKING_RULE_ENABLE";
    AccountEventType["MASKINGRULEDISABLE"] = "MASKING_RULE_DISABLE";
    AccountEventType["MASKINGRULEDELETE"] = "MASKING_RULE_DELETE";
    AccountEventType["MASKINGALLRULESDELETE"] = "MASKING_ALL_RULES_DELETE";
    AccountEventType["CUSTOMROLECREATE"] = "CUSTOM_ROLE_CREATE";
    AccountEventType["CUSTOMROLEUPDATE"] = "CUSTOM_ROLE_UPDATE";
    AccountEventType["CUSTOMROLERENAME"] = "CUSTOM_ROLE_RENAME";
    AccountEventType["CUSTOMROLEDELETE"] = "CUSTOM_ROLE_DELETE";
    AccountEventType["ANALYTICLOGMESSAGEDOWNLOADED"] = "ANALYTIC_LOG_MESSAGE_DOWNLOADED";
    AccountEventType["ANALYTICLOGSESSIONDOWNLOADED"] = "ANALYTIC_LOG_SESSION_DOWNLOADED";
    AccountEventType["ANALYTICLOGCLIENTSDOWNLOADED"] = "ANALYTIC_LOG_CLIENTS_DOWNLOADED";
    AccountEventType["APITOKENCREATE"] = "API_TOKEN_CREATE";
    AccountEventType["APITOKENREFRESH"] = "API_TOKEN_REFRESH";
    AccountEventType["APITOKENUPDATE"] = "API_TOKEN_UPDATE";
    AccountEventType["APITOKENDELETE"] = "API_TOKEN_DELETE";
    AccountEventType["FILTERCREATE"] = "FILTER_CREATE";
    AccountEventType["FILTERUPDATE"] = "FILTER_UPDATE";
    AccountEventType["FILTERDELETE"] = "FILTER_DELETE";
    AccountEventType["SEARCHMETHODCREATE"] = "SEARCH_METHOD_CREATE";
    AccountEventType["SEARCHMETHODUPDATE"] = "SEARCH_METHOD_UPDATE";
    AccountEventType["SEARCHMETHODDELETE"] = "SEARCH_METHOD_DELETE";
})(AccountEventType || (AccountEventType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var CheckboxComparisonType;
(function (CheckboxComparisonType) {
    CheckboxComparisonType["NOTIMPORTANT"] = "NOT_IMPORTANT";
    CheckboxComparisonType["NOTEXISTS"] = "NOT_EXISTS";
    CheckboxComparisonType["EXISTS"] = "EXISTS";
    CheckboxComparisonType["CONTAINS"] = "CONTAINS";
    CheckboxComparisonType["NOTCONTAINS"] = "NOT_CONTAINS";
})(CheckboxComparisonType || (CheckboxComparisonType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var DateTimeUnit;
(function (DateTimeUnit) {
    DateTimeUnit["SECOND"] = "SECOND";
    DateTimeUnit["MINUTE"] = "MINUTE";
    DateTimeUnit["HOUR"] = "HOUR";
    DateTimeUnit["DAY"] = "DAY";
    DateTimeUnit["WEEK"] = "WEEK";
    DateTimeUnit["MONTH"] = "MONTH";
    DateTimeUnit["QUARTER"] = "QUARTER";
    DateTimeUnit["YEAR"] = "YEAR";
})(DateTimeUnit || (DateTimeUnit = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var EventStatus;
(function (EventStatus) {
    EventStatus["SUCCESS"] = "SUCCESS";
    EventStatus["FAILURE"] = "FAILURE";
})(EventStatus || (EventStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var FilterKey;
(function (FilterKey) {
    FilterKey["EVENTTIME"] = "EVENT_TIME";
    FilterKey["EVENTTYPE"] = "EVENT_TYPE";
    FilterKey["PROJECTSHORTNAME"] = "PROJECT_SHORT_NAME";
    FilterKey["USERLOGIN"] = "USER_LOGIN";
    FilterKey["TARGETUSERLOGIN"] = "TARGET_USER_LOGIN";
    FilterKey["USERLOGINORTARGETUSERLOGIN"] = "USER_LOGIN_OR_TARGET_USER_LOGIN";
    FilterKey["PRODUCT"] = "PRODUCT";
})(FilterKey || (FilterKey = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var FilterType;
(function (FilterType) {
    FilterType["TEXT"] = "TEXT";
    FilterType["DATETIMERANGE"] = "DATE_TIME_RANGE";
    FilterType["NUMERICRANGE"] = "NUMERIC_RANGE";
    FilterType["CHECKBOX"] = "CHECKBOX";
    FilterType["RADIO"] = "RADIO";
    FilterType["TREE"] = "TREE";
})(FilterType || (FilterType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var IntervalType;
(function (IntervalType) {
    IntervalType["HOUR"] = "HOUR";
    IntervalType["DAY"] = "DAY";
    IntervalType["WEEK"] = "WEEK";
    IntervalType["MONTH"] = "MONTH";
    IntervalType["QUARTER"] = "QUARTER";
    IntervalType["YEAR"] = "YEAR";
})(IntervalType || (IntervalType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ProductType;
(function (ProductType) {
    ProductType["PLATFORM"] = "PLATFORM";
    ProductType["GENERAL"] = "GENERAL";
    ProductType["DATAGUARD"] = "DATA_GUARD";
})(ProductType || (ProductType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var SystemEventType;
(function (SystemEventType) {
    SystemEventType["STARTSERVICE"] = "START_SERVICE";
    SystemEventType["SHUTDOWNSERVICE"] = "SHUTDOWN_SERVICE";
})(SystemEventType || (SystemEventType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var TextComparisonType;
(function (TextComparisonType) {
    TextComparisonType["NOTIMPORTANT"] = "NOT_IMPORTANT";
    TextComparisonType["CONTAINS"] = "CONTAINS";
    TextComparisonType["NOTCONTAINS"] = "NOT_CONTAINS";
    TextComparisonType["EQUALS"] = "EQUALS";
})(TextComparisonType || (TextComparisonType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var YesNoFilterOption;
(function (YesNoFilterOption) {
    YesNoFilterOption["NO"] = "NO";
    YesNoFilterOption["YES"] = "YES";
})(YesNoFilterOption || (YesNoFilterOption = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var YesNoNotImportantFilterOption;
(function (YesNoNotImportantFilterOption) {
    YesNoNotImportantFilterOption["NO"] = "NO";
    YesNoNotImportantFilterOption["YES"] = "YES";
    YesNoNotImportantFilterOption["NOTIMPORTANT"] = "NOT_IMPORTANT";
})(YesNoNotImportantFilterOption || (YesNoNotImportantFilterOption = {}));
/**
 * AccountEventApi - axios parameter creator
 * @export
 */
export const AccountEventApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get account events by filters
         * @param {number} accountId Account identifier
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountEventsByFilters(accountId, page, size, language, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAccountEventsByFilters.');
            }
            const localVarPath = `/api/audit-service/accounts/{accountId}/account-events/filter`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter['language'] = String(language);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof filtersDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(filtersDto !== undefined ? filtersDto : {}) : (filtersDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save account event
         * @param {SaveAccountEventDto} saveAccountEventDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountEvent(saveAccountEventDto, options = {}) {
            // verify required parameter 'saveAccountEventDto' is not null or undefined
            if (saveAccountEventDto === null || saveAccountEventDto === undefined) {
                throw new RequiredError('saveAccountEventDto', 'Required parameter saveAccountEventDto was null or undefined when calling saveAccountEvent.');
            }
            const localVarPath = `/api/audit-service/account-events`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof saveAccountEventDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(saveAccountEventDto !== undefined ? saveAccountEventDto : {}) : (saveAccountEventDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save account event
         * @param {SaveAccountEventsDto} saveAccountEventsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountEventsBatch(saveAccountEventsDto, options = {}) {
            // verify required parameter 'saveAccountEventsDto' is not null or undefined
            if (saveAccountEventsDto === null || saveAccountEventsDto === undefined) {
                throw new RequiredError('saveAccountEventsDto', 'Required parameter saveAccountEventsDto was null or undefined when calling saveAccountEventsBatch.');
            }
            const localVarPath = `/api/audit-service/account-events-batch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof saveAccountEventsDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(saveAccountEventsDto !== undefined ? saveAccountEventsDto : {}) : (saveAccountEventsDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountEventApi - functional programming interface
 * @export
 */
export const AccountEventApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get account events by filters
         * @param {number} accountId Account identifier
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountEventsByFilters(accountId, page, size, language, filtersDto, options) {
            const localVarAxiosArgs = AccountEventApiAxiosParamCreator(configuration).getAccountEventsByFilters(accountId, page, size, language, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Save account event
         * @param {SaveAccountEventDto} saveAccountEventDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountEvent(saveAccountEventDto, options) {
            const localVarAxiosArgs = AccountEventApiAxiosParamCreator(configuration).saveAccountEvent(saveAccountEventDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Save account event
         * @param {SaveAccountEventsDto} saveAccountEventsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountEventsBatch(saveAccountEventsDto, options) {
            const localVarAxiosArgs = AccountEventApiAxiosParamCreator(configuration).saveAccountEventsBatch(saveAccountEventsDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountEventApi - factory interface
 * @export
 */
export const AccountEventApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get account events by filters
         * @param {number} accountId Account identifier
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountEventsByFilters(accountId, page, size, language, filtersDto, options) {
            return AccountEventApiFp(configuration).getAccountEventsByFilters(accountId, page, size, language, filtersDto, options)(axios, basePath);
        },
        /**
         * Save account event
         * @param {SaveAccountEventDto} saveAccountEventDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountEvent(saveAccountEventDto, options) {
            return AccountEventApiFp(configuration).saveAccountEvent(saveAccountEventDto, options)(axios, basePath);
        },
        /**
         * Save account event
         * @param {SaveAccountEventsDto} saveAccountEventsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountEventsBatch(saveAccountEventsDto, options) {
            return AccountEventApiFp(configuration).saveAccountEventsBatch(saveAccountEventsDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountEventApi - object-oriented interface
 * @export
 * @class AccountEventApi
 * @extends {BaseAPI}
 */
export class AccountEventApi extends BaseAPI {
    /**
     *
     * @summary Get account events by filters
     * @param {number} accountId Account identifier
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEventApi
     */
    getAccountEventsByFilters(accountId, page, size, language, filtersDto, options) {
        return AccountEventApiFp(this.configuration).getAccountEventsByFilters(accountId, page, size, language, filtersDto, options)(this.axios, this.basePath);
    }
    /**
     * Save account event
     * @param {SaveAccountEventDto} saveAccountEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEventApi
     */
    saveAccountEvent(saveAccountEventDto, options) {
        return AccountEventApiFp(this.configuration).saveAccountEvent(saveAccountEventDto, options)(this.axios, this.basePath);
    }
    /**
     * Save account event
     * @param {SaveAccountEventsDto} saveAccountEventsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEventApi
     */
    saveAccountEventsBatch(saveAccountEventsDto, options) {
        return AccountEventApiFp(this.configuration).saveAccountEventsBatch(saveAccountEventsDto, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
                throw new RequiredError('loggingEnabledE2ECheck', 'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.');
            }
            const localVarPath = `/api/audit-service/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter['Z-requestId'] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    }
}
/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get available filters
         * @param {number} accountId Account identifier
         * @param {string} [language] Language of resources
         * @param {Array<ProductType>} [products]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFilters(accountId, language, products, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAvailableFilters.');
            }
            const localVarPath = `/api/audit-service/accounts/{accountId}/filters`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (products) {
                localVarQueryParameter['products'] = products;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter['language'] = String(language);
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {string} [language] Language of resources
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterOptions(accountId, filterKey, language, page, size, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getFilterOptions.');
            }
            // verify required parameter 'filterKey' is not null or undefined
            if (filterKey === null || filterKey === undefined) {
                throw new RequiredError('filterKey', 'Required parameter filterKey was null or undefined when calling getFilterOptions.');
            }
            const localVarPath = `/api/audit-service/accounts/{accountId}/filter-options`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (filterKey !== undefined) {
                localVarQueryParameter['filterKey'] = filterKey;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter['language'] = String(language);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof filtersDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(filtersDto !== undefined ? filtersDto : {}) : (filtersDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get available filters
         * @param {number} accountId Account identifier
         * @param {string} [language] Language of resources
         * @param {Array<ProductType>} [products]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFilters(accountId, language, products, options) {
            const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getAvailableFilters(accountId, language, products, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {string} [language] Language of resources
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterOptions(accountId, filterKey, language, page, size, filtersDto, options) {
            const localVarAxiosArgs = FiltersApiAxiosParamCreator(configuration).getFilterOptions(accountId, filterKey, language, page, size, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get available filters
         * @param {number} accountId Account identifier
         * @param {string} [language] Language of resources
         * @param {Array<ProductType>} [products]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableFilters(accountId, language, products, options) {
            return FiltersApiFp(configuration).getAvailableFilters(accountId, language, products, options)(axios, basePath);
        },
        /**
         *
         * @summary Get filter options
         * @param {number} accountId Account identifier
         * @param {FilterKey} filterKey filterkey
         * @param {string} [language] Language of resources
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterOptions(accountId, filterKey, language, page, size, filtersDto, options) {
            return FiltersApiFp(configuration).getFilterOptions(accountId, filterKey, language, page, size, filtersDto, options)(axios, basePath);
        },
    };
};
/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI {
    /**
     *
     * @summary Get available filters
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {Array<ProductType>} [products]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    getAvailableFilters(accountId, language, products, options) {
        return FiltersApiFp(this.configuration).getAvailableFilters(accountId, language, products, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get filter options
     * @param {number} accountId Account identifier
     * @param {FilterKey} filterKey filterkey
     * @param {string} [language] Language of resources
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    getFilterOptions(accountId, filterKey, language, page, size, filtersDto, options) {
        return FiltersApiFp(this.configuration).getFilterOptions(accountId, filterKey, language, page, size, filtersDto, options)(this.axios, this.basePath);
    }
}
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get report for account events
         * @param {number} accountId Account identifier
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountEventReport(accountId, language, filtersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling getAccountEventReport.');
            }
            const localVarPath = `/api/audit-service/accounts/{accountId}/account-events/report`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (language !== undefined && language !== null) {
                localVarHeaderParameter['language'] = String(language);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof filtersDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(filtersDto !== undefined ? filtersDto : {}) : (filtersDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function (configuration) {
    return {
        /**
         * Get report for account events
         * @param {number} accountId Account identifier
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountEventReport(accountId, language, filtersDto, options) {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getAccountEventReport(accountId, language, filtersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get report for account events
         * @param {number} accountId Account identifier
         * @param {string} [language] Language of resources
         * @param {FiltersDto} [filtersDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountEventReport(accountId, language, filtersDto, options) {
            return ReportsApiFp(configuration).getAccountEventReport(accountId, language, filtersDto, options)(axios, basePath);
        },
    };
};
/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * Get report for account events
     * @param {number} accountId Account identifier
     * @param {string} [language] Language of resources
     * @param {FiltersDto} [filtersDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    getAccountEventReport(accountId, language, filtersDto, options) {
        return ReportsApiFp(this.configuration).getAccountEventReport(accountId, language, filtersDto, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options = {}) {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version(options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }
}
/**
 * SystemEventApi - axios parameter creator
 * @export
 */
export const SystemEventApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Save system event
         * @param {SaveSystemEventDto} saveSystemEventDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSystemEvent(saveSystemEventDto, options = {}) {
            // verify required parameter 'saveSystemEventDto' is not null or undefined
            if (saveSystemEventDto === null || saveSystemEventDto === undefined) {
                throw new RequiredError('saveSystemEventDto', 'Required parameter saveSystemEventDto was null or undefined when calling saveSystemEvent.');
            }
            const localVarPath = `/api/audit-service/system-events`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = (typeof saveSystemEventDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(saveSystemEventDto !== undefined ? saveSystemEventDto : {}) : (saveSystemEventDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemEventApi - functional programming interface
 * @export
 */
export const SystemEventApiFp = function (configuration) {
    return {
        /**
         * Save system event
         * @param {SaveSystemEventDto} saveSystemEventDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSystemEvent(saveSystemEventDto, options) {
            const localVarAxiosArgs = SystemEventApiAxiosParamCreator(configuration).saveSystemEvent(saveSystemEventDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemEventApi - factory interface
 * @export
 */
export const SystemEventApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Save system event
         * @param {SaveSystemEventDto} saveSystemEventDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSystemEvent(saveSystemEventDto, options) {
            return SystemEventApiFp(configuration).saveSystemEvent(saveSystemEventDto, options)(axios, basePath);
        },
    };
};
/**
 * SystemEventApi - object-oriented interface
 * @export
 * @class SystemEventApi
 * @extends {BaseAPI}
 */
export class SystemEventApi extends BaseAPI {
    /**
     * Save system event
     * @param {SaveSystemEventDto} saveSystemEventDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEventApi
     */
    saveSystemEvent(saveSystemEventDto, options) {
        return SystemEventApiFp(this.configuration).saveSystemEvent(saveSystemEventDto, options)(this.axios, this.basePath);
    }
}
