import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import caila from 'images/caila.svg';
import tovieDS from 'images/tovie/t-dialogstudio.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/CailaStyleComponent'));

export default class CailaTheme extends BaseTheme {
  backgroundSvgColor: string = '#FE7876';

  renderTitle() {
    const cailaDomain = this.currentDomainData.domain;
    const title = !isEuroInstance() ? (
      <div className='app-title'>
        <h1>Caila</h1>
        <h2>
          Part of Just AI <br />
          Conversational Cloud
        </h2>
      </div>
    ) : null;
    return cailaDomain ? (
      <a className='app-link' href={`${window.location.protocol}//${cailaDomain}`} style={{ textDecoration: 'none' }}>
        {title}
      </a>
    ) : (
      title
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = '/c/caila.svg';
    description.content = this.getProductName();
  }

  renderLogo() {
    const cailaDomain = this.currentDomainData.domain;
    const logo = (
      <div className='app-logo'>
        <img src={isEuroInstance() ? tovieDS : caila} alt='logo' />
      </div>
    );
    return cailaDomain ? (
      <a className='app-link' href={`${window.location.protocol}//${cailaDomain}`} style={{ textDecoration: 'none' }}>
        {logo}
      </a>
    ) : (
      logo
    );
  }
}
