import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import aimylogic from 'images/aimylogic.svg';
import tovieDS from 'images/tovie/t-dialogstudio.svg';
import tovieDsText from 'images/tovie/t-ds-text.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/AlStyleComponent'));

export default class AimylogicTheme extends BaseTheme {
  backgroundSvgColor: string = '#8bbbe499';

  renderTitle() {
    return (
      <div className='app-title'>
        {isEuroInstance() ? <img src={tovieDsText} alt='description-logo' /> : <h1>{this.getProductName()}</h1>}
        {!isEuroInstance() && (
          <h2>
            Part of Just AI <br />
            Conversational Cloud
          </h2>
        )}
      </div>
    );
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = isEuroInstance() ? '/c/tovie/t-dialogstudio.svg' : '/c/aimylogic.svg';
    description.content = '';
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  renderLogo() {
    return (
      <div className='app-logo'>
        <img src={isEuroInstance() ? tovieDS : aimylogic} alt='logo' />
      </div>
    );
  }
}
