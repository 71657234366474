import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useRef } from 'react';
import { useCompositeFiltersContext } from './index';
import { escapeRegExp, useClickListener, useKeyboardArrows } from './hooks';
import classNames from 'classnames';
export const Suggestions = ({ suggestions, searchValue, onSelectValue }) => {
    var _a;
    const suggestionsRef = useRef(null);
    const { t, keyCode } = useCompositeFiltersContext();
    const showableSuggestions = useMemo(() => {
        return [...suggestions].splice(0, 7);
    }, [suggestions]);
    const { visible, setVisible } = useClickListener(suggestionsRef, suggestions.length > 0, [suggestions]);
    const allowToNavigate = suggestions.length > 0 && visible;
    const { focusElementIndex, setFocusElement } = useKeyboardArrows(showableSuggestions, allowToNavigate, onSelectValue, () => setVisible(false), (_a = suggestionsRef.current) === null || _a === void 0 ? void 0 : _a.parentElement);
    if (!visible)
        return null;
    return (_jsxs("div", { ref: suggestionsRef, className: classNames('Suggestions', { 'has-more-then': suggestions.length > 7 }), children: [showableSuggestions.map((suggestion, elementIndex) => (_jsx(Suggestion, { suggestion: suggestion, searchValue: searchValue, focusElementIndex: focusElementIndex, elementIndex: elementIndex, setFocusElement: setFocusElement, onSelectValue: onSelectValue }, suggestion))), suggestions.length > 7 && _jsx("div", { className: 'Suggestions-more-then', children: t(`${keyCode}:SuggestionsLimitText`) })] }));
};
const Suggestion = ({ suggestion, searchValue, focusElementIndex, elementIndex, setFocusElement, onSelectValue, }) => {
    const innerStringParts = suggestion.split(new RegExp(`(${escapeRegExp(searchValue)})`, 'gi'));
    const setFilterValueHandler = useCallback(() => {
        onSelectValue(suggestion);
    }, [suggestion, onSelectValue]);
    const mouseOverHandler = useCallback(() => {
        setFocusElement(elementIndex);
    }, [setFocusElement, elementIndex]);
    return (_jsx("div", { className: classNames('Suggestions-item', { focused: focusElementIndex === elementIndex }), onMouseOver: mouseOverHandler, onClick: setFilterValueHandler, children: innerStringParts.map((part, index) => part.toLowerCase() === searchValue.toLowerCase() ? (_jsx("span", { children: part }, part + index)) : (_jsx("b", { children: part }, part + index))) }));
};
