import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle } from '../Dropdown';
import { logoByProduct } from './utils';
import './style.scss';
import { menuButton } from './icons';
const CloudMenu = ({ cloudDomains, productNames, useDomainLogos, }) => {
    const filteredMenu = Object.values(cloudDomains).filter(domain => domain.showInNavigation);
    return (_jsx("li", { className: 'nav-item cloud-menu-wrap', children: _jsxs(DropdownButton, { direction: 'down', children: [_jsx(DropdownToggle, { children: _jsx("div", { className: 'menu-button', children: menuButton }) }), _jsx(DropdownMenu, { className: 'product-dropdown', children: filteredMenu.map(domain => {
                        return (_jsx(DropdownItem, { children: _jsxs("a", { href: `${window.location.protocol}//${domain.domain}`, children: [useDomainLogos
                                        ? domain.logoSrc && (_jsx("img", { src: domain.logoSrc, alt: productNames[domain.product], className: 'product-logo', onError: event => {
                                                event.currentTarget.style.display = 'none';
                                            } }))
                                        : logoByProduct[(productNames[domain.product] || domain.product)], _jsx("span", { children: productNames[domain.product] })] }) }, `header_menu_${domain.domain}`));
                    }) })] }) }));
};
export default CloudMenu;
