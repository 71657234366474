export const FilterTypeNameList = [
    'DATE_TIME_RANGE',
    'RADIO',
    'CHECKBOX',
    'NUMERIC_RANGE',
    'CUSTOM_FIELDS_IN_RESPONSE',
    'TEXT',
    'TREE',
    'COMPOSITE_FILTER',
    'MULTIPLE_FILTER',
];
export class FiltersPanelState {
    constructor() {
        this.filters = [];
        this.usedFilters = [];
    }
}
export var TabsTypes;
(function (TabsTypes) {
    TabsTypes["volumes"] = "volumes";
    TabsTypes["results"] = "results";
})(TabsTypes || (TabsTypes = {}));
export var SessionsTabsTypes;
(function (SessionsTabsTypes) {
    SessionsTabsTypes["sessions"] = "sessions";
    SessionsTabsTypes["messages"] = "messages";
    SessionsTabsTypes["diagrams"] = "diagrams";
    SessionsTabsTypes["routes"] = "routes";
})(SessionsTabsTypes || (SessionsTabsTypes = {}));
