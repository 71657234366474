import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import caila from 'images/caila.svg';
import tovieDS from 'images/tovie/t-dialogstudio.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/ImmersStyleComponent'));

export default class ImmersTheme extends BaseTheme {
  backgroundSvgColor: string = '#353374';

  renderTitle() {
    const cailaDomain = this.currentDomainData.domain;
    const title = !isEuroInstance() ? (
      <div className='app-title_immers'>
        <h1>Caila</h1>
        <h2>by Just AI</h2>
      </div>
    ) : null;
    const immersInfo = !isEuroInstance() ? (
      <div className='immers-info'>
        <p className='immers-info__text'>
          <b>Caila</b> – платформа для хостинга, обучения и тестирования ML-моделей.
          <br /> Caila разработана компанией Just AI и интегрирована с платформой Immers.cloud.
        </p>
        <p className='immers-info__text_sm'>
          Для использования сервиса <b>caila.immers.cloud</b> необходимо создать учетную запись в Caila. Пожалуйста,
          зарегистрируйтесь!
        </p>
        <div className='flex-row gap-48 margin-top-48'>
          <a href='https://immers.cloud/' target='_blank' rel='noreferrer noopener'>
            Immers.cloud
          </a>
          <a href='https://just-ai.com/' target='_blank' rel='noreferrer noopener'>
            just-ai.com
          </a>
        </div>
      </div>
    ) : null;

    return cailaDomain ? (
      <>
        <a className='app-link' href={`${window.location.protocol}//${cailaDomain}`} style={{ textDecoration: 'none' }}>
          {title}
        </a>
        {immersInfo}
      </>
    ) : (
      <>
        {title}
        {immersInfo}
      </>
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = '/c/caila.svg';
    description.content = '';
  }

  renderLogo() {
    const cailaDomain = this.currentDomainData.domain;

    const logo = (
      <div className='app-logo'>
        <img src={isEuroInstance() ? tovieDS : caila} alt='logo' />
      </div>
    );
    return cailaDomain ? (
      <a className='app-link' href={`${window.location.protocol}//${cailaDomain}`} style={{ textDecoration: 'none' }}>
        {logo}
      </a>
    ) : (
      logo
    );
  }
}
