import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import copilot from 'images/copilot.png';
import tovieCopilot from 'images/tovie/t-copilot.svg';
import tovieCopilotText from 'images/tovie/t-copilot-text.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/CopilotStyleComponent'));

export default class CopilotTheme extends BaseTheme {
  backgroundSvgColor: string = '#353374';

  renderTitle() {
    return (
      <div className='app-title copilot'>
        {isEuroInstance() ? (
          <img src={tovieCopilotText} alt='description-logo' />
        ) : (
          <>
            <h1>Jay Copilot</h1>
            <h2>Just AI</h2>
          </>
        )}
      </div>
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = isEuroInstance() ? '/c/tovie/t-copilot.svg' : '/c/copilot.svg';
    description.content = '';
  }

  renderLogo() {
    return (
      <div className='app-logo'>
        <img
          src={isEuroInstance() ? tovieCopilot : copilot}
          alt='logo'
          style={isEuroInstance() ? { maxWidth: 170 } : undefined}
        />
      </div>
    );
  }
}
