var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { axios, getUserLanguage } from '../utils/pipes/functions';
import '../components/FiltersPanel/FilterModal/BaseModalFilter.scss';
import { ChannelTypeIcon } from '../components/ChannelTypeIcon';
import { AnalyticsSection, FiltersApi } from '../api/client';
import queryString from 'query-string';
import moment from 'moment';
const RESOURSES = {
    sessions: AnalyticsSection['SESSIONS'],
    messages: AnalyticsSection['SESSIONS'],
    diagrams: AnalyticsSection['SESSIONS'],
    clients: AnalyticsSection['CLIENTS'],
    clients_dialogs: AnalyticsSection['CLIENTSDIALOGS'],
    overview: AnalyticsSection['SESSIONS'],
    reports_activity: AnalyticsSection['REPORTSACTIVITY'],
    reports_efficiency: AnalyticsSection['REPORTSEFFICIENCY'],
    reports_session_topic: AnalyticsSection['REPORTSSESSIONTOPIC'],
    reports_session_result: AnalyticsSection['REPORTSSESSIONRESULT'],
    reports_scenario_action: AnalyticsSection['REPORTSSCENARIOACTION'],
};
export default class FiltersFetchInterface {
    constructor() {
        this.filtersApi = new FiltersApi({}, '', axios);
        this.changeResource = (resource) => {
            this.resource = RESOURSES[resource.toLowerCase()] || resource;
        };
        this.changeAccountId = (accountId) => {
            this.accountId = accountId;
        };
        this.changeProjectShortName = (projectShortName) => {
            this.projectShortName = projectShortName;
        };
        this.getFilters = (props) => __awaiter(this, void 0, void 0, function* () {
            const filtersFromQuery = this.getFiltersFromQuery();
            let filters = yield this.filtersApi.getAvailableFilters(this.accountId, this.resource, getUserLanguage());
            if (this.excludeFilters) {
                filters.data = filters.data.filter(filter => { var _a; return ((_a = this.excludeFilters) === null || _a === void 0 ? void 0 : _a.includes(filter.key)) === false; });
            }
            try {
                filtersFromQuery.forEach(({ key, value, comparisonType, endValue }) => {
                    const filter = filters.data.find(x => x.key === key);
                    if (filter) {
                        if (!filter.settings)
                            filter.settings = {};
                        filter.isDefault = true;
                        if (['CALL_TASK_ID', 'SESSION_RESULT', 'TEXT_CAMPAIGN_ID', 'TEXT_CAMPAIGN_RESULT'].includes(key)) {
                            filter.settings.defaultOptions = value ? [value] : [];
                            if (comparisonType) {
                                filter.settings.defaultComparisonOptions = comparisonType;
                            }
                        }
                        else if (key === 'WITHOUT_EMPTY_SESSIONS') {
                            filter.settings.defaultValue = value;
                        }
                        else if (key === 'BOT_ID') {
                            filter.settings.defaultOptions = value;
                        }
                        else if (['SESSION_START_TIME', 'MESSAGE_TIME', 'CLIENT_LAST_ACTIVITY_TIME', 'CLIENT_JOIN_TIME'].includes(key)) {
                            const defaultValue = { unit: 'SECONDS' };
                            if (value) {
                                defaultValue.offset = this.getDiffInSeconds(value);
                            }
                            if (endValue) {
                                defaultValue.endOffset = this.getDiffInSeconds(endValue);
                            }
                            if (comparisonType) {
                                defaultValue.comparisonType = comparisonType;
                            }
                            filter.settings.defaultValue = defaultValue;
                        }
                    }
                });
            }
            catch (e) { }
            return filters;
        });
        this.getFiltersFromQuery = () => {
            const { search } = this.location;
            const query = queryString.parse(search);
            let result = [];
            if (query['callTaskId']) {
                result.push({ key: 'CALL_TASK_ID', value: query['callTaskId'] });
            }
            if (query['textCampaignTaskId']) {
                result.push({ key: 'TEXT_CAMPAIGN_ID', value: query['textCampaignTaskId'] });
            }
            if (query['mailingResults']) {
                result.push({ key: 'SESSION_RESULT', value: query['mailingResults'], comparisonType: 'CONTAINS' });
            }
            if (query['textCampaignResults']) {
                result.push({
                    key: 'TEXT_CAMPAIGN_RESULT',
                    value: decodeURIComponent(query['textCampaignResults']),
                    comparisonType: 'CONTAINS',
                });
            }
            if (query['mailingResultsEmpty']) {
                result.push({ key: 'SESSION_RESULT', comparisonType: 'NOT_EXISTS' });
            }
            if (query['textCampaignResultsEmpty']) {
                result.push({ key: 'TEXT_CAMPAIGN_RESULT', comparisonType: 'NOT_EXISTS' });
            }
            if (query['botIds']) {
                // @ts-ignore
                result.push({ key: 'BOT_ID', value: query['botIds'].split(',') });
            }
            if (query['dateFrom'] && query['dateTo']) {
                const values = { value: new Date(+query['dateFrom']), endValue: new Date(+query['dateTo']) };
                result.push(Object.assign({ key: 'SESSION_START_TIME' }, values));
                result.push(Object.assign({ key: 'MESSAGE_TIME' }, values));
                result.push(Object.assign({ key: 'CLIENT_LAST_ACTIVITY_TIME' }, values));
                result.push({ key: 'CLIENT_JOIN_TIME', endValue: values.endValue });
            }
            else if (query['dateFrom']) {
                const values = { value: new Date(+query['dateFrom']) };
                result.push(Object.assign({ key: 'MESSAGE_TIME' }, values));
            }
            if (query['dateFromSessionStartTime'] && query['dateToSessionStartTime']) {
                const values = {
                    value: new Date(+query['dateFromSessionStartTime']),
                    endValue: new Date(+query['dateToSessionStartTime']),
                };
                const sessionsValues = {
                    value: new Date(+query['dateFromSessionStartTime']),
                    endValue: new Date(+query['dateToSessionStartTime']),
                };
                result.push(Object.assign({ key: 'MESSAGE_TIME' }, values));
                result.push(Object.assign({ key: 'SESSION_START_TIME' }, sessionsValues));
                result.push({ key: 'WITHOUT_EMPTY_SESSIONS', value: 'YES' });
            }
            if (query['dateFromClient'] && query['dateToClient']) {
                const values = {
                    value: new Date(+query['dateFromClient']),
                    endValue: new Date(+query['dateToClient']),
                };
                result.push(Object.assign({ key: 'CLIENT_JOIN_TIME' }, values));
                result.push(Object.assign({ key: 'MESSAGE_TIME' }, values));
            }
            if (query['dateFromMessageTime'] && query['dateToMessageTime']) {
                const values = {
                    value: new Date(+query['dateFromMessageTime']),
                    endValue: new Date(+query['dateToMessageTime']),
                };
                result.push(Object.assign({ key: 'MESSAGE_TIME' }, values));
            }
            return result;
        };
        this.getFiltersOptions = (key_1, type_1, ...args_1) => __awaiter(this, [key_1, type_1, ...args_1], void 0, function* (key, type, options = {
            size: 0,
            filters: [],
            useAllFilters: false,
        }) {
            try {
                const { data } = yield this.filtersApi.getFilterOptions(this.accountId, key, this.resource, this.projectShortName, undefined, undefined, options.size, { filters: (options.filters || []).filter(filter => options.useAllFilters || filter.key !== key) });
                return ['CHECKBOX'].includes(type) ? data.map(this.normalizeOptions.bind(this, key)) : data;
            }
            catch (e) {
                console.log(e);
                return [];
            }
        });
        this.normalizeOptions = (key, x) => {
            var _a;
            const result = {
                value: x.value,
                label: x.label,
                isSelected: x.isSelected,
                channelName: x.channelName,
                channelType: x.channelType,
            };
            if (x.frequency && x.frequency !== null) {
                result.count = x.frequency || 0;
            }
            if (key === 'CHANNEL_TYPE') {
                let label = result.label;
                if (label === 'incoming_webim2')
                    label = 'webim';
                if (label === 'chatwidget')
                    label = 'chat_widget';
                result.label = this.t(`ChannelType ${label.toUpperCase()}`);
                result.icon = (_jsx("div", { className: 'BaseModalFilter_container_iconContainer', children: _jsx(ChannelTypeIcon, { channelType: x.value, isEuroInstance: this.isEuroInstance }) }));
            }
            if (key === 'CHANNEL_NAME') {
                result.icon = (_jsx("div", { className: 'BaseModalFilter_container_iconContainer', children: _jsx(ChannelTypeIcon, { channelType: (_a = x === null || x === void 0 ? void 0 : x.channelType) === null || _a === void 0 ? void 0 : _a.toLowerCase(), isEuroInstance: this.isEuroInstance }) }));
            }
            return result;
        };
        this.getIntervalOptions = (key, filters, locale) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.filtersApi.getIntervalFilterOptions(this.accountId || -1, key, this.resource, this.projectShortName || '', locale, { filters: (filters || []).filter(filter => filter.key !== key) });
                return data;
            }
            catch (e) {
                return {};
            }
        });
    }
    init(accountId, resource, projectShortName, t, location, propsAxios, config, excludeFilters, isEuroInstance) {
        this.accountId = accountId;
        this.resource = RESOURSES[resource.toLowerCase()] || resource;
        this.projectShortName = projectShortName;
        this.location = location;
        this.t = t;
        this.axios = propsAxios || axios;
        this.filtersApi = new FiltersApi({}, '', this.axios);
        this.isEuroInstance = isEuroInstance;
        if (config) {
            this.config = config;
        }
        if (excludeFilters) {
            this.excludeFilters = excludeFilters;
        }
        return this;
    }
    getDiffInSeconds(value) {
        return moment(new Date()).diff(moment(value), 'seconds');
    }
}
