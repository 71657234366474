import React, { Component } from 'react';
import { history } from 'App';
import axios from 'axios';
import * as queryString from 'query-string';
import { Spinner } from '@just-ai/just-ui/dist';

import { getDomainData, isDev } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import chatSupportController from 'helpers/chatSupportController';
import LoginService from 'service/LoginService';

class AuthWrapper extends Component<{ location?: { pathname: string; search: string } }, { fetching: boolean }> {
  static contextType = AppContext;
  timer: ReturnType<typeof setTimeout> | null = null;
  supportTimer: ReturnType<typeof setTimeout> | null = null;

  state = {
    fetching: false,
  };

  LoginService = new LoginService();

  componentWillMount() {
    const { location } = this.props;
    const { appConfig } = this.context;

    this.supportAddOnMessageListener();

    const load = async () => {
      const { setCurrentUser } = this.context;

      const {
        // @ts-ignore
        location: { search },
      } = this.props;
      const { redirectUrl, token } = queryString.parse(search.replace('?', ''));

      this.setState({ fetching: true });

      let tokenError = '';

      if (token) {
        try {
          await this.LoginService.autoLogin(token as string);
          if (
            !isDev() &&
            redirectUrl &&
            redirectUrl !== window.location.origin &&
            redirectUrl !== window.location.href
          ) {
            setTimeout(() => {
              window.location.href = redirectUrl as string;
            }, 3000);
          }
        } catch (error) {
          if (
            window.location.pathname === '/c/profile' &&
            !isDev() &&
            redirectUrl &&
            !redirectUrl.includes(window.location.href)
          ) {
            window.location.href = (redirectUrl as string).replace(
              'change-email-result=success',
              'change-email-result=failed'
            );
          }
          tokenError = 'error=accountsadmin.autologin.invalid.token';
          history.push({ search: 'error=accountsadmin.autologin.invalid.token' });
        }
        if (
          window.location.pathname === '/c/profile' &&
          !isDev() &&
          redirectUrl &&
          !redirectUrl.includes(window.location.href) &&
          redirectUrl !== window.location.origin
        ) {
          return;
        }
      }

      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();
        if (!data.userData) return;
        await setCurrentUser(data);
        if (
          appConfig.registration.userNeedToSpecifyCountryIsoCode &&
          !data.userData.countryIsoCode &&
          !data.userData.internal &&
          data.userData.accountOwner
        ) {
          history.push(`/c/select-country${search}`);
        }
      } catch (e) {
        const innerRedirectUrl = window.location.href;
        const searchParams = new URLSearchParams(location?.search);
        if (window.location.host === appConfig?.domains['cc']?.domain) {
          searchParams.set('redirectUrl', innerRedirectUrl);
        }
        tokenError && searchParams.set('tokenError', tokenError);
        history.push({
          pathname: '/c/login',
          search: '?' + searchParams.toString(),
        });
      } finally {
        this.setState({
          fetching: false,
        });
      }
    };

    load();
    this.timer = setTimeout(this.checkIsUserAuthorizedbyInterval, 10000);
  }

  checkIsUserAuthorizedbyInterval = async () => {
    const { location } = this.props;
    try {
      await this.LoginService.checkIsUserAuthorized();
      this.timer = setTimeout(this.checkIsUserAuthorizedbyInterval, 10000);
    } catch (error) {
      if (axios.isAxiosError(error) && error.code === 'ECONNABORTED' && isDev() && module.hot?.active) {
        console.error('While HMR check-authorize timeout', error);
        return;
      }
      history.push(`/c/login${location?.search.replace('?', '&')}`);
    }
  };

  componentWillUnmount() {
    if (!this.timer) return;
    clearTimeout(this.timer);
  }

  supportAddOnMessageListener = () => {
    try {
      chatSupportController.init();
      chatSupportController.show();
    } catch (e) {
      this.supportTimer = setTimeout(this.supportAddOnMessageListener, 500);
    }
  };

  render() {
    const { fetching } = this.state;
    const { children } = this.props;

    if (fetching) return <Spinner size='4x' />;

    return <div className='wrapper'>{children}</div>;
  }
}

export default AuthWrapper;
