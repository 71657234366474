import React from 'react';
import { DomainOptions, OptionsResponseDto } from '@just-ai/api/dist/generated/Accountsadmin';

import { getCurrentDomainObject, isDev } from 'pipes/functions';

import { isEuroInstance } from 'helpers/isAccessFunction';
import aimychat from 'images/aimychat.svg';
import aimylogic from 'images/aimylogic.svg';
import aimyvoice from 'images/aimyvoice.svg';
import caila from 'images/caila.svg';
import generalLogo from 'images/general-logo.svg';
import jaicf from 'images/jaicf.svg';
import jaicp from 'images/jaicp.svg';
import tovieGeneralLogo from 'images/tovie/t-general-logo.svg';

const StyleComponent = React.lazy(() => import('./styleComponents/BaseStyleComponent'));

const LOGOS = [caila, jaicp, jaicf, aimylogic, aimyvoice, aimychat];

export default class BaseTheme {
  appOptions: OptionsResponseDto;
  currentDomainData: DomainOptions;
  backgroundSvgColor: string = '#BA75E099';

  constructor(appOptions: OptionsResponseDto) {
    this.currentDomainData =
      getCurrentDomainObject(
        appOptions.domains,
        isDev() ? new URL(process.env.REACT_APP_CLOUD_HOST || window.location.origin).hostname : window.location.host
      ) || ({} as DomainOptions);
    this.appOptions = appOptions;
  }

  getProductName() {
    return this.appOptions.productNames[this.currentDomainData.product];
  }

  renderTitle(): JSX.Element | null {
    return (
      <div className='app-title general-logo'>
        <h1>{this.getProductName()}</h1>
        <h2>{isEuroInstance() ? 'Tovie AI' : 'Just AI'}</h2>
      </div>
    );
  }

  getMetaLinks() {
    let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (!Boolean(link)) {
      link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    const description = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    return {
      link,
      description,
    };
  }

  renderLogo() {
    return (
      <div className='app-logo'>
        <img src={isEuroInstance() ? tovieGeneralLogo : generalLogo} alt='logo' />
      </div>
    );
  }

  setAppMeta() {
    let { link, description } = this.getMetaLinks();
    description = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    document.title = this.getProductName();
    link.href = isEuroInstance() ? '/c/tovie/t-general-logo.svg' : '/c/general-logo.svg';
    description.content = '';
  }

  renderLogosArr() {
    <div className='base-page_logos'>
      {!isEuroInstance() &&
        LOGOS.map((logo, index) => (
          <div key={`logo_${index}`} className='base-page_logos-logo'>
            <img src={logo} alt='' />
          </div>
        ))}
    </div>;
  }

  getStyleWrapper() {
    return StyleComponent;
  }
}
