var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BaseModalFilter from './BaseModalFilter';
import { clickOnCheckbox, InputGroup, InputText, isParent, JustSelect, Tooltip, TreeComponentWithCheckbox, } from '@just-ai/just-ui';
import './BaseModalFilter.scss';
import { Theme } from '@just-ai/just-ui/dist/Tree/components';
import { createComparisonOptions } from './TextModal';
import classNames from 'classnames';
import { AnalyticsContext } from '../../../../Reporter/context/AnalyticsContext';
const createDatasetTreeState = (data, t, altClickEnabled) => {
    return data.reduce((dataSet, currentValue) => {
        dataSet[currentValue.value] = {
            nodeId: currentValue.value,
            name: currentValue.label,
            children: currentValue.childValues,
            parent: currentValue.parentValue,
            count: currentValue.frequency,
            selectedChildCount: 0,
            checkbox: 'NONE',
            customData: currentValue.filterOptionCustomData,
        };
        if (currentValue.childValues && currentValue.childValues.length > 0) {
            if (altClickEnabled) {
                dataSet[currentValue.value] = Object.assign(Object.assign({}, dataSet[currentValue.value]), { checkboxTooltip: props => {
                        return _jsx(Tooltip, Object.assign({}, props, { children: t('TreeSelectionAvailableWithAlt') }));
                    } });
            }
            dataSet[currentValue.value] = Object.assign(Object.assign({}, dataSet[currentValue.value]), { selectorCountTooltip: props => {
                    return _jsx(Tooltip, Object.assign({}, props, { children: t('TreeSelectionCount') }));
                } });
        }
        return dataSet;
    }, {});
};
class TreeModalState {
    constructor() {
        this.treeData = {};
        this.treeFilter = '';
        this.comparisonType = '';
        this.hideTree = false;
    }
}
class TreeModal extends BaseModalFilter {
    constructor(props) {
        var _a, _b;
        super(props);
        this.state = new TreeModalState();
        this.filterInput = null;
        this.selectedTreeItems = [];
        this.dataSet = {};
        this.filterDataset = (value) => {
            this.setState({
                treeFilter: value,
            });
        };
        this.fetchAndSaveOptions = () => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            const { filter, filter: { key, type }, filtersFetcher, optionsArguments, } = this.props;
            const { options } = ((_c = this.props.filter) === null || _c === void 0 ? void 0 : _c.oldValue) || {};
            if (!optionsArguments)
                return;
            try {
                const data = yield filtersFetcher.getFiltersOptions(key, type, {
                    size: 0,
                    filters: optionsArguments.filters,
                });
                this.context.setChannelsOptionsFilter(data);
                this.dataSet = createDatasetTreeState(data, this.props.t, ((_d = filter.settings) === null || _d === void 0 ? void 0 : _d.altClickEnabled) || false);
                if (this.selectedTreeItems.length !== (options === null || options === void 0 ? void 0 : options.length)) {
                    this.selectedTreeItems = options || [];
                }
                if (this.selectedTreeItems.length > 0) {
                    this.selectedTreeItems.forEach(selectedTreeItem => {
                        var _a;
                        if (this.dataSet[selectedTreeItem] && !isParent(this.dataSet[selectedTreeItem])) {
                            this.dataSet = clickOnCheckbox(this.dataSet[selectedTreeItem], this.dataSet, {
                                altClickEnabled: (_a = filter.settings) === null || _a === void 0 ? void 0 : _a.altClickEnabled,
                            });
                        }
                    });
                    const parentArray = Object.values(this.dataSet).filter(treeNode => treeNode.children && treeNode.children.length > 0);
                    parentArray.forEach(parent => {
                        var _a;
                        if (parent.checkbox &&
                            ['ALL', 'PARTIAL'].includes(parent.checkbox) &&
                            !this.selectedTreeItems.includes(parent.nodeId) &&
                            ((_a = filter.settings) === null || _a === void 0 ? void 0 : _a.altClickEnabled)) {
                            parent.checkbox = 'NONE-PARENT';
                        }
                    });
                }
                this.setState({
                    treeData: this.dataSet,
                });
            }
            catch (e) {
                console.error(e);
            }
        });
        this.onConfirm = () => {
            let result = {
                options: this.selectedTreeItems,
            };
            if (this.state.comparisonType) {
                result = Object.assign(Object.assign({}, result), { 
                    //@ts-ignore
                    comparisonType: this.state.comparisonType });
            }
            if (this.filterInput) {
                this.filterInput.value = '';
            }
            this.setState({
                treeFilter: '',
            }, () => {
                this.props.onConfirm(result, false);
            });
        };
        this.onCancel = () => {
            const oldValue = this.getOldValue();
            if (!oldValue && !this.props.filter.isRequired)
                return this.props.onDelete();
            if (this.filterInput) {
                this.filterInput.value = '';
            }
            this.setState({
                treeFilter: '',
            }, () => {
                this.props.onCancel();
            });
        };
        this.setCheckboxed = (dataset) => {
            const values = Object.values(dataset);
            const selected = values.filter((value) => !value.selectedChildCount && value.checkbox && !['NONE', 'NONE-PARENT'].includes(value.checkbox));
            this.selectedTreeItems = selected.map(select => select.nodeId);
        };
        this.onChangeComparison = (value) => {
            const { comparisonType } = this.state;
            //@ts-ignore
            if (comparisonType === value[0])
                return;
            let hideTree = value.value === 'NOT_IMPORTANT';
            this.setState({
                comparisonType: value[0],
                hideTree,
            }, () => { });
        };
        this.getLocalizationMessage = () => {
            var _a;
            const { filter: { key }, t, } = this.props;
            const comparisonTranslation = t(`TreeComparisonOptions:${key}`);
            if (comparisonTranslation === `TreeComparisonOptions:${key}` ||
                //@ts-ignore
                ((_a = this.state.comparisonType) === null || _a === void 0 ? void 0 : _a.value) === 'NOT_IMPORTANT') {
                return null;
            }
            return (_jsx("div", { className: classNames('text-muted', 'BaseModalFilter_container_textMuted'), children: comparisonTranslation }));
        };
        this.renderInputs = () => {
            var _a, _b;
            const { filter, t, locale } = this.props;
            const { comparisonType } = this.state;
            const messages = {
                nothingFound: t('TreeModal:nothingFound'),
                isEmpty: t('TreeModal:isEmpty'),
            };
            return (_jsxs("div", { className: 'BaseModalFilter_container_inputsContainer', children: [this.comparisonOptions.length > 0 && (_jsxs("div", { className: 'BaseModalFilter_container_selectTreeOptionsContainer', children: [_jsx(JustSelect, { value: comparisonType, options: this.comparisonOptions, onChange: this.onChangeComparison }), this.getLocalizationMessage()] })), !this.state.hideTree && (_jsxs("div", { className: classNames('BaseModalFilter_container_inputsTreeContainer'), "data-test-id": 'inputsTreeContainer', children: [_jsx("div", { className: 'BaseModalFilter_container_treeInputContainer', children: _jsx(InputGroup, { AppendInner: this.state.treeFilter
                                        ? [
                                            {
                                                color: 'secondary',
                                                name: 'faTimesCircle',
                                                onClick: () => {
                                                    if (this.filterInput) {
                                                        this.filterInput.value = '';
                                                    }
                                                    this.setState({
                                                        treeFilter: '',
                                                    });
                                                },
                                            },
                                        ]
                                        : [], PrependInner: [
                                        {
                                            className: 'widePrepend',
                                            color: 'secondary',
                                            name: 'farSearch',
                                            onClick: event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            },
                                        },
                                    ], size: 'sm', children: _jsx(InputText, { bsSize: 'sm', placeholder: (_a = filter.settings) === null || _a === void 0 ? void 0 : _a.placeholder, onChange: this.filterDataset, innerRef: (ref) => (this.filterInput = ref) }) }) }), _jsx("div", { className: 'BaseModalFilter_container_treeContainer', children: _jsx(TreeComponentWithCheckbox, { locale: locale, saveToLocalStorageNamePrefix: `filterTreeState${filter.key}`, dataset: this.state.treeData, theme: Theme.light, compact: true, altClickEnabled: ((_b = filter.settings) === null || _b === void 0 ? void 0 : _b.altClickEnabled) || false, filter: this.state.treeFilter, onCheckbox: this.setCheckboxed, style: { maxHeight: 24 * 7 }, className: 'justuiTree', Message: (_a) => {
                                        var { type } = _a, props = __rest(_a, ["type"]);
                                        return (
                                        //@ts-ignore style is not compatible with npm link
                                        _jsx("div", Object.assign({ className: 'tree-message', style: { paddingTop: 74 } }, props, { children: messages[type] })));
                                    } }) })] }))] }));
        };
        let { comparisonOptions } = ((_a = props === null || props === void 0 ? void 0 : props.filter) === null || _a === void 0 ? void 0 : _a.settings) || {};
        const { options, comparisonType } = ((_b = this.props.filter) === null || _b === void 0 ? void 0 : _b.oldValue) || {};
        if (comparisonType) {
            comparisonOptions = comparisonOptions.map((comparisonTypeObject) => {
                comparisonTypeObject.isDefault = comparisonTypeObject.type === comparisonType;
                return comparisonTypeObject;
            });
        }
        let [comparisonOptionsFromFunction, comparisonTypeNew] = createComparisonOptions(comparisonOptions);
        this.comparisonOptions = comparisonOptionsFromFunction;
        this.selectedTreeItems = options || [];
        this.state = {
            treeData: {},
            treeFilter: '',
            hideTree: comparisonTypeNew && comparisonTypeNew.value === 'NOT_IMPORTANT',
            comparisonType: comparisonTypeNew === null || comparisonTypeNew === void 0 ? void 0 : comparisonTypeNew.value,
        };
    }
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (!this.state.hideTree && !prevProps.showModal && this.props.showModal) {
            this.setState({
                hideTree: true,
            }, () => {
                this.setState({ hideTree: false });
            });
        }
        if (this.props.showModal && prevProps.showModal === false) {
            this.checkPosition();
        }
    }
    componentDidMount() {
        this.fetchAndSaveOptions();
    }
}
TreeModal.contextType = AnalyticsContext;
export default TreeModal;
