import React from 'react';
import { Icon } from '@just-ai/just-ui/dist';

import { t } from 'localization';
import localize from 'localization';

import { useAppContext } from 'components/AppContext';

import { footerLocalization } from './localization/footer.loc';

import './index.scss';

localize.addTranslations(footerLocalization);

const Footer = () => {
  const { domainOptions, tosAndPos } = useAppContext();

  return (
    <div className='footer'>
      <div className='footer_copyright'>{domainOptions?.copyright && <small>{domainOptions.copyright}</small>}</div>
      <div className='footer_links'>
        <a href={tosAndPos?.termsOfUseUrl} target='_blank' rel='noopener noreferrer'>
          <span>{t('Footer: ToS link text')}</span>
          <Icon name='falExternalLink' color='primary' size='1x' />
        </a>{' '}
        <a href={tosAndPos?.privacyPolicyUrl} target='_blank' rel='noopener noreferrer'>
          <span>{t('Footer: policy link text')}</span>
          <Icon name='falExternalLink' color='primary' size='1x' />
        </a>
      </div>
    </div>
  );
};

export default Footer;
