import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './checkboxSelect.scss';
import { Checkbox } from '../Checkbox';
import VirtualList from 'react-tiny-virtual-list';
const DEFAULT_VIRTUAL_LIST_CONTAINER_HEIGHT = 250;
const DEFAULT_VIRTUAL_LIST_ITEM_HEIGHT = 30;
const sumVirtualItemsHeight = (vitualItems, heightGetter) => {
    if (typeof heightGetter === 'number') {
        return vitualItems.length * heightGetter;
    }
    let sum = 0;
    vitualItems.forEach((item, index) => {
        sum += heightGetter(index);
    });
    return sum;
};
export const CheckboxSelect = ({ values = [], onChange = () => { }, style, bsSize = 'sm', dark, selected, withVirtualList, virtualListContainerHeight = DEFAULT_VIRTUAL_LIST_CONTAINER_HEIGHT, virtualListItemHeight = DEFAULT_VIRTUAL_LIST_ITEM_HEIGHT, }) => {
    if (withVirtualList) {
        const listHeightOnItemsLenght = sumVirtualItemsHeight(values, virtualListItemHeight);
        const listHeight = listHeightOnItemsLenght > virtualListContainerHeight ? virtualListContainerHeight : listHeightOnItemsLenght;
        return (_jsx("div", { className: 'checkboxes-list', style: Object.assign({}, style), "data-test-id": 'virtual-list-container', children: _jsx(VirtualList, { itemCount: values.length, height: listHeight, itemSize: virtualListItemHeight, className: 'checkboxes-list-virtual-list', "data-test-id": 'virtual-list', renderItem: ({ index, style }) => {
                    const item = values[index];
                    return (_jsx("span", { style: style, "data-test-id": 'virtual-list-item-wrapper', children: _jsx(ItemToRender, { itemToRender: item, bsSize: bsSize, dark: dark, onChange: onChange, selected: selected }, item.value) }));
                } }, 'checkbox-select-virtual-list') }));
    }
    return (_jsx("div", { className: 'checkboxes-list', style: Object.assign({}, style), "data-test-id": 'checkbox-select-container', children: values.map(item => (_jsx(ItemToRender, { itemToRender: item, bsSize: bsSize, dark: dark, onChange: onChange, selected: selected }, item.value))) }));
};
const ItemToRender = ({ itemToRender, bsSize, dark, onChange, selected, }) => {
    const { value, id, label, icon, count = '' } = itemToRender;
    return (_jsx(Checkbox, { bsSize: bsSize, dark: dark, noMargin: false, name: value, id: id, defaultChecked: (selected || []).includes(value), "data-test-id": `select-item-${itemToRender.value}`, label: _jsxs("span", { className: 'value', children: [icon && _jsx("span", { className: 'icon', children: icon }), _jsx("span", { className: 'name', style: {
                        maxWidth: `calc( 100% - ${count.toString().length - 1}rem - 0.3em${Boolean(icon) ? ' - 3.5rem' : ''})`,
                        color: 'var(--gray-800)',
                    }, children: label }), ' ', Number.isInteger(count) && _jsxs("span", { className: 'count', children: ["(", count, ")"] })] }), onChange: onChange }, `value-key-${value}`));
};
