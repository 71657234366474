import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { StylingLabel } from './Label';
const TreeLabel = ({ value: { argumentsFilter, filters, filter, filtersFetcher, t } }) => {
    const [dataTree, setDataTree] = useState([]);
    const { options } = argumentsFilter;
    const { key, type } = filter || {};
    useEffect(() => {
        filtersFetcher === null || filtersFetcher === void 0 ? void 0 : filtersFetcher.getFiltersOptions(key, type, {
            size: 0,
            filters,
        }).then(labels => setDataTree(labels));
    }, [filters, filtersFetcher, key, type]);
    const value = dataTree === null || dataTree === void 0 ? void 0 : dataTree.filter(dataTreeItem => { var _a; return (options === null || options === void 0 ? void 0 : options.includes(String(dataTreeItem.value))) && !((_a = dataTreeItem === null || dataTreeItem === void 0 ? void 0 : dataTreeItem.childValues) === null || _a === void 0 ? void 0 : _a.length); }).map(labelName => labelName.label).join(', ').toLowerCase();
    return _jsx(StylingLabel, { label: value ? undefined : t('Analytics:FiltersPanel:NotSelected'), value: value });
};
export default TreeLabel;
