import cn from 'classnames';
import { WorkplacesLimitsDto } from '@just-ai/api/dist/generated/Accountsadmin';

import { t } from 'localization';

import { useAppContext } from 'components/AppContext';

import styles from './styles.module.scss';

export const LimitsHeader = ({ limitsData }: { limitsData: WorkplacesLimitsDto }) => {
  const { appConfig } = useAppContext();
  return (
    <div className={styles.LimitsHeader}>
      <h3 className={styles.title}>{t('AccountUsers:LimitsHeader:Title')}</h3>
      <div className={styles.LimitsHeader__content}>
        {Object.entries(limitsData.workplacesLimits).map(([product, limits]) => (
          <div key={product} className={styles.limitItem}>
            <span className={styles.productName}>{appConfig?.productNames[product]}:</span>
            <span className={cn(styles.value, { [styles.overflow]: limits.taken >= limits.limit })}>
              {t('AccountUsers:Limits:Used', { used: limits.taken })}
            </span>
            <span className={styles.separator}>/</span>
            <span className={styles.value}>{t('AccountUsers:Limits:Max', { max: limits.limit })}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
