import { jsx as _jsx } from "react/jsx-runtime";
import { StylingLabel } from './Label';
const CheckboxLabel = ({ value: { argumentsFilter, filter, sessionLabels, hiddenOptionsArguments, t }, }) => {
    var _a, _b, _c, _d;
    const { comparisonType, options } = argumentsFilter;
    const { comparisonOptions } = (filter === null || filter === void 0 ? void 0 : filter.settings) || { comparisonOptions: [] };
    switch (filter === null || filter === void 0 ? void 0 : filter.key) {
        case 'SESSION_LABELS': {
            const label = (_b = (_a = comparisonOptions === null || comparisonOptions === void 0 ? void 0 : comparisonOptions.find(x => (options ? x.type === comparisonType : x.isDefault))) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.toLowerCase();
            const value = hiddenOptionsArguments(comparisonType) &&
                sessionLabels
                    .filter(label => options === null || options === void 0 ? void 0 : options.includes(String(label.id)))
                    .map(label => label.name)
                    .join(', ')
                    .toLowerCase();
            return _jsx(StylingLabel, { label: label, value: value });
        }
        case 'SESSION_ID':
        case 'BOT_ID':
        case 'CLIENT_ID_OR_NAME':
        case 'CALL_TASK_ID':
        case 'TEXT_CAMPAIGN_ID':
        case 'MESSAGE_TYPE': {
            const label = (options === null || options === void 0 ? void 0 : options.length) > 0
                ? `${t('Analytics:FiltersPanel:Selected')} ${options.length}`
                : t('Analytics:FiltersPanel:Empty');
            return _jsx(StylingLabel, { label: label });
        }
        case 'SESSION_RESULT':
        case 'TEXT_CAMPAIGN_RESULT': {
            const label = !(options === null || options === void 0 ? void 0 : options.length)
                ? t('Analytics:FiltersPanel:NotSelected')
                : (_d = (_c = comparisonOptions === null || comparisonOptions === void 0 ? void 0 : comparisonOptions.find(x => x.type === comparisonType)) === null || _c === void 0 ? void 0 : _c.label) === null || _d === void 0 ? void 0 : _d.toLowerCase();
            const value = hiddenOptionsArguments(comparisonType) && (options === null || options === void 0 ? void 0 : options.length);
            return _jsx(StylingLabel, { label: label, value: value });
        }
        case 'CHANNEL_TYPE': {
            const normalizeChannelLabel = (channelLabel) => {
                return channelLabel === 'incoming_webim2'
                    ? 'webim'
                    : channelLabel === 'chatwidget'
                        ? 'chat_widget'
                        : channelLabel;
            };
            const value = options === null || options === void 0 ? void 0 : options.map((channelLabel) => t(`ChannelType ${normalizeChannelLabel(channelLabel).toUpperCase()}`)).join(', ');
            return _jsx(StylingLabel, { label: !value && t('Analytics:FiltersPanel:NotSelected'), value: value });
        }
        default: {
            const label = (options === null || options === void 0 ? void 0 : options.length) > 0
                ? `${t('Analytics:FiltersPanel:Selected')} ${options.length}`
                : t('Analytics:FiltersPanel:Empty');
            return _jsx(StylingLabel, { label: label });
        }
    }
};
export default CheckboxLabel;
