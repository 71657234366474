import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { usePrevious } from '../utils';
import Select from '../Select';
import './filterModal.scss';
export const FilterModal = ({ id, label = 'Добавить', placeholder = 'Название фильтра', type = 'new', maxHeight = 300, children, onRemove, onSelect = () => { }, onOpen, onClose, forceShowModal, options = [], required, stayClosed, resizeObserver, }) => {
    const [showModal, setShowModal] = useState(forceShowModal || false);
    const [colorEnter, setColorEnter] = useState(false);
    const modalRef = useRef(null);
    const filterModalContainer = useRef(null);
    const inputRef = useRef(null);
    useEffect(() => {
        if (!required && type === 'fulfilled' && !stayClosed) {
            setShowModal(true);
        }
    }, [type]);
    useEffect(() => {
        if (modalRef.current && resizeObserver) {
            resizeObserver.observe(modalRef.current);
            return () => {
                resizeObserver.unobserve(modalRef.current);
            };
        }
    }, [modalRef.current, resizeObserver]);
    useEffect(() => {
        if (forceShowModal === undefined)
            return;
        showModal !== forceShowModal && setShowModal(forceShowModal);
    }, [forceShowModal]);
    const prevShowModalOpenValue = usePrevious(showModal);
    const checkCursor = useCallback((event) => {
        if (filterModalContainer.current && !filterModalContainer.current.contains(event.target))
            setShowModal(false);
    }, [showModal]);
    const checkKey = (event) => {
        if (event.key === 'Escape')
            setShowModal(false);
    };
    const checkArrowKey = (event) => {
        if (event.key === 'ArrowUp' || (event.key === 'ArrowDown' && !colorEnter))
            setColorEnter(true);
    };
    useEffect(() => {
        if (showModal) {
            document.addEventListener('click', checkCursor, { capture: true });
            document.addEventListener('keydown', checkKey);
            if (inputRef.current) {
                inputRef.current.focus();
            }
            if (onOpen)
                onOpen();
        }
        else {
            document.removeEventListener('click', checkCursor);
            document.removeEventListener('keydown', checkKey);
            if (onClose && prevShowModalOpenValue)
                onClose();
            setColorEnter(false);
        }
        return () => document.removeEventListener('click', checkCursor);
    }, [showModal, prevShowModalOpenValue]);
    const removeFilterParam = useCallback(event => {
        event.stopPropagation();
        if (onRemove && (id || label))
            onRemove(id || label);
    }, [id, label]);
    const handleChoose = (value) => {
        if (value) {
            onSelect(value.value || value.label);
            setShowModal(false);
        }
    };
    return (_jsxs("div", { className: `FilterModal ${type === 'new' && showModal && 'select'}`, ref: filterModalContainer, children: [type === 'new' && !showModal && (_jsx(Button, { color: 'secondary', outline: true, onClick: () => setShowModal(true), size: 'sm', "data-test-id": 'JustUI.FilterModal.AddFilterButton', children: _jsx(Icon, { name: 'faPlus', size: 'sm', color: 'secondary' }) })), type === 'fulfilled' && (_jsxs("div", { className: `fulfilled-pseudobutton ${showModal ? 'showModal' : ''}`, onClick: () => setShowModal(true), "data-test-id": `JustUI.FilterModal.ShowFiltersList${id ? '.' + id : ''}`, children: [_jsx("div", { className: 'separator', children: label }), !required && (_jsx("div", { className: 'remove', onClick: removeFilterParam, "data-test-id": 'JustUI.FilterModal.RemoveFilter', children: _jsx(Icon, { className: 'iconRight btn-icon btn-color', name: 'faTimes', size: 'md', color: 'primary' }) }))] })), showModal && type !== 'fulfilled' && (_jsxs(_Fragment, { children: [_jsx(Select, { isSearchable: true, size: 'sm', options: options, onChange: handleChoose, className: 'nameSearchInput', placeholder: placeholder, autoFocus: true, onKeyDown: checkArrowKey, defaultMenuIsOpen: true, "data-test-id": 'JustUI.FilterModal.SearchFilter' }), _jsx("span", { className: colorEnter ? 'colorEnter' : '', children: "Enter" })] })), _jsx("div", { className: 'popper-wrapper', style: { maxHeight: maxHeight, display: showModal && type === 'fulfilled' ? 'block' : 'none' }, ref: modalRef, children: children })] }));
};
