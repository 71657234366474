import { jsx as _jsx } from "react/jsx-runtime";
import { StylingLabel } from './Label';
const MultipleFilterLabel = ({ value: { argumentsFilter, filter } }) => {
    const { subFiltersValues } = argumentsFilter;
    const { subFilters } = (filter === null || filter === void 0 ? void 0 : filter.settings) || {};
    const labelsArr = [];
    subFilters === null || subFilters === void 0 ? void 0 : subFilters.forEach(subFilter => {
        var _a, _b, _c, _d;
        const valueOfSubFilter = subFiltersValues === null || subFiltersValues === void 0 ? void 0 : subFiltersValues.find(subFilterValue => subFilterValue.key === subFilter.key);
        const labelPart = (_d = (_c = (_b = (_a = subFilter.settings) === null || _a === void 0 ? void 0 : _a.staticOptions) === null || _b === void 0 ? void 0 : _b.find(x => (valueOfSubFilter ? x.value === valueOfSubFilter.option : x.isSelected))) === null || _c === void 0 ? void 0 : _c.label) === null || _d === void 0 ? void 0 : _d.toLowerCase();
        if (labelPart) {
            labelsArr.push(labelPart);
        }
    });
    return _jsx(StylingLabel, { label: labelsArr.join(' | ') });
};
export default MultipleFilterLabel;
