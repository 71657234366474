import { useMemo } from 'react';
import { Table } from 'reactstrap';
import { AccountEventDto } from '@just-ai/api/dist/generated/Audit';

import { t } from 'localization';

import Pagination from 'components/Pagination';
import { PaginationInfo } from 'components/Pagination/hook';

import { UserLogsTableRow } from '../UserLogsTableRow';

import styles from './styles.module.scss';

type UserLogsTableProps = {
  logs: AccountEventDto[];
  paginationInfo: PaginationInfo;
  changePage: (pageNum: number) => void;
  changePageSize: (pageSize: number) => void;
};

export const UserLogsTable = ({ logs, paginationInfo, changePage, changePageSize }: UserLogsTableProps) => {
  const tokenColumnHide = useMemo(() => logs.every(log => !log.tokenId), [logs]);
  const targetUserColumnHide = useMemo(() => logs.every(log => !log.targetUser?.login), [logs]);
  const projectShortNameColumnHide = useMemo(() => logs.every(log => !log.projectShortName), [logs]);

  const cols = useMemo(
    () => [
      { code: 'Action' },
      { code: 'CreatedAt' },
      { code: 'User' },
      { code: 'TokenId', hide: tokenColumnHide },
      { code: 'TargetUser', hide: targetUserColumnHide },
      { code: 'Project', hide: projectShortNameColumnHide },
      { code: 'Comment', wrap: true },
      { code: 'DeviceAndIP' },
      { code: 'Result' },
      { code: 'Impersonator' },
    ],
    [tokenColumnHide, targetUserColumnHide, projectShortNameColumnHide]
  );

  return (
    <>
      <Table data-test-id='usersPage.userListTable' className={styles.UserLogsTable}>
        <thead>
          <tr>
            {cols.map(col => !col.hide && <th key={`userLogItem_${col.code}`}>{t(`UserLog:Table:${col.code}`)}</th>)}
            <th></th>
          </tr>
        </thead>
        <tbody className={styles.Users__list}>
          {logs.map(record => (
            <UserLogsTableRow
              key={`userLogItem_${record.id}`}
              log={record}
              tokenHide={tokenColumnHide}
              targetUserHide={targetUserColumnHide}
              projectHide={projectShortNameColumnHide}
            />
          ))}
        </tbody>
      </Table>
      <div className={styles.UserLogsTable__paginationContainer}>
        <Pagination
          page={paginationInfo.pageNum}
          size={paginationInfo.pageSize}
          totalCount={paginationInfo.totalCount}
          changePage={changePage}
          changePageSize={changePageSize}
        />
      </div>
    </>
  );
};
