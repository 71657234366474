import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLabelsContext } from '../../../../Labels/context/LabelsContext';
import CheckboxLabel from './CheckboxLabel';
import RadioLabel from './RadioLabel';
import NumericRangeLabel from './NumericRangeLabel';
import TextLabel from './TextLabel';
import DateRangeLabel from './DateRangeLabel';
import CompositeLabel from './CompositeLabel';
import TreeLabel from './TreeLabel';
import CustomFields from './CustomFields';
import { FilterType } from '../../../api/client';
import { CheckboxComparisonType } from '../../../api/client';
import MultipleFilterLabel from './MultipleFilterLabel';
const { NOTIMPORTANT, EXISTS, NOTEXISTS } = CheckboxComparisonType;
const { DATETIMERANGE, RADIO, NUMERICRANGE, CHECKBOX, COMPOSITEFILTER, TEXT, TREE, CUSTOMFIELDSINRESPONSE, } = FilterType;
export const StylingLabel = ({ label, value }) => {
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: 'filter-label', children: label }), " ", value] }));
};
const Label = ({ filter, optionsArguments, filtersFetcher, t, locale }) => {
    var _a;
    const { sessionLabels } = useLabelsContext();
    const argumentsFilter = ((_a = optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters) === null || _a === void 0 ? void 0 : _a.find(x => x.key === (filter === null || filter === void 0 ? void 0 : filter.key))) || {};
    const hiddenOptionsArguments = (comparisonType) => {
        return ![NOTIMPORTANT, EXISTS, NOTEXISTS].includes(comparisonType);
    };
    const getLabelWithParams = () => {
        switch (filter === null || filter === void 0 ? void 0 : filter.type) {
            case DATETIMERANGE:
                return _jsx(DateRangeLabel, { value: { argumentsFilter, t, locale } });
            case RADIO:
                return _jsx(RadioLabel, { value: { argumentsFilter, filter } });
            case NUMERICRANGE:
                return _jsx(NumericRangeLabel, { value: { argumentsFilter, t } });
            case CHECKBOX:
                return _jsx(CheckboxLabel, { value: { argumentsFilter, filter, sessionLabels, hiddenOptionsArguments, t } });
            case COMPOSITEFILTER:
                return _jsx(CompositeLabel, { value: { argumentsFilter, t } });
            case TEXT:
                return _jsx(TextLabel, { value: { argumentsFilter, filter, hiddenOptionsArguments } });
            case TREE:
                return _jsx(TreeLabel, { value: { filters: optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters, argumentsFilter, filter, filtersFetcher, t } });
            case CUSTOMFIELDSINRESPONSE:
                return _jsx(CustomFields, { value: { argumentsFilter, t } });
            case 'MULTIPLE_FILTER':
                return _jsx(MultipleFilterLabel, { value: { argumentsFilter, filter } });
            default:
                return;
        }
    };
    return (_jsxs("small", { children: [filter === null || filter === void 0 ? void 0 : filter.label, ": ", getLabelWithParams()] }));
};
export default Label;
