import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import aimychat from 'images/aimychat.svg';
import tovieAgent from 'images/tovie/t-agent.svg';
import tovieAgentText from 'images/tovie/t-agent-text.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/AcStyleComponent'));

export default class AimychatTheme extends BaseTheme {
  backgroundSvgColor: string = isEuroInstance() ? '#32AD4E' : '#08A5A799';

  renderTitle() {
    return (
      <div className='app-title'>
        {isEuroInstance() ? <img src={tovieAgentText} alt='description-logo' /> : <h1>{this.getProductName()}</h1>}
        {!isEuroInstance() && (
          <h2>
            Part of Just AI <br />
            Conversational Cloud
          </h2>
        )}
      </div>
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta() {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = isEuroInstance() ? tovieAgent : '/c/aimychat.svg';
    description.content = '';
  }

  renderLogo() {
    return (
      <div className='app-logo'>
        <img src={isEuroInstance() ? tovieAgent : aimychat} alt='logo' />
      </div>
    );
  }
}
