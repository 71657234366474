import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import aimyvoice from 'images/aimyvoice.svg';
import tovieVoice from 'images/tovie/t-voice.svg';
import tovieVoiceText from 'images/tovie/t-voice-text.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/AvStyleComponent'));

export default class AimyvoiceTheme extends BaseTheme {
  backgroundSvgColor: string = '#FF9257';

  renderTitle() {
    return (
      <div className='app-title'>
        {isEuroInstance() ? <img src={tovieVoiceText} alt='description-logo' /> : <h1>{this.getProductName()}</h1>}
        {!isEuroInstance() && (
          <h2>
            Part of Just AI <br />
            Conversational Cloud
          </h2>
        )}
      </div>
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = isEuroInstance() ? '/c/tovie/t-voice.svg' : '/c/aimyvoice.svg';
    description.content = this.getProductName();
  }

  renderLogo() {
    return (
      <div className='app-logo'>
        <img src={isEuroInstance() ? tovieVoice : aimyvoice} alt='logo' />
      </div>
    );
  }
}
