import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { FiltersPanel, FilterType, Locale } from '@just-ai/analytic-front';
import { AuditFilter, ProductType } from '@just-ai/api/dist/generated/Audit';
import AuditService from '@just-ai/api/dist/services/AuditService';
import { Button, Spinner } from '@just-ai/just-ui';

import localization, { t } from 'localization';
import { getUserLanguage } from 'pipes/functions';
import { appStores } from 'store';

import { NoAccounts } from 'components/NoAccounts';
import { usePagination } from 'components/Pagination/hook';
import useSearchParamsState from 'helpers/useSearchParamsState';

import { userLogsLocalization } from './localization/userLogs.loc';
import { default as UserLogFiltersFetchInterface } from './UserLogsFilterInterface';
import { UserLogsTable } from './UserLogsTable';

import styles from './styles.module.scss';

export const userParamsForRequestLSKey = 'filters';

localization.addTranslations(userLogsLocalization);

const auditService = new AuditService();
const UserLogs = () => {
  const { logs, isLogsLoading, getLogs } = appStores.Logs(state => ({
    logs: state.logs,
    isLogsLoading: state.isLogsLoading,
    getLogs: state.getLogs,
  }));
  const pageParams = useParams<{ accountId: string }>();
  const lang = getUserLanguage();
  const [areFiltersReady, setAreFiltersReady] = useState(false);

  const { paginationInfo, changePageSize, changePage } = usePagination(logs.paging);
  const [filterData, setFilterData] = useSearchParamsState<AuditFilter[]>(userParamsForRequestLSKey, []);

  const fetchInterface = useMemo(() => {
    if (!pageParams.accountId || !lang || !filterData) return;
    const products = new URLSearchParams(window.location.search).getAll('product');
    return new UserLogFiltersFetchInterface(
      auditService,
      parseInt(pageParams.accountId),
      lang,
      products as ProductType[]
    );
  }, [pageParams.accountId, lang, filterData]);

  useEffect(() => {
    if (!pageParams.accountId || !areFiltersReady || !filterData) return;
    getLogs({
      accountId: parseInt(pageParams.accountId),
      page: paginationInfo.pageNum,
      size: paginationInfo.pageSize,
      language: lang,
      filtersDto: {
        filters: filterData,
      },
    });
  }, [
    pageParams.accountId,
    filterData,
    getLogs,
    lang,
    paginationInfo.pageNum,
    paginationInfo.pageSize,
    areFiltersReady,
  ]);

  const onFilterChange = useCallback(
    (filtersOnChange: AuditFilter[]) => {
      changePage(0);
      setFilterData(filtersOnChange);
      !areFiltersReady && setAreFiltersReady(true);
    },
    [changePage, setFilterData, areFiltersReady]
  );

  const downloadReport = useCallback(async () => {
    if (!pageParams.accountId || !filterData) return;
    const { data, headers } = await auditService.getAccountEventsByFiltersFileType(
      parseInt(pageParams.accountId),
      lang,
      {
        filters: filterData,
      }
    );
    const fileName = headers['content-disposition']?.split('filename=');
    if (fileName) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName[1].replaceAll('"', ''));

      document.body.appendChild(link);
      link.click();
    }
  }, [pageParams.accountId, filterData, lang]);

  return (
    <div className={styles.UserLogs}>
      {isLogsLoading && <Spinner size='4x' />}
      <div className={styles.UserLogs__header}>
        {fetchInterface && (
          <FiltersPanel
            filtersFetchInterface={fetchInterface}
            accountId={0}
            locale={lang as Locale}
            onChange={onFilterChange}
            resource='accounts'
            t={t}
            rootClassName={styles.UserLogs__filters}
            cachedFilters={filterData as unknown as FilterType[]}
          />
        )}
        {paginationInfo.totalCount > 0 && (
          <Button
            iconLeft='farArrowToBottom'
            onClick={downloadReport}
            data-test-id='Userlogs.download.button'
            color='secondary'
            outline
            className={styles.UserLogs__downloadButton}
          >
            {t('Download')}
          </Button>
        )}
      </div>
      {logs.events.length > 0 ? (
        <UserLogsTable
          logs={logs.events}
          paginationInfo={paginationInfo}
          changePage={changePage}
          changePageSize={changePageSize}
        />
      ) : (
        <NoAccounts />
      )}
    </div>
  );
};

export default UserLogs;
