import { create } from 'zustand';
import { AccountEventsDto, FiltersDto } from '@just-ai/api/dist/generated/Audit';
import AuditService from '@just-ai/api/dist/services/AuditService';
import { AppLogger } from '@just-ai/logger';

export const DEFAULT_SIZE = 10;
export const DEFAULT_PAGE = 0;

type LogsState = {
  logs: AccountEventsDto;
  isLogsLoading: boolean;
  getLogs: ({
    accountId,
    page,
    size,
    language,
    filtersDto,
  }: {
    accountId: number;
    page?: number;
    size?: number;
    language?: string;
    filtersDto?: FiltersDto;
  }) => Promise<void>;
};

const auditService = new AuditService();

export const useLogsStore = create<LogsState>(set => ({
  logs: {
    events: [],
    paging: {
      pageNum: DEFAULT_PAGE,
      pageSize: DEFAULT_SIZE,
      totalCount: 0,
    },
  },
  isLogsLoading: false,
  getLogs: async ({ accountId, page, size, language, filtersDto }) => {
    set({ isLogsLoading: true });
    await auditService
      .getAccountEventsByFilters(accountId, page, size, language, filtersDto)
      .then(response => {
        set({ logs: response });
      })
      .catch(AppLogger.createNetworkHandler('getUserLogs'))
      .finally(() => {
        set({ isLogsLoading: false });
      });
  },
}));
