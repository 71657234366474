import React from 'react';

import BaseTheme from './BaseTheme';

import './styles.scss';

const StyleComponent = React.lazy(() => import('./styleComponents/WhitelabelStyleComponent'));

export default class WhiteLabelTheme extends BaseTheme {
  backgroundSvgColor: string = '#BA75E099';

  renderTitle(): JSX.Element | null {
    return (
      <div className='app-title general-logo'>
        <h1>{this.getProductName()}</h1>
      </div>
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = String(this.currentDomainData?.faviconSrc);
    description.content = '';
  }

  renderLogo() {
    return (
      <div className='app-logo whitelabel-logo'>
        <img src={this.currentDomainData?.logoSrc} alt='logo' />
      </div>
    );
  }
}
