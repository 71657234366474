var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { CompositeFilters } from './CompositeFilters';
import './CompositeFilter.scss';
import { CompositeFilterProviderProps } from './types';
import { Top10 } from './Top10';
import { useFilterBase, useSuggestions } from './hooks';
const CompositeFilterContext = createContext(new CompositeFilterProviderProps());
export const CompositeFilter = (_a) => {
    var { filterKey, style, keyCode = 'CompositeFilter', filters, top10, onChange, onInputChange, top10Label, top10LabelComparisonType, comparisonOptions = [], t = () => '', isUseSuggestions } = _a, restProps = __rest(_a, ["filterKey", "style", "keyCode", "filters", "top10", "onChange", "onInputChange", "top10Label", "top10LabelComparisonType", "comparisonOptions", "t", "isUseSuggestions"]);
    const { stateFilters, onChangeFilterHandler, selectTop, deleteFilter, addNewFilter, comparisonOptionTypeToValue, } = useFilterBase(filters || [], comparisonOptions, top10LabelComparisonType, onChange);
    const { filterSuggestions, onInputChangeHandler, isLoading } = useSuggestions(onInputChange);
    return (_jsx(CompositeFilterContext.Provider, { value: {
            onChange: onChangeFilterHandler,
            onInputChange: onInputChangeHandler,
            filters: stateFilters || [],
            filterSuggestions: filterSuggestions,
            top10: top10 || [],
            top10Label: top10Label || '',
            selectTop: selectTop,
            comparisonOptions: comparisonOptions,
            deleteFilter: deleteFilter,
            addNewFilter: addNewFilter,
            comparisonOptionTypeToValue: comparisonOptionTypeToValue,
            filterKey,
            t,
            keyCode,
            isLoading,
        }, children: _jsxs("div", { style: style, className: 'CompositeFilter', "data-test-id": restProps['data-test-id'], children: [_jsx(CompositeFilters, {}), _jsx(Top10, {})] }) }));
};
export const useCompositeFiltersContext = () => useContext(CompositeFilterContext);
export * from './types';
