import React, { Fragment, useMemo } from 'react';
import cn from 'classnames';
import { RoleGroupColor, RoleGroupDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { Badge, Icon, Tooltip } from '@just-ai/just-ui';

import { t } from 'localization';

import styles from './styles.module.scss';

type OptionsInnerGroup = RoleGroupDto & {
  options: GroupItem[];
};

function transformToGroupOptions(groups: RoleGroupDto[], selectedOptions: GroupItem[]): OptionsInnerGroup[] {
  const selectedOptionsSet = new Set(selectedOptions.map(el => el.name));
  const result = groups.map(group => {
    let options: GroupItem[] = [];
    selectedOptions.forEach(option => {
      const role = group.roles.find(role => role.name === option.name);
      if (role) {
        options.push(option);
        selectedOptionsSet.delete(option.name);
      }
    });
    return { ...group, options };
  });
  return result;
}

type GroupItem = { name: string; fitsWorkplacesLimits?: boolean };

type BadgeGroupsType = {
  groups: RoleGroupDto[];
  selected: GroupItem[];
  id?: number;
};
const BadgeGroups = ({ groups, selected, id }: BadgeGroupsType) => {
  const groupsWithRoles = useMemo(
    () => transformToGroupOptions(groups, selected).filter(group => group.options?.length),
    [groups, selected]
  );

  return (
    <div className={styles.BadgeGroups}>
      {groupsWithRoles.map(group => {
        const isFullyDisabled = group.options?.every(role => !role.fitsWorkplacesLimits);
        const someDisabled = group.options?.some(role => !role.fitsWorkplacesLimits);
        const tooltipId = `${group.name.split(' ').join('_')}_${id}`;
        return (
          <Fragment key={group.name}>
            {group.name ? (
              <Badge
                pastel
                color={group.color}
                text={group.name}
                className={cn({ [styles.disabled]: isFullyDisabled })}
              />
            ) : (
              <div></div>
            )}
            <div className='flex-row gap-1x flex-wrap'>
              {group.options.map(role => {
                return (
                  <Badge
                    pastel
                    key={role.name}
                    color={group.color}
                    text={role.name}
                    className={cn({ [styles.disabled]: !role?.fitsWorkplacesLimits })}
                  />
                );
              })}
              {someDisabled && (
                <>
                  <div id={tooltipId}>
                    <Icon name='farQuestionCircle' color='danger' />
                  </div>
                  <Tooltip target={tooltipId} placement='top' textAlign='left' delay={{ show: 400, hide: 200 }}>
                    {t('AccountDetail:BadgeGroups:Error:LimitTooltip')}
                  </Tooltip>
                </>
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
export default React.memo(BadgeGroups);
