import { useCallback, useEffect, useRef, useState } from 'react';
import { FilterSuggestions } from './types';
import { useCompositeFiltersContext } from './index';
import { cancelDefaults, usePrevious } from '@just-ai/just-ui';
export const useFilterBase = (filters, comparisonOptions, top10LabelComparisonType, onChange) => {
    const [stateFilters, setFilters] = useState(() => filters);
    const filtersRef = useRef(filters);
    const fastComparisonOptions = useRef({});
    const defaultComparisonType = useRef(top10LabelComparisonType);
    useEffect(() => {
        let fastComparisonOptionsValue = {};
        for (let i = 0; i < comparisonOptions.length; i += 1) {
            fastComparisonOptionsValue[comparisonOptions[i].value] = comparisonOptions[i];
            if (comparisonOptions[i].isDefault && !top10LabelComparisonType) {
                defaultComparisonType.current = comparisonOptions[i].value;
            }
        }
        fastComparisonOptions.current = fastComparisonOptionsValue;
    }, [comparisonOptions, top10LabelComparisonType]);
    const onChangeFilterHandler = useCallback((filterIndex, filter) => {
        if (filterIndex !== undefined && filter)
            filtersRef.current[filterIndex] = filter;
        onChange && onChange(filtersRef.current);
    }, [onChange]);
    const selectTop = useCallback((value) => {
        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            newFilters.push({
                label: '',
                value: value,
                comparisonOption: defaultComparisonType.current
                    ? fastComparisonOptions.current[defaultComparisonType.current].value
                    : comparisonOptions[0].value,
                property: '',
            });
            return newFilters;
        });
    }, [comparisonOptions]);
    const deleteFilter = useCallback((filterIndex) => {
        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            newFilters.splice(filterIndex, 1);
            return newFilters;
        });
    }, []);
    const addNewFilter = useCallback(() => {
        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            newFilters.push({
                label: '',
                value: '',
                comparisonOption: defaultComparisonType.current
                    ? fastComparisonOptions.current[defaultComparisonType.current].value
                    : comparisonOptions[0].value,
                property: '',
            });
            return newFilters;
        });
    }, [comparisonOptions]);
    const prevFilters = usePrevious(filters);
    useEffect(() => {
        if (prevFilters && JSON.stringify(prevFilters) === JSON.stringify(filters))
            return;
        setFilters(() => filters);
    }, [prevFilters, filters, setFilters]);
    useEffect(() => {
        filtersRef.current = stateFilters;
        onChangeFilterHandler();
    }, [stateFilters, onChangeFilterHandler]);
    return {
        stateFilters,
        onChangeFilterHandler,
        selectTop,
        deleteFilter,
        addNewFilter,
        comparisonOptionTypeToValue: fastComparisonOptions.current,
    };
};
export const useSuggestions = (onInputChange) => {
    const timeOutRef = useRef();
    const [filterSuggestions, setSuggestions] = useState(new FilterSuggestions());
    const [isLoading, setIsLoading] = useState(false);
    const onInputChangeHandler = useCallback((value, filterIndex) => {
        timeOutRef.current && clearTimeout(timeOutRef.current);
        if (onInputChange && value.length >= 2) {
            timeOutRef.current = setTimeout(() => {
                if (!isLoading)
                    setIsLoading(true);
                setSuggestions(Object.assign(Object.assign({}, new FilterSuggestions()), { filterIndex: filterIndex }));
                onInputChange(value).then(payload => {
                    setSuggestions({ filterIndex: filterIndex, suggestions: payload.suggestions, searchString: value });
                    setIsLoading(false);
                });
            }, 300);
        }
        else {
            if (filterSuggestions.suggestions.length > 0) {
                setSuggestions(new FilterSuggestions());
            }
        }
    }, [filterSuggestions.suggestions.length, isLoading, onInputChange]);
    return {
        filterSuggestions,
        onInputChangeHandler,
        isLoading,
    };
};
export const useHandlers = (filterIndex, filter) => {
    const prevSavedValue = useRef(null);
    const { onChange, deleteFilter, onInputChange } = useCompositeFiltersContext();
    const [filterData, setFilterData] = useState(() => (Object.assign({}, filter)));
    const debouncedSave = useCallback(() => {
        prevSavedValue.current = filterData;
        onChange(filterIndex, filterData);
    }, [filterData, onChange, filterIndex]);
    const onChangeFilterValue = useCallback((value) => {
        setFilterData(prevFilterData => {
            const newFilterData = Object.assign({}, prevFilterData);
            newFilterData.value = value;
            return newFilterData;
        });
    }, [setFilterData]);
    const onChangeValueHandler = useCallback((value) => {
        if (onInputChange) {
            onInputChange(value, filterIndex);
        }
        onChangeFilterValue(value);
    }, [filterIndex, onChangeFilterValue, onInputChange]);
    const onChangePropertyHandler = useCallback((value) => {
        setFilterData(prevFilterData => {
            const newFilterData = Object.assign({}, prevFilterData);
            newFilterData.property = value;
            return newFilterData;
        });
    }, [setFilterData]);
    const onChangeComparisonOptionHandler = useCallback((value) => {
        setFilterData(prevFilterData => {
            const newFilterData = Object.assign({}, prevFilterData);
            if (value && value[0]) {
                newFilterData.comparisonOption = value[0];
            }
            else {
                newFilterData.comparisonOption = undefined;
            }
            return newFilterData;
        });
    }, [setFilterData]);
    const deleteFilterHandler = useCallback(() => {
        deleteFilter(filterIndex);
    }, [deleteFilter, filterIndex]);
    const toggleCaseSensitive = useCallback(() => {
        setFilterData(prevFilterData => {
            const newFilterData = Object.assign({}, prevFilterData);
            newFilterData.caseSensitive = !newFilterData.caseSensitive;
            return newFilterData;
        });
    }, []);
    useEffect(() => {
        if (JSON.stringify(prevSavedValue.current) === JSON.stringify(filterData))
            return;
        debouncedSave();
    }, [filterData, debouncedSave]);
    return {
        onChangeFilterValue,
        onChangeValueHandler,
        onChangePropertyHandler,
        onChangeComparisonOptionHandler,
        filterData,
        deleteFilterHandler,
        toggleCaseSensitive,
    };
};
export const showInputField = (fastComparisonOptions, comparisonOptions, filterComparisonOption) => {
    var _a, _b;
    return (((_a = fastComparisonOptions[filterComparisonOption]) === null || _a === void 0 ? void 0 : _a.withInputField) ||
        ((_b = comparisonOptions.find(comparisonOption => comparisonOption.value === filterComparisonOption)) === null || _b === void 0 ? void 0 : _b.withInputField));
};
export const escapeRegExp = (value) => {
    return value.replace(/[-\[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
export const useClickListener = (ref, allowToHide, deps) => {
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        setVisible(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    useEffect(() => {
        const clickListener = () => {
            setVisible(false);
        };
        document.addEventListener('click', clickListener);
        return () => {
            document.removeEventListener('click', clickListener);
        };
    }, [ref, allowToHide]);
    return {
        visible,
        setVisible,
    };
};
export function useKeyboardArrows(arrayOfElements, allowToNavigate, selectByEnter, closeCallback, ref) {
    const arrayOfElementsRef = useRef(arrayOfElements);
    const [focusElementIndex, setFocusElement] = useState(() => 0);
    useEffect(() => {
        if (allowToNavigate) {
            setFocusElement(0);
        }
    }, [setFocusElement, arrayOfElements, allowToNavigate]);
    const keyDownListener = useCallback((event) => {
        if (allowToNavigate) {
            switch (event.key) {
                case 'ArrowUp': {
                    cancelDefaults(event);
                    setFocusElement(focusElement => {
                        if (focusElement - 1 > -1) {
                            return focusElement - 1;
                        }
                        else {
                            return arrayOfElementsRef.current.length - 1;
                        }
                    });
                    break;
                }
                case 'ArrowDown': {
                    cancelDefaults(event);
                    setFocusElement(focusElement => {
                        if (focusElement + 1 <= arrayOfElementsRef.current.length - 1) {
                            return focusElement + 1;
                        }
                        else {
                            return 0;
                        }
                    });
                    break;
                }
                case 'Enter': {
                    cancelDefaults(event);
                    selectByEnter(arrayOfElements[focusElementIndex]);
                    closeCallback();
                    break;
                }
                case 'Escape': {
                    cancelDefaults(event);
                    closeCallback();
                    break;
                }
                default:
                    break;
            }
        }
    }, [allowToNavigate, arrayOfElements, closeCallback, focusElementIndex, selectByEnter]);
    useEffect(() => {
        ref === null || ref === void 0 ? void 0 : ref.addEventListener('keydown', keyDownListener);
        return () => {
            ref === null || ref === void 0 ? void 0 : ref.removeEventListener('keydown', keyDownListener);
        };
    }, [keyDownListener, ref]);
    return {
        focusElementIndex,
        setFocusElement,
    };
}
