export class Filter {
    constructor(comparisonOption) {
        this.label = '';
        this.property = '';
        this.value = '';
        this.frequency = 0;
        this.isSelected = false;
        this.caseSensitive = false;
        this.comparisonOption = comparisonOption;
    }
}
export class FilterSuggestions {
    constructor() {
        this.filterIndex = -1;
        this.suggestions = [];
        this.searchString = '';
    }
}
export class CompositeFilterProviderProps {
    constructor() {
        this.t = () => '';
        this.keyCode = 'CompositeFilter';
        this.filterKey = '';
        this.filters = [];
        this.filterSuggestions = new FilterSuggestions();
        this.top10 = [];
        this.top10Label = '';
        this.comparisonOptions = [];
        this.comparisonOptionTypeToValue = {};
        this.isLoading = false;
        this.onInputChange = () => { };
        this.onChange = () => { };
        this.selectTop = () => { };
        this.deleteFilter = () => { };
        this.addNewFilter = () => { };
    }
}
