import React, { Component, createContext, useContext } from 'react';
import TagManager from 'react-gtm-module';
import {
  DomainOptions,
  OptionsResponseDto,
  ToSAndPoSConfig,
  UserDataDto,
} from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Spinner } from '@just-ai/just-ui/dist';
import { AlertNotificationItemProps } from '@just-ai/just-ui/dist/AlertNotification/AlertNotificationItem';
import { AppLogger } from '@just-ai/logger';

import localize from 'localization';
import { getGoogleTag, getLangByCode, getUserLanguage, isDev } from 'pipes/functions';
import { appStores } from 'store';

import { getTheme } from 'components/Themes';
import BaseTheme from 'components/Themes/BaseTheme';
import { crispInitializer } from 'helpers/crispInitializer';
import ConfigService from 'service/ConfigService';
import { ssoService } from 'service/SsoService';
import UserService from 'service/UserService';

export class AppContextType {
  loading: boolean = true;
  language: string = getUserLanguage();
  domainOptions: DomainOptions | null = null;
  appConfig: OptionsResponseDto | null = null;
  currentUser: UserDataDto | null = null;
  alerts: AlertNotificationItemProps[] = [];
  tosAndPos: ToSAndPoSConfig | null = null;
  themeInstance: BaseTheme | null = null;
}

export class AppContextProviderType extends AppContextType {
  setLoading: () => void = () => {};
  setCurrentUser: (data: UserDataDto | null) => void = data => {};
  setLanguage: (language: string) => void = language => {};
  dismissAllAlerts: () => void = () => {};
  addAlert: (alert: AlertNotificationItemProps) => void = alert => {};
  getTosAndPos: (userId: number) => void = userId => {};
}

export const AppContext = createContext<AppContextProviderType>(new AppContextProviderType());

export class AppContextProvider extends Component<{}, AppContextType> {
  state = new AppContextType();

  ConfigService = new ConfigService();
  UserService = new UserService();
  accountSadminService = new AccountsadminService();

  componentDidMount() {
    this.getAppConfig().then(() => {
      let product = 'cc';
      let domainOptions: DomainOptions | null = null;
      const { appConfig, themeInstance } = this.state;
      if (!appConfig) return;
      const domains = appConfig?.['domains'];
      const languageAvailable = appConfig?.['language'];

      const tagManagerArgs = {
        gtmId: getGoogleTag(Boolean(appConfig?.euroInstance)),
      };
      TagManager.initialize(tagManagerArgs);

      if (appConfig && domains) {
        const host = isDev() ? process.env.REACT_APP_CLOUD_HOST : window.location.host;

        const domain = (Object.values(domains) as DomainOptions[]).find(domain => domain.domain === host);
        if (domain) {
          product = domain.product;
          domainOptions = domain;
        }
      }

      const crispToken = domains?.[product]?.crispToken;
      if (crispToken) crispInitializer(crispToken);

      themeInstance?.setAppMeta();
      const language = getUserLanguage(languageAvailable);
      localize.setLocale(language);
      localize.setConfigVariables({
        helpUrl: {
          ru: appConfig?.whitelabelOptions?.cloudDocumentationUrl,
          eng: appConfig?.whitelabelOptions?.cloudDocumentationUrl,
        },
        copilot: {
          productName: {
            ru: domains?.['copilot']?.appTitle,
            eng: domains?.['copilot']?.appTitle,
          },
        },
      });
      this.setState({
        language: language,
        domainOptions: domainOptions,
      });
    });
  }

  getAppConfig = async () => {
    this.setState({ loading: true });
    const data = await this.ConfigService.getOptions().catch(AppLogger.createNetworkHandler('getOptions'));
    if (data) {
      appStores.Config.getState().setOptions(data);
      ssoService.setOptions(data);
      const themeInstance = getTheme(data);
      this.setState({ appConfig: data, themeInstance });
    }
    this.setState({ loading: false });
  };

  setLoading = () => {
    this.setState({
      loading: this.state.loading,
    });
  };

  setCurrentUser = (data: UserDataDto | null) => {
    if (data?.userData.language) this.setLanguage(getLangByCode(data?.userData.language));
    appStores.CurrentUser.getState().setCurrentUser(data);
    this.setState({ currentUser: data });
  };

  setLanguage = (language: string) => {
    localStorage.setItem('USER_LANGUAGE', language);
    localize.setLocale(language);
    appStores.CurrentUser.getState().setLanguage(language);
    this.setState({
      language: language,
    });
  };

  dismissAllAlerts = () => {
    this.setState({ alerts: [] });
  };

  addAlert = (alert: AlertNotificationItemProps) => {
    this.setState({ alerts: [...this.state.alerts, alert] });
  };

  getTosAndPos = async (userId: number) => {
    try {
      const data = await this.accountSadminService.getTosAndPos(userId);
      if (!data) return;
      this.setState({ tosAndPos: data });
    } catch (error) {
      if (error instanceof Error) {
        AppLogger.error({
          message: 'Error while getting tos and pos',
          exception: error,
        });
      }
    }
  };

  render() {
    const { loading, language, domainOptions, appConfig, currentUser, tosAndPos, themeInstance } = this.state;
    const { children } = this.props;
    const ThemeProvider = themeInstance?.getStyleWrapper();
    return (
      <AppContext.Provider
        value={{
          loading: loading,
          setLoading: this.setLoading,
          language: language,
          domainOptions: domainOptions,
          appConfig: appConfig,
          currentUser: currentUser,
          setCurrentUser: this.setCurrentUser,
          setLanguage: this.setLanguage,
          alerts: this.state.alerts,
          dismissAllAlerts: this.dismissAllAlerts,
          addAlert: this.addAlert,
          getTosAndPos: this.getTosAndPos,
          tosAndPos: tosAndPos,
          themeInstance: themeInstance,
        }}
      >
        {loading || !ThemeProvider ? (
          <Spinner size='4x' />
        ) : (
          <React.Suspense fallback={<></>}>
            <ThemeProvider>{children}</ThemeProvider>
          </React.Suspense>
        )}
      </AppContext.Provider>
    );
  }
}

export const useAppContext = () => useContext(AppContext);
