var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BaseModalFilter from './BaseModalFilter';
import { CheckboxSelect, Select, InputText, DropdownMenu, DropdownItem, Dropdown, DropdownToggle, InputGroup, } from '@just-ai/just-ui';
import './DateRangeModalStatic.scss';
import './BaseModalFilter.scss';
class CheckboxState {
}
export default class CheckboxModal extends BaseModalFilter {
    constructor(props) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        super(props);
        this.filtersChanged = false;
        this.count = 0;
        this.fetchTimer = null;
        this.fetchAndSaveOptions = () => __awaiter(this, void 0, void 0, function* () {
            const { filters: rawFilters } = this.props.optionsArguments || {};
            const { options: oldOptions } = this.state;
            const { key, settings } = this.props.filter;
            if (!this.props.optionsArguments || settings.withoutOptions)
                return;
            const filters = !(settings === null || settings === void 0 ? void 0 : settings.filterDependent) ? [] : this.cleareFiltersFromCurrent(rawFilters, key) || [];
            let newOptions = yield this.fetchOptions(this.cleareFiltersFromCurrent(filters, key) || [], false);
            let oldChoosedOptions = this.optionsValues.map(value => oldOptions.find(x => x.value === value));
            newOptions = newOptions.filter(x => {
                if (this.optionsValues.includes(x.value)) {
                    const index = oldChoosedOptions.findIndex(k => k.value === x.value);
                    oldChoosedOptions.splice(index, 1, x);
                    return false;
                }
                else {
                    return true;
                }
            });
            this.setState({
                options: [...oldChoosedOptions, ...newOptions].map((x) => (Object.assign(Object.assign({}, x), { id: `${this.props.filter.key}_${x.value}` }))),
            });
        });
        this.fetchOptions = (filters, useAllFilters) => __awaiter(this, void 0, void 0, function* () {
            const { key, settings, type } = this.props.filter;
            const { filtersFetcher } = this.props;
            return yield filtersFetcher.getFiltersOptions(key, type, {
                size: (settings === null || settings === void 0 ? void 0 : settings.defaultPageSizeForOptions) || 5,
                filters,
                useAllFilters,
            });
        });
        this.preventDefault = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        this.onChangeComparison = (value) => {
            var _a;
            if (((_a = this.state.comparisonType) === null || _a === void 0 ? void 0 : _a.value) === value.value)
                return;
            this.setState({ comparisonType: value });
        };
        this.onConfirm = () => {
            const { comparisonType } = this.state;
            const result = { options: [...this.optionsValues] };
            if (comparisonType === null || comparisonType === void 0 ? void 0 : comparisonType.value) {
                result.comparisonType = comparisonType.value;
            }
            this.props.onConfirm(result, false);
        };
        this.onChange = (checked, key) => {
            if (checked) {
                this.optionsValues.push(key);
                if (this.optionsValues.length === 1)
                    this.forceUpdate();
            }
            else {
                this.optionsValues = this.optionsValues.filter(x => x !== key);
            }
        };
        this.onChangeInputFilter = (rawValue, name, e) => __awaiter(this, void 0, void 0, function* () {
            e === null || e === void 0 ? void 0 : e.preventDefault();
            e === null || e === void 0 ? void 0 : e.stopPropagation();
            if (this.fetchTimer) {
                clearTimeout(this.fetchTimer);
            }
            const value = rawValue.trim();
            if (value.length === 0) {
                return this.setState({ dropdownOptions: undefined });
            }
            const fetchTimer = window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                this.fetchTimer = fetchTimer;
                const { key, type } = this.props.filter;
                const { filters } = this.props.optionsArguments || {};
                const filterForFetch = (filters === null || filters === void 0 ? void 0 : filters.filter(x => x.key !== key)) || [];
                filterForFetch.push({ key, type, options: [value] });
                const dropdownOptions = yield this.fetchOptions(filterForFetch, true);
                this.setState({ dropdownOptions });
            }), 500);
        });
        this.onChooseInDropdown = (option) => {
            const oldOptions = this.state.options.filter(x => x.value !== option.value);
            if (!this.optionsValues.includes(option.value))
                this.optionsValues.push(option.value);
            this.setState({
                options: [option, ...oldOptions].map((x) => (Object.assign(Object.assign({}, x), { id: `${this.props.filter.key}_${x.value}` }))),
                dropdownOptions: [],
            });
            this.count += 1;
        };
        this.showClearButton = () => {
            return this.optionsValues.length > 0;
        };
        this.onCancel = () => {
            var _a;
            const oldValue = this.getOldValue();
            if (!oldValue && !this.props.filter.isRequired)
                return this.props.onDelete();
            const options = (oldValue === null || oldValue === void 0 ? void 0 : oldValue.options) || [];
            const comparisonType = (oldValue === null || oldValue === void 0 ? void 0 : oldValue.comparisonType)
                ? ((_a = this.comparizonOptions) === null || _a === void 0 ? void 0 : _a.find(x => x.value === (oldValue === null || oldValue === void 0 ? void 0 : oldValue.comparisonType))) || null
                : null;
            this.optionsValues = options;
            this.count += 1;
            this.setState({ dropdownOptions: [], comparisonType }, () => this.props.onCancel());
        };
        this.onClear = () => {
            this.optionsValues = [];
            this.count += 1;
            this.setState({ dropdownOptions: [] });
        };
        this.addValueAsOption = (e) => {
            var _a;
            if (e.key !== 'Enter' || !this.state.dropdownOptions) {
                return;
            }
            else {
                e.preventDefault();
                e.stopPropagation();
            }
            this.fetchTimer = null;
            // @ts-ignore
            const value = (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.trim().toLowerCase();
            const { options = [], dropdownOptions = [] } = this.state;
            const checkChannelNameOrValue = (op) => { var _a, _b, _c, _d; return ((_b = (_a = op.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes(value)) || ((_d = (_c = op.channelName) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === null || _d === void 0 ? void 0 : _d.includes(value)) || op.channelType; };
            const hasDropdownOptions = dropdownOptions.some(checkChannelNameOrValue);
            if (!hasDropdownOptions) {
                return;
            }
            if (!this.optionsValues.includes(value)) {
                if (hasDropdownOptions) {
                    dropdownOptions.forEach(option => this.optionsValues.push(option.value));
                }
                else {
                    this.optionsValues.push(value);
                }
            }
            const hasOptions = options.some(checkChannelNameOrValue);
            this.count += 1;
            this.setState({
                dropdownOptions: [],
                options: hasOptions
                    ? options
                    : [{ value, label: value, count: 0 }, ...options].map(x => (Object.assign(Object.assign({}, x), { id: `${this.props.filter.key}_${x.value}` }))),
            });
        };
        this.searchOptions = (value) => this.setState({ searchValue: value });
        this.renderInputs = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            const { comparisonType, options, dropdownOptions, searchValue } = this.state;
            const { filter, showModal } = this.props;
            const filteredOptions = options === null || options === void 0 ? void 0 : options.filter(option => option.label.toLowerCase().includes(searchValue === null || searchValue === void 0 ? void 0 : searchValue.toLowerCase()) || !searchValue);
            return (_jsxs("div", { className: 'BaseModalFilter_container_inputsContainer', children: [this.comparizonOptions.length > 0 && (_jsx(Select, { value: comparisonType, options: this.comparizonOptions, className: 'BaseModalFilter_container_comparisonContainer', onChange: this.onChangeComparison, "data-test-id": 'FiltersPanel.CheckboxModal.ComparisonDropdown' })), ((_a = filter.settings) === null || _a === void 0 ? void 0 : _a.withSearchInput) && (_jsx(InputGroup, { PrependInner: [
                            {
                                color: 'secondary',
                                name: 'faSearch',
                            },
                        ], className: 'searchPanel-input', children: _jsx(InputText, { onChange: this.searchOptions, placeholder: (_b = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _b === void 0 ? void 0 : _b.placeholder }) })), ((_c = filter.settings) === null || _c === void 0 ? void 0 : _c.withFilterInput) && (_jsxs(Dropdown, { isOpen: !!(dropdownOptions === null || dropdownOptions === void 0 ? void 0 : dropdownOptions.length), toggle: () => { }, className: 'BaseModalFilter_container_inputsContainer ', "data-test-id": 'FiltersPanel.CheckboxModal.OptionInput', children: [_jsx(DropdownToggle, { tag: 'span', children: _jsx(InputText, { placeholder: (_d = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _d === void 0 ? void 0 : _d.placeholder, className: 'BaseModalFilter_container_inputWithEnterPadding', onChange: this.onChangeInputFilter, onKeyDown: this.addValueAsOption }, this.count) }), showModal && (_jsx(DropdownMenu, { style: { width: '100%', marginTop: '1.5rem' }, children: dropdownOptions === null || dropdownOptions === void 0 ? void 0 : dropdownOptions.map(option => (_jsxs(DropdownItem, { className: 'BaseModalFilter_container_dropdownOption', style: {}, onClick: () => this.onChooseInDropdown(option), children: [option.label, Boolean(option.count) || option.count === 0 ? ` (${option.count})` : ''] }, `option_${option.value}`))) }))] })), _jsx("div", { className: 'BaseModalFilter_container_fieldOptionsContainer', style: {
                            transform: 'none',
                            paddingLeft: ((_f = (_e = filter.settings) === null || _e === void 0 ? void 0 : _e.checkboxVirtualListOptions) === null || _f === void 0 ? void 0 : _f.withVirtualList) ? 0 : 5,
                        }, children: _jsx(CheckboxSelect, { onChange: this.onChange, selected: this.optionsValues, values: filteredOptions, "data-test-id": 'FiltersPanel.CheckboxModal.CheckboxSelect', withVirtualList: (_h = (_g = filter.settings) === null || _g === void 0 ? void 0 : _g.checkboxVirtualListOptions) === null || _h === void 0 ? void 0 : _h.withVirtualList, virtualListContainerHeight: (_k = (_j = filter.settings) === null || _j === void 0 ? void 0 : _j.checkboxVirtualListOptions) === null || _k === void 0 ? void 0 : _k.virtualListContainerHeight, virtualListItemHeight: (_m = (_l = filter.settings) === null || _l === void 0 ? void 0 : _l.checkboxVirtualListOptions) === null || _m === void 0 ? void 0 : _m.virtualListItemHeight }, this.count) })] }));
        };
        const { filter, optionsArguments } = props;
        let { comparisonOptions, defaultComparisonOptions: oldComparisonType } = (filter === null || filter === void 0 ? void 0 : filter.settings) || {};
        const savedOldValue = (_a = optionsArguments === null || optionsArguments === void 0 ? void 0 : optionsArguments.filters) === null || _a === void 0 ? void 0 : _a.find((x) => x.key === filter.key);
        this.comparizonOptions = [];
        let comparisonType = null;
        let options = ((_b = savedOldValue === null || savedOldValue === void 0 ? void 0 : savedOldValue.options) !== null && _b !== void 0 ? _b : []).map((option) => ({ value: option, label: option }));
        let values = (_c = savedOldValue === null || savedOldValue === void 0 ? void 0 : savedOldValue.options) !== null && _c !== void 0 ? _c : [];
        if (filter.isDefault && (filter === null || filter === void 0 ? void 0 : filter.settings.defaultOptions)) {
            values = ((_d = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _d === void 0 ? void 0 : _d.defaultOptions) || [];
            options = (_f = (_e = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _e === void 0 ? void 0 : _e.defaultOptions) === null || _f === void 0 ? void 0 : _f.map((x) => ({ label: x, value: x }));
        }
        if (filter === null || filter === void 0 ? void 0 : filter.oldValue) {
            values = ((_g = filter === null || filter === void 0 ? void 0 : filter.oldValue) === null || _g === void 0 ? void 0 : _g.options) || [];
            options = (_j = (_h = filter === null || filter === void 0 ? void 0 : filter.oldValue) === null || _h === void 0 ? void 0 : _h.options) === null || _j === void 0 ? void 0 : _j.map((x) => ({ label: x, value: x }));
            oldComparisonType = ((_k = filter === null || filter === void 0 ? void 0 : filter.oldValue) === null || _k === void 0 ? void 0 : _k.comparisonType) || oldComparisonType;
        }
        if (comparisonOptions && comparisonOptions.length > 0) {
            this.comparizonOptions = comparisonOptions.map(({ type, label, isDefault }) => {
                const result = { value: type, label };
                if (oldComparisonType === type) {
                    comparisonType = result;
                }
                else if (isDefault && !comparisonType) {
                    comparisonType = result;
                }
                return result;
            });
        }
        this.state = {
            comparisonType,
            options: options.map((x) => (Object.assign(Object.assign({}, x), { id: `${filter.key}_${x.value}` }))),
        };
        this.optionsValues = values;
    }
    componentDidMount() {
        this.fetchAndSaveOptions();
    }
    componentWillUnmount() {
        clearTimeout(this.fetchTimer);
    }
}
