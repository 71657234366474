import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BaseModalFilter from './BaseModalFilter';
import { IconButton, InputText } from '@just-ai/just-ui';
import classNames from 'classnames';
import './BaseModalFilter.scss';
export default class CustomFieldsModal extends BaseModalFilter {
    constructor(props) {
        var _a, _b, _c, _d;
        super(props);
        this.count = 0;
        this.preventDefault = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        this.onConfirm = () => {
            const result = { responseDataJsonPaths: [...this.optionsValues] };
            this.props.onConfirm(result, result.responseDataJsonPaths.length === 0);
        };
        this.showClearButton = () => {
            return this.optionsValues.length > 0;
        };
        this.onCancel = () => {
            const oldValue = this.getOldValue();
            if (!oldValue && !this.props.filter.isRequired)
                return this.props.onDelete();
            const options = (oldValue === null || oldValue === void 0 ? void 0 : oldValue.responseDataJsonPaths) || [];
            this.optionsValues = options;
            this.forceUpdate();
            this.props.onCancel();
        };
        this.onClear = () => {
            this.optionsValues = [];
            this.count += 1;
            this.forceUpdate();
        };
        this.addValueAsOption = (e) => {
            // @ts-ignore
            const value = e.target.value && e.target.value.trim();
            if (e.key === 'Enter' && value) {
                e.preventDefault();
                e.stopPropagation();
                if (!this.optionsValues.includes(value)) {
                    this.optionsValues.push(value);
                }
                // @ts-ignore
                this.count += 1;
                this.forceUpdate();
            }
        };
        this.onDeleteOption = (value) => {
            this.optionsValues = this.optionsValues.filter(x => x !== value);
            this.forceUpdate();
        };
        this.renderInputs = () => {
            var _a;
            const { filter } = this.props;
            return (_jsxs("div", { className: classNames('BaseModalFilter_container_inputsContainer', 'BaseModalFilter_container_inputWithEnter'), children: [_jsx(InputText, { placeholder: (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.placeholder, className: 'BaseModalFilter_container_inputWithEnterPadding', size: 'md', onKeyDown: this.addValueAsOption }, this.count), _jsx("div", { className: 'BaseModalFilter_container_fieldOptionsContainer', children: this.optionsValues.map((value, index) => (_jsxs("div", { className: 'BaseModalFilter_container_fieldOption', children: [_jsx("span", { children: value }), _jsx(IconButton, { color: 'primary', name: 'faTimes', size: 'sm', flat: true, value: value, onClick: () => this.onDeleteOption(value) })] }, value))) })] }));
        };
        this.optionsValues = ((_b = (_a = props.filter) === null || _a === void 0 ? void 0 : _a.oldValue) === null || _b === void 0 ? void 0 : _b.responseDataJsonPaths)
            ? [...(_d = (_c = props.filter) === null || _c === void 0 ? void 0 : _c.oldValue) === null || _d === void 0 ? void 0 : _d.responseDataJsonPaths]
            : [];
    }
}
