import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import jaicp from 'images/jaicp.svg';
import toviePlatform from 'images/tovie/t-platform.svg';
import toviePlatformText from 'images/tovie/t-platform-text.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/JaicpStyleComponent'));

export default class JaicpTheme extends BaseTheme {
  backgroundSvgColor: string = '#353374';

  renderTitle() {
    return (
      <div className='app-title'>
        {isEuroInstance() ? <img src={toviePlatformText} alt='description-logo' /> : <h1>JAICP</h1>}
        {!isEuroInstance() && (
          <h2>
            Part of Just AI <br />
            Conversational Cloud
          </h2>
        )}
      </div>
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = isEuroInstance() ? '/c/tovie/t-plarform.svg' : '/c/jaicp.svg';
    description.content = '';
  }

  renderLogo() {
    return (
      <div className='app-logo'>
        <img src={isEuroInstance() ? toviePlatform : jaicp} alt='logo' />
      </div>
    );
  }
}
