import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Button } from '@just-ai/just-ui';
import { useCompositeFiltersContext } from './index';
export const Top10 = () => {
    const { top10, top10Label, selectTop } = useCompositeFiltersContext();
    const selectTopHandler = useCallback(value => () => {
        selectTop(value);
    }, [selectTop]);
    if (top10.length === 0)
        return null;
    return (_jsxs("div", { className: 'top10Results_container', children: [top10Label && _jsx("div", { className: 'top10Results_label', children: top10Label }), _jsx("div", { className: 'top10Results', children: top10.map(oneOfTop => (_jsx(Button, { color: 'secondary', onClick: selectTopHandler(oneOfTop), size: 'sm', outline: true, "data-test-id": `top10Item-${oneOfTop}`, children: oneOfTop }, oneOfTop))) })] }));
};
