import { Component, SyntheticEvent } from 'react';
import { matchPath } from 'react-router';
import classNames from 'classnames';
import queryString from 'query-string';
// @ts-ignore
import Cookies from 'universal-cookie';
import { DomainOptions, OptionsResponseDto } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Banner, FormFeedback, Spinner } from '@just-ai/just-ui/dist';

import { t } from 'localization';
import localize from 'localization';
import { getDomainData, getUserLanguage, isAxiosError, langToUpperCase } from 'pipes/functions';
import { axios } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import Recaptcha, { isCaptchaReady } from 'components/Recaptcha';
import chatSupportController from 'helpers/chatSupportController';
import { utmKeeperService } from 'service/UtmKeeper';

import { basepageLocalization } from './localization/basepage.loc';
import BasePageBackground from './BasePageBackground';

import './index.scss';

localize.addTranslations(basepageLocalization);

const cookies = new Cookies();

export type Error = {
  args?: {
    path: string;
    [key: string]: string | number;
  } | null;
  error?: string;
  uuid?: string;
};

export type DomainToLoginItem = {
  host: string;
  token: string;
};

export interface BaseStateTypes {
  gRecaptchaResponse?: any;
  fetching: boolean;
  gRecaptchaError?: boolean;
  errors: Error[] | [];
  isLoginPage?: boolean;
  loaded?: boolean;
}

export interface BasePropTypes {
  location: {
    search: string;
    pathname: string;
  };
}

export class BasePage<PropTypes extends BasePropTypes, StateTypes extends BaseStateTypes> extends Component<
  PropTypes,
  StateTypes
> {
  name: string = '';
  static contextType = AppContext;
  recaptchaInstance: any = null;
  supportTimer: any;
  captchaPromiseResolve: any = null;
  captchaReadyInterval: number | null = null;

  AccountsadminService = new AccountsadminService(axios);

  renderInputs = () => {};

  renderButtons = () => {};

  submit = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  renderBody = () => {
    return (
      <form onSubmit={this.submit}>
        {this.renderInputs()}
        {this.renderButtons()}
      </form>
    );
  };

  renderHead = () => {};

  renderFooter = () => {};

  renderCommonErrors = (): JSX.Element | void | false | unknown => {
    const { errors } = this.state;
    const commonErrors: Error[] = errors.filter(
      (error: Error) => !error?.args?.path || error?.args?.path === '$.redirectUrl'
    );
    return (
      commonErrors?.length > 0 && (
        <div className='base-page_formarea-errors'>
          <Banner
            type='danger'
            withIcon
            BannerText={() => (
              <>
                {commonErrors.map(error => (
                  <div key={`commonError_${error.error}`}>{t(`BasePage:BE-error ${error.error}`, error.uuid)}</div>
                ))}
              </>
            )}
          />
        </div>
      )
    );
  };

  renderArea = () => {
    const { fetching } = this.state;
    const {
      location: { pathname },
    } = this.props;
    return (
      <div
        data-test-id={this.name ? `${this.name}Page` : 'BasePage:FormArea'}
        className={classNames('base-page_formarea', {
          'phone-verification-area': pathname.includes('/c/phone-verification'),
        })}
      >
        {fetching && <Spinner size='4x' />}
        {this.renderHead()}
        {this.renderCommonErrors()}
        {this.renderBody()}
        {this.renderFooter()}
      </div>
    );
  };

  renderCopyright = () => {
    const { domainOptions } = this.context;
    return (
      <div className='base-page_copyright'>
        <p>{domainOptions?.copyright && <small data-test-id='BasePage.Copyright'>{domainOptions?.copyright}</small>}</p>
      </div>
    );
  };

  renderLeftBlock = () => {
    const { domainOptions, themeInstance } = this.context;
    const partOfCC = domainOptions?.partOfConversationalCloud || false;
    const themeExistAndNotImmers = domainOptions?.defaultTheme !== 'immers';

    return (
      <div className={classNames('base-page_left-block', { 'not-part-of-cc': !partOfCC })}>
        <div className='base-page_left-block-app-info-wrap'>
          {themeInstance.renderLogo()}
          {themeInstance.renderTitle()}
        </div>
        {partOfCC && themeExistAndNotImmers && themeInstance.renderLogosArr()}
      </div>
    );
  };

  verifyCallback = (response: any) => {
    this.setState(
      {
        gRecaptchaResponse: response,
        gRecaptchaError: false,
      },
      () => {
        if (this.captchaPromiseResolve) {
          this.captchaPromiseResolve();
          this.captchaPromiseResolve = null;
        }
      }
    );
  };

  executeCaptcha = async () => {
    const { gRecaptchaResponse } = this.state;

    if (cookies.get('CAPTCHA_BYPASS_TOKEN')) return;

    await new Promise(res => {
      this.captchaReadyInterval = window.setInterval(() => {
        if (isCaptchaReady()) {
          res(null);
          this.captchaReadyInterval && clearInterval(this.captchaReadyInterval);
        }
      }, 1000);
    });

    if (this.recaptchaInstance && !gRecaptchaResponse) {
      await this.recaptchaInstance.execute();
      await new Promise(res => {
        this.captchaPromiseResolve = res;
      });
    }
  };

  resetCaptcha = () => {
    if (Boolean(this.recaptchaInstance)) {
      this.recaptchaInstance?.reset();
      this.setState({ gRecaptchaResponse: null });
    }
  };

  renderCaptcha = (): JSX.Element | void => {
    const { appConfig } = this.context;

    return (
      appConfig?.captcha?.enabled &&
      appConfig?.captcha?.siteKey && (
        <Recaptcha
          data-test-id='BasePage.recaptcha'
          ref={(instance: any) => (this.recaptchaInstance = instance)}
          sitekey={appConfig?.captcha?.siteKey}
          className='captcha'
          size='invisible'
          verifyCallback={this.verifyCallback}
          expiredCallback={this.resetCaptcha}
          hl={getUserLanguage().substr(0, 2).toUpperCase()}
        />
      )
    );
  };

  supportAddOnMessageListener = () => {
    try {
      chatSupportController.init();
      chatSupportController.show();
    } catch (e) {
      this.supportTimer = setTimeout(this.supportAddOnMessageListener, 500);
    }
  };

  renderFieldError = (name?: string) => {
    const { errors } = this.state;

    const error: Error | undefined = errors.find((error: Error) => error?.args?.path?.includes(name as string));

    return error ? (
      <FormFeedback tag='div' valid={false}>
        {t(
          error['error']
            ? `BasePage:BE-error ${error['error']} ${error['args']?.['path']}`
            : 'Register: required field',
          t(`Register: password ${error['args']?.['strength']}`)
        )}
      </FormFeedback>
    ) : null;
  };

  externalSsoLogin = async (email: string) => {
    try {
      const { available, oauth2Provider } = await this.AccountsadminService.checkEmailForSso({
        email: email.trim() || '',
      });

      if (!available || !oauth2Provider) {
        this.setState({
          errors: [{ args: { path: 'email' }, error: 'external.sso.not.available' }],
          fetching: false,
        });
        return;
      }

      this.loginWithProvider(oauth2Provider);
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];

        this.setState({
          errors: errors,
        });
      }
    }
  };

  loginWithProvider = (provider: string, invitationEmail?: string, invitationAccountId?: string) => {
    const {
      location: { search },
    } = this.props;

    const { appConfig, language } = this.context;

    const { redirectUrl, product } = getDomainData(search, appConfig.domains);
    const { redirectUrl: queryRedirectUrl } = queryString.parse(search.replace('?', ''));

    if (!product) return;

    localStorage.CLOUD_REDIRECT_URL = queryRedirectUrl || redirectUrl;

    const url = new URL(`${window.location.origin}/api/accountsadmin/public/authorization/oauth2/${provider}`);
    url.searchParams.set('product', product);
    url.searchParams.set('language', langToUpperCase(language));
    invitationEmail && url.searchParams.set('invitationEmail', invitationEmail);
    invitationAccountId && url.searchParams.set('invitationAccountId', invitationAccountId);
    const utmMarks = utmKeeperService.getUtmMarks();

    if (utmMarks) {
      Object.entries(utmMarks).forEach(([key, value]) => {
        url.searchParams.set(key, value);
      });
    }

    window.location.href = url.toString();
  };

  checkDomainForDirectSso = () => {
    const { appConfig } = this.context;
    const { domains } = appConfig;

    const currentDomainData = Object.values(domains as OptionsResponseDto['domains']).find(
      (value: DomainOptions) => value.domain === window.location.hostname
    );

    if (!currentDomainData || !currentDomainData?.domainSso?.oauth2Provider) return;

    return currentDomainData.domainSso.oauth2Provider;
  };

  render() {
    const {
      location: { search },
    } = this.props;

    const queryParams = queryString.parse(search.replace('?', ''));

    const { loaded } = this.state;
    const { themeInstance } = this.context;

    const isLoginPage = matchPath(this.props.location.pathname, {
      path: '/c/login',
      exact: true,
    });

    const isPageWithCheck = matchPath(this.props.location.pathname, {
      path: ['/c/login', '/c/register', '/c/register-with-invitation'],
      exact: true,
    });

    if ((!queryParams.needAuth && isLoginPage) || (isPageWithCheck && this.checkDomainForDirectSso())) return null;

    if (!loaded)
      return (
        <>
          <Spinner size='4x' />
        </>
      );

    return (
      <div className='base-page'>
        {this.renderLeftBlock()}
        {this.renderArea()}
        {this.renderCopyright()}
        {this.renderCaptcha()}
        <BasePageBackground color={themeInstance.backgroundSvgColor} />
      </div>
    );
  }
}
