import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '../Icon';
import './Banner.scss';
import classNames from 'classnames';
import { Button } from '../Button';
const IconByBannerType = {
    success: 'faCheckSquare',
    info: 'faInfoCircle',
    danger: 'faExclamationCircle',
    warning: 'faExclamationTriangle',
};
export function Banner({ BannerText, onClose, type = 'info', action, withIcon = false, className, iconName, iconSize, iconClassName, }) {
    return (_jsxs("div", { className: classNames('justui_banner', type, className, { 'with-close': onClose }), children: [withIcon ? (_jsx(Icon, { className: classNames('justui_banner-icon-type', iconClassName), name: iconName || IconByBannerType[type], color: type, size: iconSize })) : null, _jsx("div", { className: 'justui_banner-text', children: _jsx(BannerText, {}) }), action ? (_jsx("div", { className: 'justui_banner-buttons', children: Array.isArray(action) ? (action.map((actionItem, index) => (_jsx(Button, { size: 'sm', color: type, onClick: actionItem.callback, "data-test-id": `AccountManagerPage.BannerButton.Button${index + 1}`, children: actionItem.text }, `Banner-button-${actionItem.text}`)))) : (_jsx(Button, { size: 'sm', color: type, onClick: action.callback, "data-test-id": 'AccountManagerPage.BannerButton', children: action.text })) })) : null, onClose ? (_jsx("div", { className: 'justui_banner-close', onClick: onClose, children: _jsx(Icon, { name: 'faTimes', color: 'secondary', size: 'sm' }) })) : null] }));
}
