import moment from 'moment';
export function buildDateRangeLabel(defaultLabel, filter, t, locale, timeZone) {
    let { from, to } = filter || {};
    if (from && timeZone) {
        from = applyTimeZone(moment(from).toDate(), timeZone);
    }
    if (to && timeZone) {
        to = applyTimeZone(moment(to).toDate(), timeZone);
    }
    const matrix = locale === 'eng' ? 'MM.DD.YYYY, hh:mm A' : 'DD.MM.YYYY, HH:mm';
    let result = defaultLabel;
    if (from && !to) {
        result += `: ${t('Analytics: from')} ${moment(from).format(matrix)}`;
    }
    else if (!from && to) {
        result += `: ${t('Analytics: to')} ${moment(to).format(matrix)}`;
    }
    else if (from && to) {
        result += `: ${moment(from).format(matrix)} - ${moment(to).format(matrix)}`;
    }
    return result;
}
const applyTimeZone = (date, timeZone, reduce) => {
    const utcOffset = -moment().tz(timeZone).utcOffset();
    return moment(date)
        .add((date.getTimezoneOffset() - utcOffset) * (reduce ? -1 : 1), 'minutes')
        .toDate();
};
export function buildCheckboxLabel(defaultLabel, filter, t) {
    let { options } = filter || {};
    if (!options)
        options = [];
    return `${defaultLabel}: ${options.length > 0 ? options.length : t('Analytics: All')}`;
}
