var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { FiltersPanelState, FilterTypeNameList, } from '../../types';
import Filter from './Filter';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './FiltersPanel.scss';
import './FiterPanelStatic.scss';
import { buildCheckboxDefaultValue, buildDateTimeRangeDefaultValue, buildMultipleFilterDefaultValue, buildNumericRangeDefaultValue, buildOptionsDefaultValue, buildTextDefaultValue, buildTreeDefaultValue, } from '../../utils/buildDefaultValueFunctions';
import classnames from 'classnames';
import { isEqual } from 'lodash';
export const BUILD_DEFAULT_VALUE_FUNC = {
    DATE_TIME_RANGE: buildDateTimeRangeDefaultValue,
    RADIO: buildOptionsDefaultValue,
    NUMERIC_RANGE: buildNumericRangeDefaultValue,
    CHECKBOX: buildCheckboxDefaultValue,
    CUSTOM_FIELDS_IN_RESPONSE: () => { },
    TEXT: buildTextDefaultValue,
    TREE: buildTreeDefaultValue,
    COMPOSITE_FILTER: () => { },
    MULTIPLE_FILTER: buildMultipleFilterDefaultValue,
};
export default class FiltersPanel extends Component {
    constructor(props) {
        super(props);
        this.lastKey = 1;
        this.values = [];
        this.state = new FiltersPanelState();
        this.updateFiltersWithValues = (values) => {
            const isCompositeFilter = values.find(x => x.filterKey);
            if (!isEqual(values, this.values) && !isCompositeFilter) {
                this.applySavedFilters(values);
                return;
            }
            this.values = values;
            let newUsedFilters = this.state.usedFilters;
            if (isCompositeFilter) {
                const filterKeyMap = this.values.reduce((result, value) => {
                    if (value.filterKey)
                        result[value.key] = value.filterKey;
                    return result;
                }, {});
                newUsedFilters = this.state.usedFilters.map(x => {
                    if (filterKeyMap[x.key])
                        x.filterKey = filterKeyMap[x.key];
                    return x;
                });
            }
            this.setState({ usedFilters: newUsedFilters });
        };
        this.getFilters = (props) => __awaiter(this, void 0, void 0, function* () {
            const filters = yield this.props.filtersFetchInterface.getFilters(props);
            const data = filters.data.filter((x) => FilterTypeNameList.includes(x.type));
            this.setState({
                filters: data.map(filter => {
                    return Object.assign({}, filter);
                }),
            }, () => __awaiter(this, void 0, void 0, function* () {
                const { accountId, projectShortName, filterSessionIdController, history } = this.props;
                let filters = [];
                if (accountId && projectShortName && filterSessionIdController && history) {
                    filters = yield filterSessionIdController.get(accountId, projectShortName, history);
                }
                else if (this.props.cachedFilters) {
                    filters = this.props.cachedFilters;
                }
                if ((filters === null || filters === void 0 ? void 0 : filters.length) > 0) {
                    this.applySavedFilters(filters);
                }
                else {
                    this.buildAndFetchDefaultValues(data);
                    this.chooseDefaultFilters(data);
                }
            }));
        });
        this.applySavedFilters = (oldFilters) => {
            const { filters } = this.state;
            const usedFilters = oldFilters.reduce((result, oldFilter) => {
                let filter = filters.find(({ key }) => key === oldFilter.key);
                if (filter) {
                    result.push(Object.assign(Object.assign({ oldValue: Object.assign({}, oldFilter) }, filter), { reactKey: this.lastKey, stayClosed: true }));
                    this.lastKey += 1;
                }
                return result;
            }, []);
            const usedFiltersKeys = usedFilters.map(filter => filter.key);
            this.values = [...oldFilters.filter(filter => usedFiltersKeys.includes(filter.key))];
            this.setState({ usedFilters: this.sortFilters(usedFilters) }, this.fetchData);
        };
        this.sortFilters = (filters) => {
            return [...filters].sort((a, b) => {
                const aNumber = a.isRequired ? 1 : 0;
                const bNumber = b.isRequired ? 1 : 0;
                return bNumber - aNumber;
            });
        };
        this.buildAndFetchDefaultValues = (filters) => {
            const defaultFilters = filters.filter(x => x.isDefault);
            if (this.props.values && this.props.forceDefaultValues) {
                this.values = this.props.values;
            }
            else {
                this.values = defaultFilters.reduce((result, filter) => {
                    const { type } = filter;
                    let value;
                    try {
                        value = BUILD_DEFAULT_VALUE_FUNC[type](filter);
                    }
                    catch (e) {
                        console.error(e);
                    }
                    if (value) {
                        result.push(value);
                    }
                    return result;
                }, []);
            }
            this.fetchData();
        };
        this.chooseDefaultFilters = (filters) => {
            const defaultFilters = filters.filter(x => x.isDefault);
            const usedFilters = [];
            for (let filter of defaultFilters) {
                const usedFilter = this.buildUsedFilter(this.lastKey, filter.key, true);
                if (usedFilter) {
                    usedFilters.push(usedFilter);
                }
                this.setState({ usedFilters: this.sortFilters([...this.state.usedFilters, ...usedFilters]) });
            }
        };
        this.onChooseFilter = (reactKey, key, forceClose) => {
            this.setState({ usedFilters: [...this.state.usedFilters, this.buildUsedFilter(reactKey, key, forceClose)] });
        };
        this.buildUsedFilter = (reactKey, key, forceClose) => {
            const { filters } = this.state;
            const filter = filters.find(x => x.key === key);
            if (!filter)
                return;
            this.lastKey += 1;
            return Object.assign(Object.assign({}, filter), { reactKey, stayClosed: Boolean(forceClose) });
        };
        this.onDeleteFilter = (key) => {
            var _a;
            const { usedFilters } = this.state;
            const filterToDelete = usedFilters.find(x => x.key === key);
            const filterToDeleteValue = this.values.find(x => x.key === key);
            const newUsedFilters = usedFilters.filter(x => x.key !== key);
            this.setState({ usedFilters: newUsedFilters });
            if (filterToDeleteValue) {
                this.values = this.values.filter(x => x.key !== key);
                this.fetchData();
                const filter = this.state.filters.find(x => x.key === key);
                if (filter)
                    filter.isDefault = false;
            }
            if ((_a = filterToDelete === null || filterToDelete === void 0 ? void 0 : filterToDelete.settings) === null || _a === void 0 ? void 0 : _a.updateFiltersOnChange) {
                this.getFilters(Object.assign(Object.assign({}, filterToDelete), { options: [] }));
            }
        };
        this.onChange = (_a, clear) => {
            var _b;
            var { key, type } = _a, additionalParams = __rest(_a, ["key", "type"]);
            const oldValues = [...this.values];
            this.values = this.values.filter(x => x.key !== key);
            if (!clear) {
                this.values.push(Object.assign({ key, type }, additionalParams));
            }
            if (JSON.stringify(oldValues.sort()) !== JSON.stringify(this.values.sort())) {
                this.fetchData();
            }
            const currentFilter = this.state.filters.find(x => x.key === key);
            if ((_b = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.settings) === null || _b === void 0 ? void 0 : _b.updateFiltersOnChange) {
                this.getFilters(Object.assign({ key, type }, additionalParams));
            }
            this.forceUpdate();
        };
        this.fetchData = () => {
            if (this.props.setFilterData) {
                this.props.setFilterData(this.values);
            }
            this.props.onChange(this.values);
        };
        //@ts-ignore
        this.observer = new ResizeObserver(entities => {
            entities.forEach(({ target }) => {
                var _a, _b, _c, _d;
                const position = target.getBoundingClientRect();
                const containerPosition = (_d = (_c = (_b = (_a = target === null || target === void 0 ? void 0 : target.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.parentElement) === null || _d === void 0 ? void 0 : _d.getBoundingClientRect();
                if (!containerPosition)
                    return;
                if (position.right + (target.className.includes('popper-wrapper-right') ? position.width : 0) >
                    (containerPosition === null || containerPosition === void 0 ? void 0 : containerPosition.right)) {
                    target.classList.add('popper-wrapper-right');
                }
                else {
                    target.classList.remove('popper-wrapper-right');
                }
            });
        });
    }
    componentDidMount() {
        this.getFilters();
    }
    componentWillUnmount() {
        this.observer.disconnect();
    }
    componentDidUpdate(prevProps, prevState) {
        const { values, resource, setFilterData, onChange } = this.props;
        if (resource !== prevProps.resource) {
            setFilterData && setFilterData(this.values);
            onChange([...this.values]);
        }
        if (values && values !== prevProps.values && values !== this.values) {
            this.updateFiltersWithValues(values);
        }
    }
    render() {
        const { filters, usedFilters } = this.state;
        const { locale, bottomActions, timeZone, withoutLabel, rootClassName, t } = this.props;
        const usedFiltersKeys = usedFilters.map(x => x.key);
        const filterComponents = [];
        usedFilters.forEach(usedFilter => filterComponents.push({
            key: usedFilter.reactKey,
            component: (_jsx(Filter, { reactKey: usedFilter.reactKey, filter: usedFilter, onDelete: this.onDeleteFilter, onChoose: this.onChooseFilter, onChange: this.onChange, timeZone: timeZone, locale: locale, optionsArguments: { filters: this.values || [] }, filtersFetcher: this.props.filtersFetchInterface, t: t, resizeObserver: this.observer, filters: filters })),
        }));
        if (usedFilters.length < filters.length) {
            filterComponents.push({
                key: this.lastKey,
                component: (_jsx(Filter, { reactKey: this.lastKey, onChoose: this.onChooseFilter, filters: filters, hidden: usedFiltersKeys.length === filters.length, usedFiltersKeys: usedFiltersKeys, locale: locale, filtersFetcher: this.props.filtersFetchInterface, t: t, resizeObserver: this.observer })),
            });
        }
        return (_jsxs("div", { className: classnames('filtersPanelContainer', bottomActions && 'withBottomActions', rootClassName && rootClassName), "data-test-id": 'FiltersPanel.MainContainer', children: [!withoutLabel && (_jsxs("p", { style: {
                        color: 'var(--gray-800)',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        marginRight: '0.5rem',
                        marginBottom: 0,
                    }, children: [t('Analytics: Filters'), ":"] })), _jsx("form", { className: 'filtersPanel_filtersContainer', children: _jsx(TransitionGroup, { className: 'filtersPanel_filtersContainer', children: filterComponents.map(({ key, component }) => (_jsx(CSSTransition, { timeout: 400, classNames: 'filtersPanel_filterContainer', unmountOnExit: true, children: component }, key))) }) }), bottomActions && _jsx("div", { className: 'bottomActions', children: bottomActions })] }));
    }
}
