import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRef } from 'react';
import BaseModalFilter from './BaseModalFilter';
import { JustSelect, Icon, InputText } from '@just-ai/just-ui';
import './BaseModalFilter.scss';
import classNames from 'classnames';
class CheckboxState {
    constructor() {
        this.comparisonType = 'CONTAINS';
        this.notEmpty = false;
        this.isCaseSensitive = false;
    }
}
//@ts-ignore //TODO fix it
export const createComparisonOptions = comparisonOptions => {
    let comparisonOptionsValues = [];
    let comparisonType = null;
    if (comparisonOptions && comparisonOptions.length > 0) {
        comparisonOptionsValues = comparisonOptions.map(({ type, label, isDefault }) => {
            const result = { value: type, label };
            if (isDefault)
                comparisonType = result;
            return result;
        });
    }
    return [comparisonOptionsValues, comparisonType];
};
export default class TextModal extends BaseModalFilter {
    constructor(props) {
        var _a, _b;
        super(props);
        this.text = createRef();
        this.defaultValue = '';
        this.count = 0;
        this.onChangeComparison = (value) => {
            var _a, _b;
            //@ts-ignore
            if (((_a = this.state.comparisonType) === null || _a === void 0 ? void 0 : _a.value) === value[0])
                return;
            if (value === 'NOT_IMPORTANT' && ((_b = this.text) === null || _b === void 0 ? void 0 : _b.current))
                this.text.current.value = '';
            this.setState({ comparisonType: value[0] }, () => {
                var _a;
                if (((_a = this.text) === null || _a === void 0 ? void 0 : _a.current) && value[0] !== 'NOT_IMPORTANT')
                    this.text.current.focus();
            });
        };
        this.onConfirm = () => {
            var _a, _b, _c, _d;
            const { comparisonType, isCaseSensitive } = this.state;
            const result = {
                values: [((_b = (_a = this.text) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.value.trim()) || ''],
                //@ts-ignore
                comparisonType: comparisonType,
                isCaseSensitive: isCaseSensitive,
            };
            this.defaultValue = ((_d = (_c = this.text) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.value.trim()) || '';
            this.props.onConfirm(result, false);
        };
        this.onCancel = () => {
            var _a;
            const oldValue = this.getOldValue();
            if (!oldValue && !this.props.filter.isRequired)
                return this.props.onDelete();
            const { values } = oldValue || {};
            const comparisonType = (oldValue === null || oldValue === void 0 ? void 0 : oldValue.comparisonType)
                ? ((_a = this.comparisonOptions) === null || _a === void 0 ? void 0 : _a.find(x => x.value === (oldValue === null || oldValue === void 0 ? void 0 : oldValue.comparisonType))) || null
                : null;
            if (values && values[0]) {
                this.defaultValue = values[0];
            }
            else {
                this.defaultValue = '';
            }
            this.count += 1;
            this.setState({ comparisonType }, () => this.props.onCancel());
        };
        this.toggleCleanButton = (value) => {
            if (value.length > 0 && !this.state.notEmpty) {
                this.setState({
                    notEmpty: true,
                });
            }
            if (value.length === 0 && this.state.notEmpty) {
                this.setState({
                    notEmpty: false,
                });
            }
        };
        this.showClearButton = () => {
            return this.state.notEmpty;
        };
        this.onClear = () => {
            var _a;
            this.defaultValue = '';
            this.setState({
                notEmpty: false,
            });
            if ((_a = this.text) === null || _a === void 0 ? void 0 : _a.current)
                this.text.current.value = '';
        };
        this.renderInputs = () => {
            var _a;
            const { comparisonType } = this.state;
            const { placeholder } = (_a = this.props.filter) === null || _a === void 0 ? void 0 : _a.settings;
            return (_jsxs("div", { className: 'BaseModalFilter_container_inputsContainer', children: [this.comparisonOptions.length > 0 && (_jsx(JustSelect, { value: comparisonType, options: this.comparisonOptions, onChange: this.onChangeComparison, "data-test-id": 'FiltersPanel.FilterModal.TextModal.Select' })), _jsxs("div", { className: 'BaseModalFilter_container_inputCustomGroup', children: [_jsx(InputText, { type: 'text', placeholder: placeholder, innerRef: this.text, onChange: this.toggleCleanButton, defaultValue: this.defaultValue, addon: true, "data-test-id": 'FiltersPanel.FilterModal.TextModal.Input' }, this.count), _jsx("div", { onClick: () => this.setState({ isCaseSensitive: !this.state.isCaseSensitive }), className: classNames('BaseModalFilter_container_registerIcon', {
                                    BaseModalFilter_container_registerIconSelected: this.state.isCaseSensitive,
                                }), style: { height: 34 }, "data-test-id": 'FiltersPanel.FilterModal.TextModal.CaseSensitive', children: _jsx(Icon, { color: 'secondary', name: 'faFontCase', size: 'md', style: { height: 34 } }) })] })] }));
        };
        let { comparisonOptions } = ((_a = props === null || props === void 0 ? void 0 : props.filter) === null || _a === void 0 ? void 0 : _a.settings) || {};
        const { values, comparisonType, isCaseSensitive } = ((_b = props === null || props === void 0 ? void 0 : props.filter) === null || _b === void 0 ? void 0 : _b.oldValue) || {};
        if (comparisonType) {
            comparisonOptions = comparisonOptions.map((comparisonTypeObject) => {
                comparisonTypeObject.isDefault = comparisonTypeObject.type === comparisonType;
                return comparisonTypeObject;
            });
        }
        let comparisonTypeNew;
        [this.comparisonOptions, comparisonTypeNew] = createComparisonOptions(comparisonOptions);
        this.defaultValue = values ? values[0] : null;
        this.state = {
            comparisonType: comparisonTypeNew === null || comparisonTypeNew === void 0 ? void 0 : comparisonTypeNew.value,
            notEmpty: false,
            isCaseSensitive: isCaseSensitive || false,
        };
    }
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (!prevProps.showModal && this.props.showModal && this.text && this.text.current) {
            this.text.current.value = this.defaultValue;
            if (this.defaultValue) {
                this.setState({
                    notEmpty: true,
                });
            }
        }
    }
}
