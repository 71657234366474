var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useToggle } from '../../utils/hooks';
import { Popover } from '@just-ai/just-ui';
export function Popohover(props) {
    const { target, children, className, closable = true } = props, restProps = __rest(props, ["target", "children", "className", "closable"]);
    const [isOpen, setOpen, setClose, toggle] = useToggle(false);
    return (_jsx(_Fragment, { children: _jsx(Popover, Object.assign({ isOpen: isOpen, toggle: toggle, target: target, className: `popohover ${className}`, trigger: 'hover' }, restProps, { onCancelClick: closable ? setClose : undefined, children: _jsx("div", { children: children }) })) }));
}
