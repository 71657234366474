import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import BaseModalFilter from '../BaseModalFilter';
import { FILTER_MODAL_COMPONENTS } from '../../Filter';
import styles from './styles.module.scss';
export default class MultipleFilter extends BaseModalFilter {
    constructor(props) {
        var _a, _b, _c, _d, _e, _f;
        super(props);
        this.radioModalRefs = {};
        this.state = {
            values: {},
        };
        this.onConfirm = () => {
            this.props.onConfirm({
                subFiltersValues: Object.entries(this.state.values).map(([key, value]) => {
                    var _a, _b, _c, _d;
                    return ({
                        key: key,
                        option: value,
                        type: (_d = (_c = (_b = (_a = this.props.filter) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.subFilters) === null || _c === void 0 ? void 0 : _c.find(x => x.key === key)) === null || _d === void 0 ? void 0 : _d.type,
                    });
                }),
            }, false);
        };
        this.onChange = (name, value) => {
            this.setState({ values: Object.assign(Object.assign({}, this.state.values), { [name]: value }) });
        };
        this.showButtons = () => {
            return false;
        };
        this.onCancel = () => {
            Object.values(this.radioModalRefs).forEach(ref => {
                var _a;
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.onCancel();
            });
        };
        this.renderInputs = () => {
            var _a, _b, _c;
            return (_jsx("div", { className: styles.MultipleFilter, children: (_c = (_b = (_a = this.props.filter) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.subFilters) === null || _c === void 0 ? void 0 : _c.map(option => {
                    return (_jsx(React.Fragment, { children: FILTER_MODAL_COMPONENTS[option.type](Object.assign(Object.assign({}, this.props), { ref: this.radioModalRefs[option.key], filter: option, onConfirm: this.onConfirm, inMultipleFilter: true, onChange: this.onChange, onCancel: this.props.onCancel })) }, option.key));
                }) }));
        };
        (_b = (_a = props.filter.settings) === null || _a === void 0 ? void 0 : _a.subFilters) === null || _b === void 0 ? void 0 : _b.forEach(subFilter => {
            this.radioModalRefs[subFilter.key] = React.createRef();
        });
        const subFilters = (_d = (_c = props.filter) === null || _c === void 0 ? void 0 : _c.settings) === null || _d === void 0 ? void 0 : _d.subFilters;
        if (!((_e = props.filter) === null || _e === void 0 ? void 0 : _e.isDefault)) {
            const preselectedValues = subFilters === null || subFilters === void 0 ? void 0 : subFilters.reduce((acc, subFilter) => {
                var _a, _b;
                acc[subFilter.key] = ((_b = (_a = subFilter === null || subFilter === void 0 ? void 0 : subFilter.settings) === null || _a === void 0 ? void 0 : _a.defaultOptions) === null || _b === void 0 ? void 0 : _b[0]) || '';
                return acc;
            }, {});
            if (preselectedValues) {
                this.state = { values: preselectedValues };
            }
        }
        if (subFilters && ((_f = props.filter) === null || _f === void 0 ? void 0 : _f.isDefault)) {
            const initialValues = subFilters.reduce((acc, subFilter) => {
                if (subFilter.key) {
                    const subFilterSettings = subFilter.settings;
                    const subFilterStaticOptions = subFilterSettings === null || subFilterSettings === void 0 ? void 0 : subFilterSettings.staticOptions;
                    const activeOptions = subFilterStaticOptions === null || subFilterStaticOptions === void 0 ? void 0 : subFilterStaticOptions.find(option => option.isSelected);
                    acc[subFilter.key] = (activeOptions === null || activeOptions === void 0 ? void 0 : activeOptions.value) || '';
                }
                return acc;
            }, {});
            this.state = Object.assign(Object.assign({}, this.state), { values: initialValues });
        }
    }
}
