import moment from 'moment';
import { CompositeComparisonType } from '../api/client';
import { BUILD_DEFAULT_VALUE_FUNC } from '../components/FiltersPanel/FiltersPanel';
export function buildDateTimeRangeDefaultValue(filter) {
    var _a;
    const { type, key } = filter;
    const { offset, endOffset, unit, comparisonType, from, to } = (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.defaultValue;
    if (comparisonType === 'Any')
        return { type, key, from: null, to: null };
    let needToRound = unit.toLowerCase() !== 'seconds';
    let value = { type, key };
    if (offset) {
        value.from = moment(needToRound ? new Date().setHours(0, 0, 0, 0) : new Date())
            .subtract(offset, unit.toLowerCase())
            .toDate();
        value.to = new Date();
    }
    if (endOffset) {
        value.to = moment()
            .subtract(endOffset, unit.toLowerCase())
            .toDate();
    }
    if (typeof offset !== 'number' && typeof endOffset === 'number') {
        value = { type, key, to: value.to };
    }
    if (from) {
        value.from = moment(from).toDate();
    }
    if (to) {
        value.to = moment(to).toDate();
    }
    if (value.to && needToRound) {
        value.to.setHours(23, 59, 59, 999);
    }
    if (value.from && needToRound) {
        value.from.setHours(0, 0, 0, 0);
    }
    return value;
}
export function buildCheckboxDefaultValue(filter) {
    var _a, _b;
    const { type, key } = filter;
    let defaultValue = (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.defaultOptions;
    const value = {
        type,
        key,
        comparisonType: ((_b = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _b === void 0 ? void 0 : _b.defaultComparisonOptions) || undefined,
    };
    if (defaultValue) {
        //@ts-ignore
        value.options = defaultValue;
    }
    return value;
}
export function buildNumericRangeDefaultValue(filter) {
    var _a;
    const { type, key } = filter;
    let { from, to } = (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.defaultOptions;
    return { type, key, from, to };
}
export function buildTreeDefaultValue(filter) {
    var _a;
    const { type, key } = filter;
    let { options } = (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.defaultOptions;
    return { type, key, options };
}
export function buildTextDefaultValue(filter) {
    var _a, _b, _c, _d;
    const { type, key } = filter;
    let defaultValue = (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.defaultOptions;
    const value = {
        type,
        key,
        isCaseSensitive: false,
        comparisonType: (_d = (_c = (_b = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _b === void 0 ? void 0 : _b.comparisonOptions) === null || _c === void 0 ? void 0 : _c.find(option => option.isDefault)) === null || _d === void 0 ? void 0 : _d.type,
    };
    if (defaultValue) {
        //@ts-ignore
        value.values = [defaultValue];
    }
    else {
        value.comparisonType = CompositeComparisonType.NOTIMPORTANT;
        //@ts-ignore
        value.values = [''];
    }
    return value;
}
export function buildEndBorder(date) {
    const endTimeBorder = date ? new Date(date) : new Date();
    endTimeBorder.setHours(23);
    endTimeBorder.setMinutes(59);
    endTimeBorder.setSeconds(59);
    return endTimeBorder;
}
export function buildStartBorder(date) {
    const startTimeBorder = date || new Date();
    startTimeBorder.setHours(0);
    startTimeBorder.setMinutes(0);
    startTimeBorder.setSeconds(0);
    return startTimeBorder;
}
export function buildOptionsDefaultValue(filter) {
    var _a, _b, _c;
    const { type, key, settings } = filter;
    //@ts-ignore
    const { defaultValue } = settings;
    let option = defaultValue || ((_c = (_b = (_a = filter === null || filter === void 0 ? void 0 : filter.settings) === null || _a === void 0 ? void 0 : _a.staticOptions) === null || _b === void 0 ? void 0 : _b.find(option => option.isSelected)) === null || _c === void 0 ? void 0 : _c.value);
    const value = {
        type,
        key,
        option,
    };
    return value;
}
export function buildMultipleFilterDefaultValue(filter) {
    var _a, _b, _c;
    if (!((_a = filter.settings) === null || _a === void 0 ? void 0 : _a.subFilters))
        return;
    const options = (_c = (_b = filter.settings) === null || _b === void 0 ? void 0 : _b.subFilters) === null || _c === void 0 ? void 0 : _c.map((subFilter) => {
        if (!subFilter.type)
            return null;
        return BUILD_DEFAULT_VALUE_FUNC[subFilter.type](subFilter);
    }).filter(Boolean);
    const value = {
        type: filter.type,
        key: filter.key,
        subFiltersValues: options,
    };
    return value;
}
