export function crispInitializer(token: string) {
  if (!token) return;
  // @ts-ignore
  window.$crisp = [];
  // @ts-ignore
  window.CRISP_WEBSITE_ID = token;

  (function () {
    var d = document;
    var s = d.createElement('script') as HTMLScriptElement;
    s.src = 'https://client.crisp.chat/l.js';
    s.async = true;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
}
