import React from 'react';
import { NavLink } from 'react-router-dom';
import { history } from 'App';
import * as queryString from 'query-string';
import { Button, Spinner } from '@just-ai/just-ui';
import { Banner } from '@just-ai/just-ui/dist';
import { AppLogger } from '@just-ai/logger';

import { SendVerificationEmailDto } from 'api/cloud/client';
import { t } from 'localization';
import localize from 'localization';
import { getDomainData, isAxiosError, isDev } from 'pipes/functions';

import EmailVerificationService from 'service/EmailVerificationService';
import LoginService from 'service/LoginService';
import { ssoService } from 'service/SsoService';
import { BasePage, BasePropTypes, BaseStateTypes, Error as BasePageError } from 'views/BasePage';

import { verifyemailLocalization } from './localization/verifyemail.loc';
import ResendTimer from './ResendTimer';

localize.addTranslations(verifyemailLocalization);

interface VerifyEmailState extends BaseStateTypes {
  email: string;
  fetching: boolean;
  token: string;
  success: boolean;
  needWaitMinute: boolean;
  remainingTime: number;
  errors: BasePageError[] | [];
  error: boolean;
}

class VerifyEmail extends BasePage<BasePropTypes, VerifyEmailState> {
  EmailVerificationService = new EmailVerificationService();
  LoginService = new LoginService();

  state = {
    email: '',
    fetching: true,
    token: '',
    success: false,
    needWaitMinute: false,
    remainingTime: 0,
    errors: [],
    error: false,
    loaded: true,
  };

  async componentDidMount() {
    this.emailVerification().finally(() => this.setState({ fetching: false }));
  }

  emailVerification = async () => {
    const {
      // @ts-ignore
      location: { search },
    } = this.props;
    const queryParams = queryString.parse(search);
    this.setState({
      fetching: true,
    });

    this.supportAddOnMessageListener();

    if (queryParams && !queryParams.token) {
      try {
        const {
          location: { search },
        } = this.props;
        const { appConfig } = this.context;

        const {
          data: { userData },
        } = await this.LoginService.checkIsUserAuthorized();

        if (userData.emailVerified) {
          const { redirectUrl } = getDomainData(search, appConfig?.domains);
          window.location.href = isDev() ? '/' : (redirectUrl as string);
        }
      } catch (e) {
        if (e instanceof Error) {
          AppLogger.error({
            message: 'Error while trying to check if user is authorized',
            exception: e,
          });
        }
      }
    }

    try {
      if (queryParams?.token) {
        this.setState({
          token: (queryParams?.token as string) || '',
        });
        await this.verifyEmail();
      }
      const { data } = await this.EmailVerificationService.getVerificationEmailInfo();

      this.setState({
        email: data?.email as string,
        needWaitMinute: data.remainingTimeMs > 0,
        remainingTime: Math.ceil(data.remainingTimeMs / 1000),
      });
    } catch (error) {
      if (isAxiosError(error) && error?.response?.data?.error === 'common.access.denied') {
        history.push('/c/login');
      }
    }
  };

  finishTimer = () => {
    this.setState({
      remainingTime: 0,
    });
  };

  verifyEmail = async () => {
    const { domainOptions, appConfig } = this.context;
    const {
      location: { search },
    } = this.props;
    const queryParams = queryString.parse(search.replace('?', ''));
    const newQueryParams = { redirectUrl: queryParams?.redirectUrl };
    const newQueryParamsString = '?' + queryString.stringify(newQueryParams);

    const { redirectUrl } = getDomainData(search, appConfig?.domains);

    try {
      await this.LoginService.autoLogin(queryParams?.token as string);

      if (domainOptions?.showPhoneVerification) {
        history.push(`/c/phone-verification${newQueryParamsString}`);
      } else {
        ssoService.stayOrRedirect(redirectUrl as string);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error.response?.data;
        const errors = errorData.errors?.length > 0 ? errorData.errors : [errorData.error];
        this.setState({
          error: true,
          errors: errors,
        });
        throw error;
      }
    }
  };

  sendVerificationMail = async () => {
    const { appConfig } = this.context;
    const {
      location: { search },
    } = this.props;

    const { product, redirectUrl } = getDomainData(search, appConfig?.domains);

    const verificationObject: SendVerificationEmailDto = {
      redirectUrl: redirectUrl as string,
    };

    if (product) verificationObject.product = product;

    try {
      await this.EmailVerificationService.sendVerificationMail(verificationObject);
    } catch (error) {
      if (error instanceof Error) {
        AppLogger.error({
          message: 'Error while trying to send verification email',
          exception: error,
        });
      }
    }
    const time = await this.EmailVerificationService.getVerificationEmailInfo();

    const remainingTimeMs = typeof time?.data.remainingTimeMs === 'number' ? time?.data.remainingTimeMs : 0;
    const remainingTimeSec = Math.round(remainingTimeMs / 1000);

    this.setState({
      needWaitMinute: true,
      remainingTime: remainingTimeSec,
    });
  };

  renderArea = () => {
    const { email, token, success, remainingTime, errors, fetching } = this.state;

    return (
      <div className='base-page_formarea base-page_email-verification'>
        {fetching && <Spinner size='4x' />}
        <h1 data-test-id='VerifyEmailPage.Title'>{t('VerifyEmail: title')}</h1>
        {!token && <h3 dangerouslySetInnerHTML={{ __html: t('VerifyEmail: text', email) }} />}
        {errors?.length > 0 && (
          <Banner
            type='danger'
            withIcon
            BannerText={() => (
              <div>
                {errors.map(error => (
                  <div key={`commonError_${error}`}>{t(`VerifyEmail:BE-error ${error}`)}</div>
                ))}
              </div>
            )}
          />
        )}

        {success && <h3 dangerouslySetInnerHTML={{ __html: t('VerifyEmail: success') }} />}
        {!success && !token && Boolean(remainingTime) && remainingTime > 0 && (
          <ResendTimer timer={remainingTime} finishTimer={this.finishTimer} />
        )}
        <div className='base-page_email-verification-buttons'>
          {!token && (
            <Button
              color='primary'
              onClick={this.sendVerificationMail}
              disabled={Boolean(remainingTime) && remainingTime > 0}
              data-test-id='VerifyEmailPage.Button'
            >
              {t('VerifyEmail: button')}
            </Button>
          )}
        </div>
        {this.renderFooter()}
      </div>
    );
  };

  renderFooter = () => {
    const { location } = this.props;

    return (
      <div className='base-page_formarea-footer'>
        <p>
          {t('VerifyEmail: have account')}
          <NavLink to={`/c/logout${location?.search}`} data-test-id='VerifyEmailPage.Login'>
            {t('Register: login link')}
          </NavLink>
        </p>
      </div>
    );
  };

  renderCaptcha = () => {};
}

export default VerifyEmail;
