import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { StylingLabel } from './Label';
import moment from 'moment';
const DateRangeLabel = ({ value: { argumentsFilter, t, locale } }) => {
    const { from, to } = argumentsFilter;
    const dateFormat = (date) => moment(date)
        .locale(locale.slice(0, 2) || 'en')
        .format(locale.slice(0, 2) === 'en' ? 'MM.DD.YYYY, hh:mm A' : 'DD.MM.YYYY, HH:mm');
    if (from && to) {
        return (_jsxs(_Fragment, { children: [dateFormat(from), " - ", dateFormat(to)] }));
    }
    if (!from && !to) {
        return _jsx(StylingLabel, { label: t('Any').toLowerCase() });
    }
    if (from) {
        return _jsx(StylingLabel, { label: t('After').toLowerCase(), value: dateFormat(from) });
    }
    if (to) {
        return _jsx(StylingLabel, { label: t('Analytics: to'), value: dateFormat(to) });
    }
    return _jsx(_Fragment, {});
};
export default DateRangeLabel;
