import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { JustSelect, IconButton, InputText, usePrevious } from '@just-ai/just-ui';
import { useCompositeFiltersContext } from './index';
import { showInputField, useHandlers } from './hooks';
import classNames from 'classnames';
import { InputFieldWithSuggestion } from './InputFieldWithSuggestion';
import { FilterKey } from '../../../../api/client';
export const CompositeFilterItem = ({ filter, filterIndex }) => {
    const { comparisonOptions, comparisonOptionTypeToValue, filterSuggestions, isLoading, filterKey, } = useCompositeFiltersContext();
    const { onChangeFilterValue, onChangeValueHandler, onChangePropertyHandler, onChangeComparisonOptionHandler, deleteFilterHandler, toggleCaseSensitive, filterData, } = useHandlers(filterIndex, filter);
    const autoFocusFirstFieldRule = usePrevious(true);
    const autoFocusSecondFieldRule = useRef(!autoFocusFirstFieldRule && !isLoading && filterData.value !== '' && filterIndex !== filterSuggestions.filterIndex);
    const isRevertInputs = [FilterKey.STATEROUTES, FilterKey.COMMENTTEXT].includes(filterKey);
    const keyInput = (_jsx(InputFieldWithSuggestion, { value: filterData.value, filterIndex: filterIndex, onChangeValueHandler: onChangeValueHandler, filterSuggestions: filterSuggestions, isLoading: isLoading, onChangeFilterValue: onChangeFilterValue, autoFocusFirstFieldRule: autoFocusFirstFieldRule && !autoFocusSecondFieldRule.current }));
    const valueInput = (_jsx(InputText, { bsSize: 'sm', type: 'text', "data-test-id": `CompositeFilterItem-${filterIndex}-property`, value: filterData.property, autoFocus: !autoFocusFirstFieldRule && autoFocusSecondFieldRule.current, onChange: onChangePropertyHandler }));
    const comparisonOptionInput = (_jsx(JustSelect, { fullWidth: true, options: comparisonOptions, value: filterData.comparisonOption, onChange: onChangeComparisonOptionHandler }));
    const mainInput = (_jsx("div", { className: 'CompositeFilterItem_main-input', tabIndex: 1, children: comparisonOptionInput }));
    const filterInputs = {
        [FilterKey.STATEROUTES]: (_jsxs(_Fragment, { children: [mainInput, keyInput] })),
        [FilterKey.COMMENTTEXT]: (_jsxs(_Fragment, { children: [mainInput, valueInput] })),
        default: (_jsxs(_Fragment, { children: [_jsx("div", { className: 'CompositeFilterItem_main-input', tabIndex: 1, children: keyInput }), comparisonOptionInput] })),
    };
    return (_jsxs("div", { className: classNames('CompositeFilterItem', { CompositeFilterItemRevert: isRevertInputs }), style: { zIndex: 55 - filterIndex, position: 'relative' }, children: [filterInputs[filterKey] || filterInputs.default, filterData.comparisonOption &&
                showInputField(comparisonOptionTypeToValue, comparisonOptions, filterData.comparisonOption) && (_jsxs("div", { className: 'caseSensitive', children: [valueInput, _jsx(IconButton, { className: classNames('btn-caseSensitive btn-rect', { caseSensitive: filterData.caseSensitive }), color: 'secondary', flat: true, name: 'faFontCase', onClick: toggleCaseSensitive, size: 'md', "data-test-id": `CompositeFilterItem-${filterIndex}-case-sensitive` })] })), _jsx("div", { children: _jsx(IconButton, { className: 'btn-rect', color: 'secondary', flat: true, name: 'farTrashAlt', onClick: deleteFilterHandler, size: 'md', "data-test-id": `CompositeFilterItem-${filterIndex}-delete-icon` }) })] }));
};
export const CompositeFilterItemMemo = React.memo(CompositeFilterItem);
