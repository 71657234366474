var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BaseModalFilter from './BaseModalFilter';
import { CompositeFilter } from './CompositeFilter';
class CompositeModalState {
    constructor(comparisonOptionsLike) {
        this.comparisonOptions = [];
        this.top10 = [];
        this.comparisonOptions = (comparisonOptionsLike || []).map(item => (Object.assign({ value: item.type }, item)));
    }
}
export class CompositeModal extends BaseModalFilter {
    constructor(props) {
        var _a, _b;
        super(props);
        this.filtersData = [];
        this.normalizeOldValue = ({ key, comparisonOption, caseSensitive, value, }) => {
            return {
                value: key,
                property: value,
                comparisonOption,
                caseSensitive,
            };
        };
        this.fetchAndSaveOptions = () => {
            const { key, settings, type } = this.props.filter;
            const { filtersFetcher } = this.props;
            const { filters } = this.props.optionsArguments || {};
            const filterForFetch = (filters === null || filters === void 0 ? void 0 : filters.filter(x => x.key !== key)) || [];
            filtersFetcher
                .getFiltersOptions(key, type, {
                size: (settings === null || settings === void 0 ? void 0 : settings.defaultPageSizeForOptions) || 10,
                filters: filterForFetch,
                useAllFilters: false,
            })
                .then(data => {
                let top10 = data
                    .sort((itemA, itemB) => {
                    return (itemB.frequency || 0) - (itemA.frequency || 0);
                })
                    .map(item => item.value);
                this.setState({
                    top10,
                });
            });
        };
        this.onConfirm = () => {
            const normalizedOptions = this.filtersData.map(option => {
                return {
                    comparisonOption: option.comparisonOption,
                    key: option.value,
                    value: option.property,
                    caseSensitive: !!option.caseSensitive,
                };
            });
            this.props.onConfirm({ options: normalizedOptions }, false);
        };
        this.onCancel = () => {
            const oldValue = this.getOldValue();
            if (!oldValue && !this.props.filter.isRequired)
                return this.props.onDelete();
            this.filtersData = (oldValue.options || []).map(this.normalizeOldValue);
            this.props.onCancel();
        };
        this.changeCompositeFilters = (filters) => {
            this.filtersData = filters.map(filter => ({
                comparisonOption: filter.comparisonOption,
                value: filter.value,
                property: filter.property,
                caseSensitive: !!filter.caseSensitive,
            }));
        };
        this.fetchOptions = (filters, useAllFilters) => __awaiter(this, void 0, void 0, function* () {
            const { key, settings, type } = this.props.filter;
            const { filtersFetcher } = this.props;
            return yield filtersFetcher.getFiltersOptions(key, type, {
                size: (settings === null || settings === void 0 ? void 0 : settings.defaultPageSizeForOptions) || 10,
                filters,
                useAllFilters,
            });
        });
        this.onChangeInputFilter = (rawValue) => __awaiter(this, void 0, void 0, function* () {
            const value = rawValue.trim();
            const { key, type } = this.props.filter;
            const { filters } = this.props.optionsArguments || {};
            const filterForFetch = (filters === null || filters === void 0 ? void 0 : filters.filter(x => x.key !== key)) || [];
            filterForFetch.push({
                key,
                type,
                options: [
                    {
                        key: value,
                        caseSensitive: false,
                        comparisonOption: 'NOT_IMPORTANT',
                        value: '',
                    },
                ],
            });
            return yield this.fetchOptions(filterForFetch, true).then(data => {
                return { suggestions: data.map(filter => filter.value) };
            });
        });
        this.renderInputs = () => {
            const { settings } = this.props.filter;
            const { t } = this.props;
            return (_jsxs("div", { className: 'BaseModalFilter_container_inputsContainer BaseModalFilter_container_compositeContainer', children: [(settings === null || settings === void 0 ? void 0 : settings.description) && (_jsx("div", { className: 'description', children: _jsx("p", { style: { color: 'var(--gray-600)' }, children: _jsx("small", { children: settings === null || settings === void 0 ? void 0 : settings.description }) }) })), _jsx(CompositeFilter, { t: t, onInputChange: this.onChangeInputFilter, filterKey: this.props.filter.key, filters: this.filtersData, comparisonOptions: this.state.comparisonOptions, keyCode: this.props.filter.key, top10: this.state.top10, top10Label: settings === null || settings === void 0 ? void 0 : settings.popularLabel, onChange: this.changeCompositeFilters }, `showModal${this.props.showModal}`)] }));
        };
        this.filtersData = (((_a = this.getOldValue()) === null || _a === void 0 ? void 0 : _a.options) || []).map(this.normalizeOldValue);
        this.state = new CompositeModalState((_b = this.props.filter.settings) === null || _b === void 0 ? void 0 : _b.comparisonOptions);
    }
    componentDidMount() {
        if (this.props.showModal) {
            this.fetchAndSaveOptions();
        }
    }
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (this.props.showModal && !prevProps.showModal) {
            this.fetchAndSaveOptions();
        }
    }
}
