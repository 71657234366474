import React from 'react';

import { isEuroInstance } from 'helpers/isAccessFunction';
import cloud from 'images/cloud.svg';
import tovieCloud from 'images/tovie/t-cloud.svg';
import tovieCloudText from 'images/tovie/t-cloud-text.svg';

import BaseTheme from './BaseTheme';

const StyleComponent = React.lazy(() => import('./styleComponents/CloudStyleComponent'));
export default class CloudTheme extends BaseTheme {
  backgroundSvgColor: string = '#BA75E099';

  renderTitle() {
    return (
      <div className='app-title'>
        <div className='cloud-title'>
          {isEuroInstance() ? <img src={tovieCloudText} alt='description-logo' /> : <h1>Just AI</h1>}
          {!isEuroInstance() && (
            <h2>
              Conversational <br />
              Cloud
            </h2>
          )}
        </div>
      </div>
    );
  }

  getStyleWrapper() {
    return StyleComponent;
  }

  setAppMeta(): void {
    const { link, description } = this.getMetaLinks();
    document.title = this.getProductName();
    link.href = isEuroInstance() ? '/c/tovie/t-cloud.svg' : '/c/cloud.svg';
    description.content = '';
  }

  renderLogo() {
    return (
      <div className='app-logo'>
        <img src={isEuroInstance() ? tovieCloud : cloud} alt='logo' />
      </div>
    );
  }
}
