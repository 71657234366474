export const headerLocalization = {
  'Header: profile': {
    eng: 'Profile',
    ru: 'Профиль',
    cn: 'Profile',
  },
  'Header: accounts': {
    eng: 'Accounts',
    ru: 'Аккаунты',
    cn: 'Accounts',
  },
  'Header: users': {
    eng: 'Users',
    ru: 'Пользователи',
    cn: 'Users',
  },
  'Header: admins': {
    eng: 'Administrators',
    ru: 'Администраторы',
    cn: 'Administrators',
  },
  'Header: features': {
    eng: 'Feature settings',
    ru: 'Настройки фич',
    cn: 'Feature settings',
  },
  'Header: logout': {
    eng: 'Sign out',
    ru: 'Выйти',
    cn: 'Sign out',
  },
};
