import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { StylingLabel } from './Label';
const NumericRangeLabel = ({ value: { argumentsFilter, t } }) => {
    const { from, to } = argumentsFilter;
    if ((!from && !to) || (from === '0' && to === '0') || to === '0') {
        return _jsx(StylingLabel, { label: t('Analytics:FiltersPanel:NotSelected') });
    }
    if (from && to) {
        return (_jsxs(_Fragment, { children: [_jsx(StylingLabel, { label: t('Analytics: from'), value: from }), ' ', _jsx(StylingLabel, { label: t('Analytics: to'), value: to })] }));
    }
    if (from) {
        return _jsx(StylingLabel, { label: t('Analytics: from'), value: from });
    }
    if (to) {
        return _jsx(StylingLabel, { label: t('Analytics: to'), value: to });
    }
    return null;
};
export default NumericRangeLabel;
