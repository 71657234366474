export const filterPanelLocalization = {
  'FilterPanel:Filters:HideFilters': {
    eng: 'Hide filters',
    ru: 'Свернуть фильтры',
  },
  'FilterPanel:Filters:ShowFilters': {
    eng: 'Filters',
    ru: 'Фильтры',
    pt: 'Filtros',
  },
};
