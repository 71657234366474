import { DateTime } from 'luxon';
import { AccountEventDto } from '@just-ai/api/dist/generated/Audit';

import { t } from 'localization';

type UserLogsTableRowProps = {
  log: AccountEventDto;
  tokenHide: boolean;
  targetUserHide: boolean;
  projectHide: boolean;
};

export const UserLogsTableRow = ({ log, tokenHide, targetUserHide, projectHide }: UserLogsTableRowProps) => {
  const { id, creationTime, user, projectShortName, comment, localizedName, targetUser, status, tokenId } = log;
  const date = DateTime.fromJSDate(new Date(creationTime)).toFormat('dd.MM.yyyy HH:mm');
  return (
    <>
      <tr data-test-id={`Userlogs.table.tr.userLogItem_${id}`}>
        <td data-test-id={`Userlogs.table.td.event_${id}`}>{localizedName}</td>
        <td data-test-id={`Userlogs.table.td.created_${id}`}>{date}</td>
        <td data-test-id={`Userlogs.table.td.user_${id}`}>{user?.login}</td>
        {!tokenHide && <td data-test-id={`Userlogs.table.td.tokenId_${id}`}>{tokenId || ''}</td>}
        {!targetUserHide && <td data-test-id={`Userlogs.table.td.targetUser_${id}`}>{targetUser?.login}</td>}
        {!projectHide && <td data-test-id={`Userlogs.table.td.project_${id}`}>{projectShortName}</td>}
        <td data-test-id={`Userlogs.table.td.comment_${id}`}>{comment}</td>
        <td data-test-id={`Userlogs.table.td.ip_and_device_${id}`}>
          {user?.ip}
          <br />
          <span style={{ color: 'var(--gray-600)' }}>
            {`${user?.device?.os || ''} ${user?.device?.browser || ''}`.trim()}
          </span>
        </td>
        <td data-test-id={`Userlogs.table.td.result_${id}`}>{t(`UserLog:Table:Result:${status}`)}</td>
        <td data-test-id={`Userlogs.table.td.impersonator_${id}`}>{user?.impersonator?.login}</td>
      </tr>
    </>
  );
};
