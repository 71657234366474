var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TreeState } from './Tree';
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { clickOnCheckbox } from './utils';
import classNames from 'classnames';
import './TreeWithCheckbox.scss';
import { Tooltip } from '../Tooltip';
import { translate } from './localization';
const TreeCheckbox = ({ node, onChange = (node) => { }, compact, dark, locale }) => {
    var _a;
    const TooltipInput = node.checkboxTooltip ? node.checkboxTooltip : () => null;
    const TooltipSelectedChildren = node.selectorCountTooltip ? node.selectorCountTooltip : () => null;
    const nodeIdReplace = useMemo(() => {
        return node.nodeId.replace(/\W/g, '_');
    }, [node.nodeId]);
    const customData = node.customData || [];
    const isRemoved = customData.some(item => item.key === 'isRemoved' && item.value);
    const botId = customData.find(item => item.key === 'botId');
    return (_jsxs("div", { className: classNames('custom-checkbox', 'tree-checkbox-wrapper', 'custom-control', 'noMargin', { 'custom-checkbox-sm': compact }, { dark: dark }), children: [_jsx("input", { type: 'checkbox', id: `treeCheckbox${nodeIdReplace}`, onChange: event => onChange(node, event), className: classNames('custom-control-input', 'custom-tree-control-input', node.checkbox), checked: node.checkbox && ['ALL', 'PARTIAL', 'PARENT'].includes(node.checkbox) }), _jsx(TooltipInput, { target: `treeCheckbox${nodeIdReplace}` }), _jsx("label", { htmlFor: `treeCheckbox${nodeIdReplace}`, onClick: event => onChange(node, event), className: 'custom-control-label', children: _jsxs("span", { className: 'label-container', children: [_jsx("span", { title: node.name, className: classNames('name', { isDeleted: isRemoved }), style: { textDecoration: node.checkbox === 'NONE-PARENT' ? 'line-through' : 'none' }, children: node.name }), node.selectedChildCount && +node.selectedChildCount > 0 ? (_jsxs(_Fragment, { children: [_jsxs("span", { className: 'selectedChildCount', id: `selectedChildCount${nodeIdReplace}`, children: ["(", node.selectedChildCount, "/", (_a = node.children) === null || _a === void 0 ? void 0 : _a.length, ")"] }), _jsx(TooltipSelectedChildren, { target: `selectedChildCount${nodeIdReplace}` })] })) : ((node.count || node.count === 0) && (_jsxs("span", { className: 'selectedChildCount', children: ["(", node.count, isRemoved && `, ${translate('removed', locale || 'ru')}`, ")"] }))), !!botId && (_jsxs("span", { id: `customData_${botId.value}`, className: classNames('customData', { isDeleted: isRemoved }), children: [botId.key, _jsx(Tooltip, { placement: 'top', target: `customData_${botId.value}`, children: botId.value })] }))] }) })] }));
};
export const TreeComponentWithCheckbox = (_a) => {
    var { dataset, onCheckbox, altClickEnabled } = _a, restprops = __rest(_a, ["dataset", "onCheckbox", "altClickEnabled"]);
    const [datasetFromState, setDatasetFromState] = useState(dataset);
    const toggleCheckBox = useCallback((node, event) => {
        const newState = clickOnCheckbox(node, datasetFromState, { event, altClickEnabled });
        setDatasetFromState(newState);
    }, [datasetFromState, altClickEnabled]);
    useEffect(() => {
        if (onCheckbox)
            onCheckbox(datasetFromState);
    }, [datasetFromState, onCheckbox]);
    useEffect(() => {
        setDatasetFromState(dataset);
    }, [dataset]);
    const ReactElementLeaf = React.memo(({ node }) => (_jsx("div", { style: { paddingLeft: 10, paddingRight: 0, display: 'flex' }, children: _jsx(TreeCheckbox, { onChange: toggleCheckBox, node: node, compact: restprops.compact, dark: restprops.theme === 'dark', altClickEnabled: altClickEnabled, locale: restprops.locale }) })));
    return (_jsx(TreeState, Object.assign({ dataset: datasetFromState, Leaf: ReactElementLeaf, Head: ReactElementLeaf, itemHeight: restprops.compact ? 24 : undefined }, restprops)));
};
