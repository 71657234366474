import { jsx as _jsx } from "react/jsx-runtime";
import { StylingLabel } from './Label';
import { withOverflowDots } from '../../../utils/pipes/functions';
const TextLabel = ({ value: { argumentsFilter, filter, hiddenOptionsArguments } }) => {
    var _a, _b, _c;
    const { values = [], comparisonType } = argumentsFilter;
    const { comparisonOptions } = (filter === null || filter === void 0 ? void 0 : filter.settings) || {};
    const label = (_b = (_a = comparisonOptions === null || comparisonOptions === void 0 ? void 0 : comparisonOptions.find(x => (values ? x.type === comparisonType : x.isDefault))) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    const value = hiddenOptionsArguments(comparisonType) && ((_c = values[0]) === null || _c === void 0 ? void 0 : _c.length) > 0
        ? `"${withOverflowDots(values[0], 40)}"`
        : undefined;
    return _jsx(StylingLabel, { label: label, value: value });
};
export default TextLabel;
