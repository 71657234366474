import { jsx as _jsx } from "react/jsx-runtime";
import { StylingLabel } from './Label';
const RadioLabel = ({ value: { argumentsFilter, filter } }) => {
    var _a, _b;
    const { option } = argumentsFilter;
    const { staticOptions } = (filter === null || filter === void 0 ? void 0 : filter.settings) || {};
    const label = (_b = (_a = staticOptions === null || staticOptions === void 0 ? void 0 : staticOptions.find(x => (option ? x.value === option : x.isSelected))) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    return _jsx(StylingLabel, { label: label });
};
export default RadioLabel;
