var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BaseModalFilter from './BaseModalFilter';
import { InputText, Spinner } from '@just-ai/just-ui';
import './NumericRangeModal.scss';
import { min, max, floor } from 'lodash';
export class NumericRangeModalState {
    constructor() {
        this.valueMin = undefined;
        this.valueMax = undefined;
        this.fetch = true;
        this.minValue = 0;
        this.minValueClear = false;
        this.maxValue = 0;
        this.maxValueClear = false;
    }
}
export default class NumericRangeModal extends BaseModalFilter {
    constructor(props) {
        var _a;
        super(props);
        this.filtersChanged = false;
        this.paddings = {
            minValue: 0,
            maxValue: 0,
        };
        this.fetchAndSaveOptions = () => __awaiter(this, void 0, void 0, function* () {
            const { filter: { key }, filtersFetcher, optionsArguments, locale, } = this.props;
            if (!optionsArguments)
                return;
            try {
                const { minValue, maxValue } = yield filtersFetcher.getIntervalOptions(key, optionsArguments.filters, locale);
                this.setState({
                    minValue,
                    maxValue,
                    fetch: false,
                });
            }
            catch (e) { }
        });
        this.toggleCleanButton = (value, name) => {
            //@ts-ignore
            if (value.length > 0 && !this.state[`${name}Clear`]) {
                this.setState({
                    [`${name}Clear`]: true,
                });
            }
            //@ts-ignore
            if (value.length === 0 && this.state[`${name}Clear`]) {
                this.setState({
                    [`${name}Clear`]: false,
                });
            }
            this.setState({
                [name === 'minValue' ? 'valueMin' : 'valueMax']: value.length === 0 ? undefined : min([floor(+value), this.state.maxValue]),
            });
        };
        this.getRef = (ref) => {
            if (ref && ref['dataset'].name && ['minValue', 'maxValue'].includes(ref['dataset'].name)) {
                //@ts-ignore
                this.paddings[ref['dataset'].name] = Math.ceil(ref.clientWidth) + 12;
            }
            if (this.paddings.maxValue > 0 && this.paddings.minValue > 0) {
                this.forceUpdate();
            }
        };
        this.onClear = () => {
            this.setState({
                valueMin: undefined,
                valueMax: undefined,
                minValueClear: false,
                maxValueClear: false,
            });
        };
        this.onCancel = () => {
            const oldValue = this.getOldValue();
            if (!oldValue && !this.props.filter.isRequired)
                return this.props.onDelete();
            let { from, to } = oldValue || {};
            from = Number.parseInt(from);
            to = Number.parseInt(to);
            let state = {
                valueMin: undefined,
                valueMax: undefined,
                minValueClear: false,
                maxValueClear: false,
            };
            if (Number.isInteger(from) || Number.isInteger(to)) {
                if (from) {
                    state.valueMin = from;
                    state.minValueClear = true;
                }
                if (to) {
                    state.valueMax = to;
                    state.maxValueClear = true;
                }
            }
            this.setState(Object.assign({}, state));
            this.props.onCancel();
        };
        this.onConfirm = () => {
            const { valueMin, valueMax } = this.state;
            let value = {};
            let newState = {
                valueMin: valueMin,
                valueMax: valueMax,
            };
            if (valueMin) {
                value.from = `${valueMin}`;
            }
            if (valueMax) {
                value.to = `${valueMax}`;
            }
            if (value.to && value.from) {
                let minValue = min([+valueMin, +valueMax]);
                let maxValue = max([+valueMin, +valueMax]);
                if (minValue !== valueMin) {
                    value.from = `${minValue}`;
                    newState.valueMin = value.from;
                }
                if (maxValue !== valueMax) {
                    value.to = `${maxValue}`;
                    newState.valueMax = value.to;
                }
            }
            this.setState(Object.assign({}, newState));
            this.props.onConfirm(value, false);
        };
        this.showClearButton = () => {
            return Boolean(this.state.valueMax || this.state.valueMin);
        };
        this.renderInputs = () => {
            const { t } = this.props;
            return (_jsxs(_Fragment, { children: [this.state.fetch && _jsx(Spinner, { size: '4x' }), _jsxs("div", { className: 'filter-numeric-range', children: [_jsxs("div", { className: 'filter-numeric-range-col', children: [_jsx("div", { className: 'filter-numeric-custom-label', "data-name": 'minValue', ref: this.props.showModal ? this.getRef : () => { }, children: t('Analytics: from') }), _jsx(InputText, { clearable: this.state.minValueClear, placeholder: this.state.minValue, type: 'number', name: 'minValue', value: this.state.valueMin + '', size: 'md', onChange: this.toggleCleanButton, style: { paddingLeft: this.paddings.minValue }, "data-test-id": 'FiltersPanel.FilterModal.NumberRangeModal.From' })] }), _jsxs("div", { className: 'filter-numeric-range-col', children: [_jsx("div", { className: 'filter-numeric-custom-label', "data-name": 'maxValue', ref: this.props.showModal ? this.getRef : () => { }, children: t('Analytics: to') }), _jsx(InputText, { clearable: this.state.maxValueClear, placeholder: this.state.maxValue, type: 'number', name: 'maxValue', size: 'md', value: this.state.valueMax + '', onChange: this.toggleCleanButton, style: { paddingLeft: this.paddings.maxValue }, "data-test-id": 'FiltersPanel.FilterModal.NumberRangeModal.To' })] })] })] }));
        };
        const { from, to } = ((_a = props.filter) === null || _a === void 0 ? void 0 : _a.oldValue) || {};
        this.state = {
            valueMin: from || undefined,
            valueMax: to || undefined,
            fetch: true,
            minValue: 0,
            minValueClear: Number.isInteger(from || undefined),
            maxValue: 0,
            maxValueClear: Number.isInteger(to || undefined),
        };
    }
    componentDidMount() {
        this.fetchAndSaveOptions();
    }
}
