import { OptionsResponseDto } from '@just-ai/api/dist/generated/Accountsadmin';

import { getCurrentDomainObject, isDev } from 'pipes/functions';

import AimychatTheme from './AcTheme';
import AimylogicTheme from './AlTheme';
import AimyvoiceTheme from './AvTheme';
import BaseTheme from './BaseTheme';
import CailaTheme from './CailaTheme';
import CloudTheme from './CloudTheme';
import CopilotTheme from './CopilotTheme';
import ImmersTheme from './ImmersTheme';
import JaicpTheme from './JaicpTheme';
import WhiteLabelTheme from './WhiteLabelTheme';

const themeInstances = {
  aimyvoice: AimyvoiceTheme,
  immers: ImmersTheme,
  aimychat: AimychatTheme,
  aimylogic: AimylogicTheme,
  caila: CailaTheme,
  cloud: CloudTheme,
  copilot: CopilotTheme,
  jaicp: JaicpTheme,
  default: BaseTheme,
  whitelabel: WhiteLabelTheme,
};

export const getTheme = (appConfig: OptionsResponseDto) => {
  const host =
    isDev() && process.env.REACT_APP_CLOUD_HOST ? new URL(process.env.REACT_APP_CLOUD_HOST).host : window.location.host;
  const domainTheme = getCurrentDomainObject(appConfig.domains, host)?.defaultTheme;
  const devTheme = process.env.REACT_APP_THEME ?? domainTheme;

  const resultTheme = Object.keys(themeInstances).includes(devTheme as string) ? devTheme : 'default';
  if (resultTheme === 'whitelabel' || appConfig?.whitelabelOptions?.fullWhitelabelEnabled) {
    return new themeInstances['whitelabel'](appConfig);
  }

  return new themeInstances[resultTheme as keyof typeof themeInstances](appConfig);
};
